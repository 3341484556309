import { doQuery } from '../providers/data-provider';

export const getAttribute = async (search) => {
  const query = `
      query QueryAttribute {
        queryAttribute(filter: { label: { allofterms: "${search}" } }) {
          id
          label
        }
      }
    `;

  const result = await doQuery(query, {});

  if (result.errors) {
    console.warn('Error getting attributes', result.errors);
  }

  return (
    result.data?.queryAttribute?.map(({ id }) => {
      return { id: id };
    }) || []
  );
};

export const getAttributes = async () => {
  const query = `
    query QueryAttributes {
      queryAttributeGroup {
        id
        name
        items(order: { asc: code }) {
          id
          label
        }
      }
    }
  `;

  const result = await doQuery(query, {});

  if (result.errors) {
    console.warn('Error getting attribute groups', result.errors);
  }

  return result.data?.queryAttributeGroup || [];
};
