import { useState } from 'react';
import { FormLabel, Typography, Tooltip } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import { confirmBan } from '../styles';
import { useCustomNotify } from '../../common/utils';
import { useUserBan } from '../hooks/user-ban';
import { Div } from '../../common/components';

const {
  banButtonContainer,
  confirmDialog,
  confirmDialogSubText,
  confirmDialogUserContentText,
  banButton,
  label,
  yesButton,
} = confirmBan;

export const ConfirmBan = ({ fullName, isBanned, userId }) => {
  const [showDialog, setShowDialog] = useState(false);
  const form = useForm();
  const notify = useCustomNotify();
  const { handleDialog, handleUserBan, banText, banStatus, tooltips } = useUserBan(
    { id: userId, fullName, isBanned },
    form,
    notify,
    setShowDialog
  );

  return (
    <Div>
      <FormLabel style={label}>Account status: {banStatus}</FormLabel>
      <Tooltip title={tooltips.banButton} placement="right">
        <Box sx={banButtonContainer}>
          <Button onClick={handleDialog} style={banButton}>
            {banText}
          </Button>
        </Box>
      </Tooltip>
      <Typography variant="body2" color="textSecondary">
        {tooltips.banned}
      </Typography>
      {showDialog && (
        <Dialog
          open={showDialog}
          onClose={handleDialog}
          aria-labelledby="alert-dialog-ban-title"
          aria-describedby="alert-dialog-ban-description"
        >
          <DialogTitle id="alert-dialog-ban-title" style={confirmDialog}>
            Are you sure you want to {banText} {fullName}?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-ban-description" style={confirmDialogSubText}>
              {tooltips.confirm}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Typography style={confirmDialogUserContentText} color="textSecondary">
              {tooltips.banned}
            </Typography>
            <Button onClick={handleDialog} color="primary">
              No
            </Button>
            <Button onClick={handleUserBan} style={yesButton}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Div>
  );
};
