import { useCallback, useMemo } from 'react';
import { Edit, SimpleForm, Toolbar, SaveButton, TextInput, BooleanInput, DeleteWithConfirmButton } from 'react-admin';
import { Box, Typography, FormLabel, Grid } from '@mui/material';
import { PhoneNumberMask } from '../../common/utils';

import { Status, CloudinaryUpload, Hr, Fieldset, Title, Div, CustomInput } from '../../common/components';
import { ClaimList } from '../../claim/components';
import { UserOwnerList } from './UserOwnerList';
import { ConfirmBan } from './ConfirmBan';
import { FormatPhoneNumberRaw, useCustomNotify } from '../../common/utils';
import { detail } from '../styles';
import { validateUserInformation as validate, characterLimit } from '../validators';

const { avatarLabel, container, name, bio, generalInformation, checkboxContainer } = detail.information;

export const UserInformation = ({ classNameSimpleForm = '', classNameEdit = '', props, data }) => {
  const notify = useCustomNotify();
  const simpleFormStyles = useMemo(() => (classNameSimpleForm ? {} : { padding: '1rem' }), [classNameSimpleForm]);

  const helperText = {
    bio: 'Max 300 characters',
  };

  const {
    banned,
    id: userId,
    fullName,
    userReportsReceivedAggregate,
    reportsAggregateAll,
    reportsAggregateValid,
  } = data;

  const receivedCount = userReportsReceivedAggregate?.count ?? 0;
  const madeCount = reportsAggregateAll?.count ?? 0;
  const madeValidCount = reportsAggregateValid?.count ?? 0;

  const toolbar = useMemo(
    () => (
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SaveButton type="button" />
        <DeleteWithConfirmButton
          confirmTitle="Delete User"
          confirmContent="This will delete this user & all their activities"
        />
      </Toolbar>
    ),
    []
  );

  const title = useMemo(() => <Title basePath={props.basePath} />, [props.basePath]);

  const onFailure = useCallback(
    (error) => {
      notify({
        message: error.message,
        type: 'error',
      });
    },
    [notify]
  );

  const receivedText = `This user has${receivedCount === 0 ? ' not ' : ' '}violated our `;
  const numberReceivedText =
    receivedCount >= 10 ? ' 10+ times' : ` ${receivedCount} time${receivedCount === 1 ? '' : 's'}`;

  const madeText = `This user has submitted
    ${madeCount === 0 ? 'no violations' : `${madeCount} violation${madeCount === 1 ? '' : 's'}`}`;
  const madeValidText = `, ${
    madeValidCount === 0 ? 'none were' : madeValidCount === 1 ? `${madeValidCount} was` : `${madeValidCount} were`
  } valid and resolved.`;

  return (
    <Edit
      title={title}
      onFailure={onFailure}
      {...props}
      mutationMode="pessimistic"
      undoable={false}
      className={classNameEdit}
    >
      <SimpleForm style={simpleFormStyles} className={classNameSimpleForm} toolbar={toolbar} submitOnEnter={false}>
        <Fieldset style={container}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <FormLabel>Avatar Image</FormLabel>
              <CloudinaryUpload record={data} field="avatarImage" recordType="user" />
              <br />
              <FormLabel>Background Image</FormLabel>
              <CloudinaryUpload record={data} field="backgroundImage" recordType="user" />
              <FormLabel style={avatarLabel}>.jpg, .png and .heic formats supported</FormLabel>
            </Grid>

            <Grid item xs={8}>
              <Box style={name}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormLabel>First name</FormLabel>
                    <TextInput source="firstName" validate={validate.firstName} variant="outlined" />
                  </Grid>

                  <Grid item xs={6}>
                    <FormLabel>Last name</FormLabel>
                    <TextInput source="lastName" validate={validate.lastName} variant="outlined" />
                  </Grid>
                </Grid>
              </Box>

              <Box xs={bio}>
                <Grid item xs={12}>
                  <CustomInput
                    formLabel="Bio"
                    tooltipText={helperText.bio}
                    characterLimit={characterLimit.bio}
                    multiline
                    source="bio"
                    validate={validate.bio}
                  />
                </Grid>
              </Box>
              <Box mt="1rem">
                <Div style={checkboxContainer}>
                  <ConfirmBan fullName={fullName} isBanned={banned} userId={userId} />
                  <BooleanInput label="Blessed" source="fastTrackReferrals" />
                </Div>
              </Box>
            </Grid>
          </Grid>
        </Fieldset>

        <Hr />

        <Status
          reportsReceivedText={receivedText}
          numberReportsReceivedText={receivedCount === 0 ? '.' : `${numberReceivedText}.`}
          reportsMadeText={madeText}
          reportsMadeValidText={madeValidText}
          resource="user"
        />

        <Hr />

        <Fieldset>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography xs={generalInformation} variant="h6">
                General Information
              </Typography>
              <Typography variant="body2">We'll use this info to contact users if necessary.</Typography>
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Email</FormLabel>
              <TextInput
                initialValue="Set email address"
                source="protectedData.email"
                validate={validate.email}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <FormLabel>Phone number</FormLabel>
              <TextInput
                initialValue="Set Phone number"
                format={PhoneNumberMask}
                source="protectedData.phoneNumber"
                validate={validate.phoneNumber}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Fieldset>

        <UserOwnerList record={data} />

        <ClaimList record={data} resource="user" />
      </SimpleForm>
    </Edit>
  );
};
