import React from 'react';
import { useRedirect } from 'react-admin';
import { Typography, List, ListItem, ListItemText, ListItemButton } from '@mui/material';

import { Fieldset } from '../../common/components';
import { components } from '../../common/styles/component';

const { list, fieldset: fieldsetWidth } = components.detailList;

/**
 * Displays a list of businesses owned by a user
 *
 * @param   {object}  record    query result from RA
 *
 * @return  {React.Component}
 */
export const UserOwnerList = React.memo(({ record }) => {
  const redirect = useRedirect();

  const typographyHeader = 'Owns Business(es)';
  const typographyNoBusinesses = `${record.fullName} owns no businesses.`;
  const owns = record.userBusinessRoles;

  return (
    <Fieldset style={fieldsetWidth}>
      <hr />
      <Typography variant="h6">{typographyHeader}</Typography>
      <List disablePadding>
        {owns && owns.length > 0 ? (
          owns.map((owner) => {
            const { id, name, location } = owner.business;
            return (
              <ListItem key={id} disableGutters disablePadding sx={list.item}>
                <ListItemButton
                  component="a"
                  sx={list.button}
                  disableGutters
                  onClick={() => {
                    redirect('edit', '/business', id, {}, { redirectRecord: record });
                  }}
                >
                  {name}
                  <ListItemText disableTypography>
                    {location?.city !== '' ? <>, {location?.city}</> : null}
                    {location?.address1 !== '' ? <>, {location?.address1}</> : null}
                    {location?.state !== '' ? <> {location?.state}</> : null}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })
        ) : (
          <ListItem disableGutters disablePadding>
            {typographyNoBusinesses}
          </ListItem>
        )}
      </List>
    </Fieldset>
  );
});
UserOwnerList.displayName = 'UserOwnerList';
