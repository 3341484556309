import { useCallback, useState } from 'react';
import { Confirm } from 'react-admin';
import { Button } from '@material-ui/core';

export const ButtonConfirm = ({ buttonLabel, onConfirm, confirmTitle, confirmContent, children, ...props }) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleShow = useCallback(() => setShowDialog(true), [setShowDialog]);
  const handleClose = useCallback(() => setShowDialog(false), [setShowDialog]);

  const handleOnConfirm = useCallback(() => {
    if (typeof onConfirm === 'function') {
      onConfirm();
    }

    handleClose();
  }, [handleClose, onConfirm]);

  const handleOnClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  return (
    <>
      <Button onClick={handleShow} {...props}>
        {buttonLabel}
      </Button>
      <Confirm
        isOpen={showDialog}
        title={confirmTitle}
        content={confirmContent}
        onConfirm={handleOnConfirm}
        onClose={handleOnClose}
      />
    </>
  );
};
