import { find } from 'lodash';
import lodashMemoize from 'lodash/memoize';

import { searchBy } from '../queries/search';
import { PhoneNumberMask } from '../../common/utils';

import { memoizeValidation } from '../../common/utils';

export const uniquePhoneNumber = memoizeValidation((errorMessage = 'Not Unique') => (value, allValues, input) => {
  if (!value) return undefined;
  /**
   * Only mask if value is _not_ undefined
   */
  const mask = PhoneNumberMask(value);

  /**
   * Gate re-querying of form validation to only new changes.
   *
   */
  if (mask === input.initial || input.active !== true) return undefined;
  else {
    return (async () => {
      const ids = await searchBy(mask);

      return find(ids, (user) => user.id !== allValues.id) && ids.length > 0 ? errorMessage : undefined;
    })();
  }
});

export const uniqueEmail = memoizeValidation((errorMessage = 'Not Unique') => (value, allValues, input) => {
  if (!value) return undefined;
  /**
   * Gate re-querying of form validation to only new changes.
   *
   */
  if (!value || value === input.initial) return undefined;
  else {
    return (async () => {
      const ids = await searchBy(value);

      return find(ids, (user) => user.id !== allValues.id) ? errorMessage : undefined;
    })();
  }
});
