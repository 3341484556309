import { useCallback, useEffect } from 'react';
import { SelectInput } from 'react-admin';
import { useTimezones } from '../hooks/timezones';
import { useForm } from 'react-final-form';

import { components } from '../styles/component';

export const TimezoneSelect = ({ disabled = false, defaultSelection }) => {
  const form = useForm();

  const { timezones } = useTimezones({ defaultSelection });

  return (
    <SelectInput
      source="timezone"
      choices={timezones}
      disabled={disabled}
      optionText="label"
      optionValue="value"
      style={components.selectInput}
    />
  );
};
