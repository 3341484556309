import { Box, Typography } from '@material-ui/core';
import map from 'lodash/map';

export const TicketListReporter = ({ record }) => {
  if (!record) return null;

  const lineLimit = 3;
  const reporters = map(record?.userReportedContentReports, (report) => {
    return report?.reporter || { fullName: 'Deleted User' };
  });
  const shouldTruncate = reporters.length > lineLimit;

  const list = reporters
    .slice(0, lineLimit)
    .map(({ fullName }, index) =>
      shouldTruncate && index === lineLimit - 1 ? `...and ${reporters.length + 1 - lineLimit} more` : fullName
    );

  return (
    <Box>
      {list.map((name) => (
        <Typography variant="body2" key={name}>
          {name}
        </Typography>
      ))}
    </Box>
  );
};
