export const businessClaimFields = `
  id
  business {
    id
    name
    location {
      city
      address1
      state
    }
  }
`;
