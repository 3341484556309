import { useMemo } from 'react';
import { Admin, Resource } from 'react-admin';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import ReportIcon from '@mui/icons-material/Report';

import BusinessCreate from '../../business/views/BusinessCreate';
import BusinessEdit from '../../business/views/BusinessEdit';
import BusinessList from '../../business/views/BusinessList';
import BusinessApplicationsList from '../../business/views/BusinessApplicationsList';
import TicketEdit from '../../ticket/views/TicketEdit';
import TicketList from '../../ticket/views/TicketList';
import UserEdit from '../../user/views/UserEdit';
import UserList from '../../user/views/UserList';

import { getDataProvider } from '../providers/data-provider';
import theme from '../styles/theme';
import CustomLayout from '../components/Layout';

const resources = [
  {
    name: 'userReportedContentGroup',
    label: 'Reported Content',
    list: TicketList,
    edit: TicketEdit,
    icon: ReportIcon,
  },
  {
    name: 'business',
    label: 'Businesses',
    list: BusinessList,
    edit: BusinessEdit,
    create: BusinessCreate,
    icon: BusinessIcon,
  },
  {
    name: 'businessApplications',
    label: 'Businesses Applications',
    list: BusinessApplicationsList,
    create: BusinessCreate,
    icon: AssignmentIcon,
  },
  {
    name: 'user',
    label: 'Users',
    list: UserList,
    edit: UserEdit,
    icon: GroupIcon,
  },
];

const JupiterAdmin = ({ token }) => {
  const dataProvider = useMemo(() => getDataProvider(token), [token]);

  return (
    <div>
      <Admin dataProvider={dataProvider} theme={theme} layout={CustomLayout}>
        {resources.map(({ label, ...props }) => (
          <Resource key={props.name} options={{ label }} {...props} />
        ))}
      </Admin>
    </div>
  );
};

export default JupiterAdmin;
