import { useRecordContext } from 'react-admin';
import { Typography, Link, Box } from '@material-ui/core';

import { list } from '../styles';
const { container } = list.name;

export const BusinessListName = () => {
  const record = useRecordContext();
  if (!record) return null;
  const linkToBusiness = `/#/business/${record.id}`;
  const city = record.location?.city;
  const state = record.location?.state;
  const subHeader = city && state ? `${city}, ${state}` : !city ? state : city;
  return record ? (
    <Box sx={{ ...container }}>
      <Link href={linkToBusiness}>{record.name}</Link>
      {!record.onlineOnly ? <Typography variant="body2">{subHeader}</Typography> : null}
    </Box>
  ) : null;
};
