export const Title = ({ record, basePath }) => {
  if (!record) {
    return null;
  }

  switch (basePath) {
    case '/business':
      return <>{`Business: ${record.name}`}</>;
    case '/business/create':
      return <>Create a Business</>;
    case '/user':
      return <>{`User: ${record.firstName} ${record.lastName}`}</>;
    case '/userReportedContent':
      if (!record.reportedItem) {
        return null;
      }
      const { __typename, name, business, fullName } = record.reportedItem;
      return <>{`Reported ${__typename}: ${name || business?.name || fullName}`}</>;
    default:
      return <>{'Squad Admin'}</>;
  }
};
