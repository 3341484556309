import { TextField, List, Datagrid, useRedirect } from 'react-admin';
import { CustomListActions, ListCreatedAt, ScrollToTop, ListBlessed } from '../../common/components';
import { list } from '../styles';

const { empty } = list.view;

const BusinessApplicationsList = (props) => {
  const redirect = useRedirect();

  function handleClick(id, basePath, record) {
    redirect(`${basePath}/create?source=${encodeURIComponent(JSON.stringify(record))}`);
  }

  return (
    <ScrollToTop>
      <CustomListActions title="Businesses Applications" />
      <List {...props} actions={<></>} bulkActionButtons={false} style={empty}>
        <Datagrid rowClick={handleClick}>
          <ListBlessed source="fastTrackReferrals" label="Blessed" sortable={false} />
          <TextField source="name" label="Business Name" sortable={false} />
          <TextField source="location.city" label="City" sortable={false} />
          <TextField source="email" label="Email" sortable={false} />
          <ListCreatedAt source="createdAt" label="Submitted" sortable={false} />
        </Datagrid>
      </List>
    </ScrollToTop>
  );
};

export default BusinessApplicationsList;
