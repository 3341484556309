import { doQuery } from '../../common/providers/data-provider';

export const queryAdminUser = async (authId) => {
  const query = `
    query queryAdminUser {
      queryAdminUser(filter: {authId: {eq: "${authId}"}}) {
        id
        authId
        email
        name
      }
    }
  `;

  const response = await doQuery(query, {});

  return response.data.queryAdminUser?.[0];
};
