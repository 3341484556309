import { MenuItemLink } from 'react-admin';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { useAuth0Wrapper } from '../hooks/auth0';

const LogoutButton = () => {
  const { logout } = useAuth0Wrapper();

  return (
    <MenuItemLink
      to="/"
      primaryText="Logout"
      leftIcon={<ExitToAppIcon />}
      onClick={() => {
        logout({ returnTo: window.location.origin });
      }}
    />
  );
};

export default LogoutButton;
