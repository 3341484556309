import { doQuery } from '../../common/providers/data-provider';

export const removeActivity = async (activityId, isBusiness, authorId) => {
  const query = `
    mutation CustomAdminRemoveRegularActivity(
      $authorId: String!
      $isBusiness: Boolean!
      $activityId: String!
    ) {
      customAdminRemoveRegularActivity(
        activityId: $activityId
        authorId: $authorId
        isBusiness: $isBusiness
      )
    }
  `;

  return await doQuery(query, {
    activityId,
    isBusiness,
    authorId: `${authorId}`,
  });
};

export const removeReaction = async (reactionId, isBusiness, authorId) => {
  const query = `
    mutation customAdminRemoveRegularReaction(
      $authorId: String!
      $isBusiness: Boolean!
      $reactionId: String!
    ) {
      customAdminRemoveRegularReaction(
        reactionId: $reactionId
        authorId: $authorId
        isBusiness: $isBusiness
      )
    }
  `;

  return await doQuery(query, {
    reactionId,
    isBusiness,
    authorId: `${authorId}`,
  });
};
