import { Badge, Box, Avatar, Typography, Tooltip, Grid } from '@material-ui/core';
import EmailIcon from '@mui/icons-material/Email';

import { list } from '../styles';
import { FormatDistance } from '../../common/utils';

export const TicketReporter = ({ data }) => {
  const reportId = data?.id;
  const reporters = data?.userReportedContentReports || [];
  const reportCount = data?.reportCount?.count;
  return (
    <>
      <Box>
        <h2 style={{ marginTop: 0, marginBottom: '0.75rem' }}>Reported by {reportCount} user(s)</h2>
      </Box>

      {reporters.map(({ reporter, reporterComment: comment, reason }, i) => {
        const reporterName = reporter?.fullName;
        const reporterEmail = reporter?.protectedData?.email;
        const reporterId = reporter?.id;
        const reporterLink = `/#/user/${reporterId}`;
        const reporterBlessed = reporter?.fastTrackReferrals;
        const reporterComment = comment || '';
        const reporterMailto = `mailto:${reporterEmail}?subject=Your Recent Activity on Squad - Ticket ID ${reportId} –`;
        const reporterImage = reporter?.avatarImage?.url;
        return (
          <section className="Card" style={{ marginBottom: '0.5rem' }} key={i}>
            <Grid container spacing={4}>
              <Grid item md={8}>
                <Box sx={{ alignItems: 'center', display: 'flex', gap: '0.75rem' }}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={reporterBlessed && <span style={{ ...list.badge }}>B</span>}
                  >
                    <Avatar src={reporterImage} alt={reporterName} style={{ width: '3rem', height: '3rem' }} />
                  </Badge>

                  <div>
                    <Typography component="div" variant="body1">
                      <a href={reporterLink} rel="noreferrer" target="_blank">
                        {reporterName}
                      </a>
                    </Typography>

                    <Typography component="div" variant="body2">
                      Reason Code: {reason?.label}
                      {reporterComment && <div> Comment: {reporterComment}</div>}
                    </Typography>
                  </div>
                </Box>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: '1.5rem',
                }}
              >
                <Typography component="div" variant="body2">
                  <FormatDistance date={data.createdAt} structure="h:mm a, MMM d, yyyy" />
                </Typography>

                <a href={reporterMailto} style={{ display: 'contents' }}>
                  <Tooltip title={`Email ${reporterName}`}>
                    <EmailIcon style={{ fontSize: '1.5rem', marginRight: '0.75rem' }} />
                  </Tooltip>
                </a>
              </Grid>
            </Grid>
          </section>
        );
      })}
    </>
  );
};
