import mean from 'lodash/mean';
import lodashMemoize from 'lodash/memoize';
import { emptyValue } from './emptyValue';

export * from './emptyValue';
export * from './FormatDate';
export * from './FormatPhoneNumber';
export * from './NonInput';
export * from './WithProps';
export * from './customNotify';
export * from './masks';
export * from './transformTypeformData';
export * from './ConditionalWrap';

/**
 * Hoist from React-Admin's implementation of in-variable validators
 * such as `required` and `number`.
 * [Source](https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/form/validate.ts#L71-L75)
 */
export const memoizeValidation = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args));

export const ResourceTypes = {
  Business: 'Business',
  Review: 'Review',
  User: 'User',
};

export const getResourceLink = (type, id) => {
  switch (type) {
    case ResourceTypes.User:
      return `#/user/${id}`;
    case ResourceTypes.Business:
      return `#/business/${id}`;
  }
};

export const latlongCombo = (value, allValues) => {
  const latitude = allValues?.location?.coordinates?.latitude;
  const longitude = allValues?.location?.coordinates?.longitude;
  const bothEmpty = latitude === undefined && longitude === undefined;

  if ((!value || value === '' || value === ' ') && !bothEmpty) {
    return 'Field cannot be empty';
  }
  if (value && (!longitude || !latitude)) {
    return 'Required lat & long';
  }
  return undefined;
};

export const requiredOn = memoizeValidation((predicate = 'hidden', errorMessage = 'Required') => (value, values) => {
  if (values && values[predicate] !== undefined && values[predicate] && emptyValue(value)) {
    return errorMessage;
  }
});

export const attributeLimit = memoizeValidation((limit = 5) => (value) => {
  if (value && value.length > limit) {
    return `Select no more then ${limit} attributes`;
  }
});

export const notZero = (value) => {
  if (value === '0' || value === 0) {
    return 'Cannot be Zero';
  }
};
