import { useCallback, useEffect, useState, useRef, useContext, createContext } from 'react';
import { useForm } from 'react-final-form';

import { getAttributes } from '../queries/attributes';
import { useCategories } from './categories';
import { useMounted } from './mounted';

const attributeGroupCategoryMap = {
  Shopping: 'shopping',
  'Health & Beauty': 'healthBeauty',
  'Beauty & Grooming': 'beautyGrooming',
  'Home & Garden': 'homeGarden',
  'Auto Services': 'autoServices',
  Fashion: 'fashion',
  Lifestyle: 'lifestyle',
  'Sports & Training': 'sportsTraining',
  Music: 'music',
  Restaurants: ['restaurantSpecialOcasion', 'restaurantSpecialAudience', 'restaurantCuisine'],
};

const getAttributesByCategory = (category, attributeGroups) => {
  const attributeGroupsNames = attributeGroupCategoryMap[category];

  if (Array.isArray(attributeGroupsNames)) {
    const attributesGroupsByCategory = [];
    attributeGroupsNames.forEach((groupName) => {
      attributeGroups.forEach((attributeGroup) => {
        if (attributeGroup.name === groupName) {
          attributesGroupsByCategory.push(attributeGroup);
        }
      });
    });
    return attributesGroupsByCategory.reduce((previous, current) => previous.concat(current.items), []);
  }

  const attributesGroupsByCategory = attributeGroups.filter(
    (attributeGroup) => attributeGroup.name === attributeGroupsNames
  );

  return attributesGroupsByCategory.reduce((previous, current) => previous.concat(current.items), []);
};

export const useAttributesProvider = (_attributes = []) => {
  const mounted = useMounted();
  const { category } = useCategories();
  const attributeOptions = useRef([]);
  const [attributes, setAttributes] = useState(() =>
    _attributes.map(({ id, label, name }) => ({ id, name: name || label }))
  );

  const fetchAttributes = useCallback(async () => {
    if (!attributeOptions.current.length) {
      attributeOptions.current = await getAttributes();
    }
    if (mounted.current && category?.name) {
      setAttributes(getAttributesByCategory(category?.name, attributeOptions.current));
    }
  }, [category?.name, mounted]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchAttributes, [category?.name]);

  return { attributes, fetchAttributes };
};

export const AttributesContext = createContext({});
export const AttributesContextProvider = ({ children, attributes }) => {
  const form = useForm();

  const context = useAttributesProvider(attributes?.length ? attributes : form.getState().values.attributes || []);

  return <AttributesContext.Provider value={context}>{children}</AttributesContext.Provider>;
};

export const useAttributes = () => useContext(AttributesContext);
