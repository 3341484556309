import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0Wrapper } from '../../auth/hooks/auth0';
import LoginButton from '../../auth/components/LoginButton';

const useParams = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const Landing = () => {
  let paramsQuery = useParams();
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0Wrapper();

  if (isLoading || isAuthenticated) {
    return <div>Loading ...</div>;
  }

  const params = {
    error: paramsQuery.get('error'),
    error_desc: paramsQuery.get('error_description'),
  };
  const Auth0Error = params.error && params.error_desc;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <div>
          <LoginButton onClick={loginWithRedirect} />
        </div>
        {Auth0Error ? (
          <div style={{ marginTop: '1rem', color: 'red', textAlign: 'center' }}>
            <p>
              <b>{params.error}</b>: {params.error_desc}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Landing;
