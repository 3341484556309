export const reviewItem = {
  mediaContainer: {
    marginTop: '1rem',
    display: 'block',
  },
  mediaThumbnail: {
    marginRight: '0.5rem',
    display: 'inline-block',
    width: '144px',
  },
  actionMenu: {
    width: '18rem',
    justifyContent: 'space-between',
  },
  deleteIcon: {
    color: '#E53E3E',
  },
  typeLine: {
    display: 'block',
    lineHeight: '100%',
    marginTop: '1rem',
  },
  list: {
    item: {
      container: {
        borderBottom: '1px solid hsl(0, 0%, 90%)',
        marginBottom: '1.5rem',
        paddingBottom: '1.5rem',
      },
    },
  },
  chip: {
    container: {
      minWidth: '10rem',
      textAlign: 'right',
    },
    internalContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    component: {
      marginBottom: '0.5rem',
      marginRight: '1.5rem',
      backgroundColor: 'red',
    },
  },
};
