import React, { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import StarIcon from '@mui/icons-material/Star';

import { list } from '../styles';

const { container, containerInternal, score, reviewers } = list.review;
const useStyles = makeStyles({ ...list.review });

export const BusinessListReviews = ({ source }) => {
  const record = useRecordContext();
  const { starIcon, flagIcon } = useStyles();
  const ratingAverage = record?.businessRating === null ? 0 : record?.businessRating;

  const hasUnresolvedReviewTickets = record?.flaggedReviewsNest?.length > 0;
  const hasUnresolvedBusinessTickets = record?.flaggedBusinessAggregate?.count > 0;
  // Display flag if any unresolved tickets against business or business reviews
  const showFlag = hasUnresolvedReviewTickets || hasUnresolvedBusinessTickets;

  if (!record) return null;
  const reviewCount = record?.reviews?.length;
  return record ? (
    <Box sx={{ ...container }}>
      <Box sx={{ ...containerInternal }}>
        <Typography sx={{ ...score }}>{ratingAverage}</Typography>
        <StarIcon className={starIcon} />
      </Box>
      <Box sx={{ ...containerInternal }}>
        <Typography sx={{ ...reviewers }}>
          {reviewCount || 'No'} {reviewCount === 1 ? 'review' : 'reviews'}
        </Typography>
        {showFlag ? <FlagIcon className={flagIcon} /> : null}
      </Box>
    </Box>
  ) : null;
};
