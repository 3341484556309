import { memo } from 'react';
import { useRedirect } from 'react-admin';
import { Box, ListItem, ListItemButton, ListItemText } from '@mui/material';

import { components } from '../../common/styles/component';

const { list } = components.detailList;

export const ClaimItem = memo(({ record, claim, isBusiness, removeClaim, ...props }) => {
  const redirect = useRedirect();

  /**
   * Handle both business, and user views
   */
  if (isBusiness) {
    const {
      user: { id: userId, fullName },
    } = claim;
    return (
      <ListItem disableGutters disablePadding sx={list.item} {...props}>
        <Box>
          <ListItemButton
            component="a"
            sx={list.button}
            onClick={() => {
              redirect('edit', '/user', userId, {}, { redirectRecord: record });
            }}
            disableGutters
          >
            <ListItemText disableTypography>{fullName}</ListItemText>
          </ListItemButton>
        </Box>
        <Box>
          <ListItemButton component="a" sx={list.button} disableGutters onClick={() => removeClaim(claim)}>
            <ListItemText disableTypography>Remove</ListItemText>
          </ListItemButton>
        </Box>
      </ListItem>
    );
  } else {
    const { id, name, location } = claim.business;
    return (
      <ListItem disableGutters disablePadding sx={list.item} {...props}>
        <ListItemButton
          component="a"
          sx={list.button}
          disableGutters
          onClick={() => {
            redirect('edit', '/business', id, {}, { redirectRecord: record });
          }}
        >
          {name}
          <ListItemText disableTypography>
            {location?.city !== '' ? <>, {location?.city}</> : null}
            {location?.address1 !== '' ? <>, {location?.address1}</> : null}
            {location?.state !== '' ? <> {location?.state}</> : null}
          </ListItemText>
        </ListItemButton>
      </ListItem>
    );
  }
});
ClaimItem.displayName = 'ClaimItem';
