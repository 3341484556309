import { useAuth0 } from '@auth0/auth0-react';
import { config } from '../../common/config';

export const useAuth0Wrapper = () => {
  const overrideToken = config.userOverride?.token;
  if (!overrideToken) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useAuth0();
  }
  return {
    isAuthenticated: true,
    user: {
      name: 'Testy Testerson',
      isTestyTesterson: true,
    },
    logout: async ({ returnTo }) => {
      window.location = returnTo;
    },
    getIdTokenClaims: async () => {
      return {
        __raw: overrideToken,
      };
    },
  };
};
