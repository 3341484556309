import { maxValue, maxLength, minValue, number, regex, required } from 'react-admin';
import { PhoneNumberValidation } from '../../common/utils/FormatPhoneNumber';
import { attributeLimit, latlongCombo, notZero, requiredOn } from '../../common/utils';

export const characterLimit = {
  name: 75,
  description: 400,
  subtitle: 30,
  location: {
    address1: 75,
    city: 30,
  },
};

export const validateBusinessInformation = {
  name: [required(), maxLength(characterLimit.name)],
  description: [required(), maxLength(characterLimit.description)],
  websiteUrl: [regex(/^(http(s)?:\/\/)/, 'missing http(s)://')],
  phoneNumber: [PhoneNumberValidation],
  subtitle: [maxLength(characterLimit.subtitle)],
  attributes: [required(), attributeLimit(10)],
  location: {
    address1: [maxLength(characterLimit.location.address1)],
    city: [requiredOn('flexibleLocation'), maxLength(characterLimit.location.city)],
    state: [requiredOn('flexibleLocation'), regex(/^[a-zA-Z]+$/, 'Must be text')],
    zip: [regex(/^[0-9]+$/, 'Must be a number')],
  },
};
