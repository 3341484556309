export const SubNav = ({ children, ...props }) => (
  <div className="SubNav" {...props}>
    <div className="SubNav-content">{children}</div>
  </div>
);

export const SubNavTitle = ({ children }) => (
  <div style={{ display: 'flex', gap: '0.75rem', alignItems: 'center' }}>{children}</div>
);

export const SubNavActions = ({ children }) => (
  <div style={{ display: 'flex', gap: '1rem', justifySelf: 'flex-end' }}>{children}</div>
);
