import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-final-form';

const mapBusinessOwners = (record) =>
  record?.businessUserRoles?.map(({ role, user }) => (role === 'OWNER' ? user : undefined)).filter(Boolean) || [];

export const useBusinessOwner = (record, field = 'businessUserId') => {
  const form = useForm();
  const [owners, setOwners] = useState([]);

  useEffect(() => {
    setOwners(mapBusinessOwners(record));
    form.change(field, mapBusinessOwners(record)?.[0]?.id || null);
    // eslint-disable-next-line
  }, [record?.businessUserRoles]);

  const onChange = useCallback(
    (owner) => {
      form.change(field, owner?.id || null);
      setOwners(owner ? [owner] : []);
    },
    // eslint-disable-next-line
    [setOwners, form]
  );

  const removeOwner = useCallback(() => {
    setOwners([]);
    form.change(field, null);
    // eslint-disable-next-line
  }, [setOwners, form]);

  return { owners, removeOwner, onChange };
};
