import { useMemo, useState } from 'react';
import { List, Datagrid, SearchInput, TopToolbar, FilterButton, BooleanInput } from 'react-admin';
import { PhoneNumberMask } from '../../common/utils';
import { list } from '../styles';
import {
  LastActivity,
  Pagination,
  EmptyList,
  CustomListActions,
  CustomListActionsTrigger,
  ListCreatedAt,
  ScrollToTop,
  ListBlessed,
} from '../../common/components';
import { UserListName, UserListStatus } from '../components';
import { useDebounce } from '../../common/hooks/debounce';

const { topToolBar, searchInput, empty } = list.view;

const LISTACTIONSFILTER = 'active';

const Actions = () => {
  return (
    <TopToolbar style={{ ...topToolBar }}>
      <FilterButton />
    </TopToolbar>
  );
};

const defaultSort = { field: 'fullName', order: 'desc' };

const UserList = (props) => {
  const [filterContext, setfilterContext] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const searchUser = useDebounce((e) => setSearchQuery(e.target?.value), 100);

  const listFilters = [
    <SearchInput
      key={1}
      style={searchInput}
      source="customSearchUserInputFilter"
      alwaysOn
      placeholder="Search User name, phone, or email"
      className="searchField"
      parse={PhoneNumberMask}
      format={PhoneNumberMask}
      onChange={searchUser}
    />,
    <BooleanInput key={3} label="Blessed" source="blessed" defaultValue={false} />,
  ];

  const pagination = useMemo(() => <Pagination limit={<EmptyList searchQuery={searchQuery} />} />, [searchQuery]);

  return (
    <ScrollToTop>
      <CustomListActions
        title="Users"
        btnPrimary="All users"
        btnSecondary="Flagged users"
        filter={LISTACTIONSFILTER}
        listContext={filterContext}
      />
      <List
        {...props}
        actions={<Actions />}
        bulkActionButtons={false}
        filters={listFilters}
        style={empty}
        pagination={pagination}
        perPage={100}
        sort={defaultSort}
      >
        <>
          <CustomListActionsTrigger setfilterContext={setfilterContext} />
          <Datagrid rowClick="edit">
            <UserListName source="name" label="User Name" sortBy="fullName" />
            <ListBlessed source="fastTrackReferrals" label="Blessed" sortable={false} />
            <UserListStatus source="status" sortable={false} />
            <LastActivity source="Last Activity" sortable={false} />
            <ListCreatedAt source="createdAt" label="Created" />
          </Datagrid>
        </>
      </List>
    </ScrollToTop>
  );
};

export default UserList;
