import { Avatar, Typography } from '@material-ui/core';

import { FormatDate, FormatDistance } from '../utils';

export const Comment = ({ comment, reportedCommentId }) => {
  const {
    user: {
      data: { image: autherImage, name: authorFullName },
    },
    updated_at: createdAt,
    data: { text: commentText },
    id: currentCommentId,
  } = comment;
  const ownComments = comment?.own_children?.comment || [];
  const reportedComment = reportedCommentId === currentCommentId;
  const notReported = !reportedComment && reportedCommentId ? { opacity: '0.5' } : {};

  return (
    <>
      <div className="Comment" style={reportedComment ? {} : notReported}>
        <Avatar className="Comment-avatar" alt={authorFullName} src={autherImage} />

        <div style={{ flex: '1' }}>
          <div
            className="Comment-body"
            style={
              reportedComment
                ? {
                    backgroundColor: '#fff',
                    outline: '2px solid var(--colorDanger)',
                    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
                  }
                : {}
            }
          >
            <Typography style={{ fontSize: '0.875em', fontWeight: '600' }}>{authorFullName}</Typography>
            <Typography style={{ margin: '0.25em 0', fontSize: '0.7em', fontWeight: '400' }}>{commentText}</Typography>

            <Typography style={{ opacity: '0.6', fontSize: '0.625em' }}>
              <FormatDistance date={createdAt} />
            </Typography>
          </div>

          {ownComments.map((comment, key) => {
            return (
              <>
                <Comment key={key} comment={comment} />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
