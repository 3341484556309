import { useRecordContext } from 'react-admin';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CampaignIcon from '@mui/icons-material/Campaign';
import { list } from '../styles';

const useStyles = makeStyles({ ...list.status });

export const BusinessListClaims = () => {
  const record = useRecordContext();
  if (!record) return null;

  // remove owner from claiment count
  const businessOwner = record?.businessUserRoles?.find((userRole) => userRole?.role === 'OWNER');
  const ownerClaimed = record?.userClaims?.find((userClaim) => userClaim?.user?.id === businessOwner?.user?.id);

  const claimCount =
    record?.userClaimsAggregate?.count > 0 && ownerClaimed
      ? record?.userClaimsAggregate?.count - 1
      : record?.userClaimsAggregate?.count;
  const hasClaims = claimCount > 0;
  const claimMessage = hasClaims ? `${claimCount} user claim${claimCount > 1 ? 's' : ''}` : 'no claims';

  return record ? (
    <Chip
      style={{ margin: '0 1rem' }}
      size="small"
      variant="outlined"
      icon={hasClaims ? <CampaignIcon fontSize="small" /> : null}
      label={claimMessage}
    />
  ) : null;
};
