import { useRecordContext } from 'react-admin';
import { Avatar, Chip } from '@material-ui/core';

export const BusinessListOwner = () => {
  const record = useRecordContext();
  if (!record) return null;
  const businessOwner = record?.businessUserRoles?.find((userRole) => userRole.role === 'OWNER');

  return record ? (
    <Chip
      style={{ margin: '0 1rem' }}
      size="small"
      variant="outlined"
      avatar={
        businessOwner ? <Avatar src={businessOwner?.user.avatarImage?.url} alt={businessOwner?.user.fullName} /> : null
      }
      label={businessOwner ? businessOwner?.user.fullName : 'Un-owned'}
    />
  ) : null;
};
