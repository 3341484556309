import { dataProvider, doQuery } from '../../common/providers/data-provider';

export const searchBy = async (term = '') => {
  const query = `
    query CustomQueryUsersId {
      customAdminQueryUsers(
        order:  ASC
        sort: FULLNAME
        offset: 0
        first: ${term ? 0 : 50}
        searchTerm: "${term}"
      ) {
        ids
        totalCount
      }
    }
  `;

  const result = await doQuery(query, {});

  if (result.errors) {
    console.warn('Error searching users', result.errors);
  }

  if (result?.data?.customAdminQueryUsers?.ids?.length) {
    const users = await dataProvider.getMany('user', {
      pagination: { offset: null, first: term ? 0 : 50 },
      ids: result.data.customAdminQueryUsers.ids,
      order: '{ asc: fullName }',
    });

    if (users.errors) {
      console.warn('Error searching users', result.errors);
    }
    return users.data;
  }

  return [];
};
