import { useCallback, useMemo } from 'react';
import { Create } from 'react-admin';
import { useCustomNotify } from '../../common/utils';
import { Title } from '../../common/components';
import { BusinessForm, transformData } from './BusinessForm';

const simpleFormProps = {
  submitOnEnter: false,
};

export const BusinessCreateForm = ({ formProps, defaultData, CustomToolbar }) => {
  const notify = useCustomNotify();

  const onFailure = useCallback(
    (error) => {
      notify({
        message: error.message,
        type: 'error',
      });
    },
    [notify]
  );

  const title = useMemo(() => <Title basePath={formProps.location.pathname} />, [formProps.location.pathname]);

  return (
    <Create title={title} onFailure={onFailure} transform={transformData} {...formProps}>
      <BusinessForm
        simpleFormProps={simpleFormProps}
        CustomToolbar={CustomToolbar}
        data={{}}
        defaultData={defaultData}
      />
    </Create>
  );
};
