import { useRef, useEffect } from 'react';

export const useChange = (effect, deps = undefined, onlyChange = true) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current && onlyChange) {
      mounted.current = true;
      return;
    }
    effect();
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};
