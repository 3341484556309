import { createContext } from 'react';
import { CategoriesContextProvider } from './categories';
import { AttributesContextProvider } from './attributes';

export const CategoryAttributesContext = createContext({});
export const CategoryAttributesContextProvider = ({ children, categories, attributes }) => {
  return (
    <CategoriesContextProvider categories={categories}>
      <AttributesContextProvider attributes={attributes}>{children}</AttributesContextProvider>
    </CategoriesContextProvider>
  );
};
