import { useRecordContext } from 'react-admin';
import { Box } from '@mui/material';
import { FormatDistance } from '../../common/utils';
import { list } from '../styles';

const { text, on } = list.report;

export const BusinessListReport = () => {
  const record = useRecordContext();
  if (!record || !record?.reportsValid) return null;
  const validCount = record?.reportsAggregateValid?.count;
  const latestReport = record?.reportsValid[0]?.createdAt;
  const plural = validCount > 1 ? 'reports' : 'report';
  return record ? (
    <Box>
      <Box sx={{ ...text }}>
        <b>
          {validCount != 0 ? (
            <>
              {validCount} {plural}
            </>
          ) : (
            'None'
          )}
        </b>
      </Box>
      <Box sx={{ ...on }}>
        {validCount != 0 ? (
          <>
            Last report <FormatDistance date={latestReport} />
          </>
        ) : null}
      </Box>
    </Box>
  ) : null;
};
