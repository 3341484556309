import { useCallback, useMemo } from 'react';
import { BooleanInput, SimpleForm, TextInput, Toolbar, SaveButton, FormDataConsumer } from 'react-admin';
import { Typography, FormLabel, Grid, Tooltip } from '@mui/material';
import InfoIcon from '@material-ui/icons/Info';

import {
  CategorySelect,
  CategoryAttributesSelect,
  CloudinaryUpload as BaseCloudinaryUpload,
  Hr,
  Div,
  Fieldset,
  Status,
  NoImage as BaseNoImage,
  CustomInput,
} from '../../common/components';
import { FormatPhoneNumberRaw } from '../../common/utils/FormatPhoneNumber';
import { CategoryAttributesContextProvider } from '../../common/hooks/category-attributes';
import { useCategories } from '../../common/hooks/categories';
import { ClaimList } from '../../claim/components';
import { BusinessHours } from './BusinessHours';
import { BusinessLocation } from './BusinessLocation';
import { BusinessAttributes } from './BusinessAttributes';
import { BusinessOwnerList } from './BusinessOwnerList';
import { useBusinessInformationValidator } from '../hooks/business-information-validator';

import { detail } from '../styles';

const { avatarLabel, metadataLabel, uploadContainer, nameContainer } = detail.information;

const avatarContainerStyles = { ...uploadContainer, textAlign: 'left' };

export const transformData = (data) => {
  return {
    ...data,
    attributes: data.attributes?.map(({ id }) => ({ id })) || [],
    categories: data.categories?.map(({ id }) => ({ id })) || [],
  };
};

const CloudinaryUpload = ({ record, field }) => {
  const { category } = useCategories();

  const NoImage = useCallback(
    () => <BaseNoImage category={category ? category : null} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [category?.image]
  );

  return <BaseCloudinaryUpload record={record} field={field} recordType="business" NoImage={NoImage} />;
};
CloudinaryUpload.displayName = 'CloudinaryUpload';

const helperText = {
  name: 'Max 75 characters',
  subtitle: 'Max 30 characters',
  description: 'Max 400 characters',
  websiteUrl: 'not required',
  phoneNumber: 'not required',
  location: {
    address1: 'Max 75 characters',
    city: 'Max 30 characters',
  },
};

export const BusinessForm = ({ simpleFormProps, data, defaultData = {}, CustomToolbar, ...props }) => {
  const { validate, validateHours, characterLimit } = useBusinessInformationValidator();

  const validateGlobalForm = useCallback(() => {
    /**
     * We use this validation step to prevent saving on invalid hours inputs.
     * We manually display error messages in the `<DayHours />` components.
     */
    const errors = {};
    if (validateHours.anyErrors) {
      errors.customHoursValidationErrors = 'Failure';
    }

    return errors;
  }, [validateHours.anyErrors]);

  const toolbar = useMemo(
    () => (
      <Toolbar>
        <SaveButton type="button" />
      </Toolbar>
    ),
    []
  );

  const receivedCount = data?.reportsAggregateValid?.count ?? 0;
  const receivedText = `This business has ${receivedCount === 0 ? 'not' : ''} violated our `;
  const numberReceivedText =
    receivedCount >= 10 ? '10+ times' : ` ${receivedCount} time${receivedCount === 1 ? '' : 's'}`;

  return (
    <SimpleForm
      toolbar={CustomToolbar ? CustomToolbar : toolbar}
      validate={validateGlobalForm}
      {...simpleFormProps}
      {...props}
    >
      <CategoryAttributesContextProvider attributes={data.attributes} categories={data.categories}>
        <Fieldset>
          <Grid container spacing={4}>
            <Grid item xs={6} style={avatarContainerStyles}>
              <FormLabel>Avatar Image</FormLabel>
              <CloudinaryUpload record={data} field="avatarImage" />
              <br />
              <FormLabel>Background Image</FormLabel>
              <CloudinaryUpload record={data} field="backgroundImage" />
              <FormLabel style={avatarLabel}>.jpg, .png and .heic formats supported</FormLabel>
            </Grid>

            <Grid item xs={6}>
              <Div style={nameContainer}>
                <CustomInput
                  source="name"
                  formLabel="Business Name"
                  tooltipText={helperText.name}
                  characterLimit={characterLimit.name}
                  validate={validate.name}
                />
              </Div>
              <FormLabel style={metadataLabel}>Business Settings</FormLabel>
              <BusinessAttributes validate={validate} helperText={helperText} characterLimit={characterLimit} />
            </Grid>
          </Grid>
        </Fieldset>

        <Hr />

        <Fieldset>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Category & Attributes</Typography>
            </Grid>

            <Grid item xs={3}>
              <FormLabel>Category</FormLabel>
              <CategorySelect />
            </Grid>

            <Grid item xs={9}>
              <FormLabel>
                Attributes aid in search querying, and provide additional context to a businesses services.
              </FormLabel>
              <CategoryAttributesSelect validate={validate.attributes} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">General Information</Typography>
            </Grid>

            <Grid item xs={5}>
              <CustomInput
                source="websiteUrl"
                formLabel="Primary Web Address"
                tooltipText={helperText.websiteUrl}
                placeholder="Set website"
                validate={validate.websiteUrl}
              />
            </Grid>

            <Grid item xs={3}>
              <CustomInput
                source="phoneNumber"
                formLabel="Phone number"
                tooltipText={helperText.phoneNumber}
                placeholder="Set Phone number"
                format={FormatPhoneNumberRaw}
                validate={validate.phoneNumber}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomInput
                source="description"
                formLabel="Business Description"
                tooltipText={helperText.description}
                validate={validate.description}
                characterLimit={characterLimit.description}
                multiline={true}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </Fieldset>

        <Hr />

        <BusinessLocation validate={validate} data={data} helperText={helperText} characterLimit={characterLimit} />

        <Hr />

        <Fieldset>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Hours</Typography>
              <FormLabel>Business hours help support discovery.</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <BooleanInput label="Online Only" source="onlineOnly" />
            </Grid>
            <Grid item xs={12}>
              <FormDataConsumer subscription={{ values: true }}>
                {({ formData }) =>
                  !formData.onlineOnly && (
                    <BusinessHours record={formData} defaultData={defaultData} validate={validateHours} />
                  )
                }
              </FormDataConsumer>
            </Grid>
          </Grid>
        </Fieldset>

        <Hr />

        <Status
          reportsReceivedText={receivedText}
          numberReportsReceivedText={receivedCount === 0 ? '.' : `${numberReceivedText}.`}
          resource="business"
        />

        <ClaimList record={data} />

        <FormDataConsumer subscription={{ values: true }}>
          {({ formData }) => <BusinessOwnerList record={formData} />}
        </FormDataConsumer>
      </CategoryAttributesContextProvider>
    </SimpleForm>
  );
};
