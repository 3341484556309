import { useCallback, useState } from 'react';
import { Show, FunctionField } from 'react-admin';
import { Tabs, Tab } from '@material-ui/core';
import { get } from 'lodash';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { DetailHeader, Div, ScrollToTop, TabPanel } from '../../common/components';
import { BusinessEditForm, BusinessReviews } from '../components';

import theme from '../../common/styles/theme';

const cardContainerStyles = get(theme, 'overrides.CardContainer');

const BusinessEdit = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = useCallback(
    (_, newValue) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab]
  );

  return (
    <ScrollToTop>
      <Show title="&#8203;" actions={null} {...props} style={cardContainerStyles}>
        <>
          <FunctionField
            render={(record) => {
              const detailNotice = record.typeformResponseId
                ? {
                    label: 'User submitted',
                    Icon: AssignmentIcon,
                  }
                : null;

              return <DetailHeader title={record.name} notice={detailNotice} />;
            }}
          />
          <Div>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Business information and reviews">
              <Tab label="Business information" aria-label="Business information" />
              <Tab label="Reviews" aria-label="Business reviews" />
            </Tabs>
          </Div>
          <TabPanel value={selectedTab} index={0}>
            <FunctionField render={(record) => <BusinessEditForm formProps={props} data={record} />} />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <FunctionField render={(record) => <BusinessReviews props={props} data={record} />} />
          </TabPanel>
        </>
      </Show>
    </ScrollToTop>
  );
};

export default BusinessEdit;
