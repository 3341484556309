import { memo } from 'react';
import { Typography, List, ListItem, Box } from '@mui/material';

import { Fieldset } from '../../common/components';
import { UserItem } from '../../user/components/UserItem';
import { components } from '../../common/styles/component';
import { UserSelectInput } from '../../user/components/UserSelectInput';
import { useBusinessOwner } from '../hooks/business-owner';

const { fieldset: fieldsetWidth } = components.detailList;

const text = {
  heading: 'Owned By',
  noOwner: 'This business has no owners.',
};

/**
 * Displays the current BusinessOwnerList, and allows a user to be searched by email/name and selected as the Owner.
 *
 * @param   {object}  record    query result from RA
 * @param   {string}  resource  either `business` or `user`. Defaults to `business`.
 *
 * @return  {React.Component}
 */
export const BusinessOwnerList = memo(({ record }) => {
  const { owners, removeOwner, onChange } = useBusinessOwner(record);

  return (
    <Fieldset style={fieldsetWidth}>
      <hr />
      <Typography variant="h6">{text.heading}</Typography>
      <Box mt={2}>
        <UserSelectInput value={owners?.[0] || null} label="Select Owner" onChange={onChange} />
      </Box>
      <List disablePadding>
        {owners?.length ? (
          owners.map((owner) => <UserItem key={owner.id} record={record} user={owner} removeUser={removeOwner} />)
        ) : (
          <ListItem disableGutters disablePadding>
            {text.noOwner}
          </ListItem>
        )}
      </List>
    </Fieldset>
  );
});
BusinessOwnerList.displayName = 'BusinessOwnerList';
