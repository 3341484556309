import { reviewUserListFields, reviewUserDetailFields } from './review';
import { businessClaimFields } from './business-claim';
import { userOwnerFields } from './user-claim';

const userAggregateFields = `
  reportsAggregateValid: reportsAggregate(filter: { reportResolution: { eq: VALID }}) {
    count
  }
  reportsAggregateAll: reportsAggregate {
    count
  }
  userReportsReceivedAggregate(filter: { reportResolution: { eq: VALID }}) {
    count
  }
`;

export const userFields = `
  __typename
  id
  firstName
  lastName
  fullName
  banned
  bio
  createdAt
  fastTrackReferrals
  avatarImage {
    url
    publicId
    resourceType
    type
  }
  backgroundImage {
    url
    publicId
    resourceType
    type
  }
  protectedData {
    id
    email
    phoneNumber
  }
`;

export const userUserReportedContentListFields = `
  ${userFields}
`;

export const userUserReportedContentDetailFields = `
  id
  fullName
  bio
  createdAt
  avatarImage {
    url
    publicId
    resourceType
    type
  }
  backgroundImage {
    url
    publicId
    resourceType
    type
  }
  protectedData {
    email
  }
`;

const list = `
  ${userFields}
  reviews(order: { desc: createdAt }, first: 1) {
    ${reviewUserListFields}
  }
  businessClaimsAggregate {
    count
  }
  businessClaims { ${businessClaimFields} }
  ${userAggregateFields}
`;

const detail = `
  ${userFields}
  reviews { ${reviewUserDetailFields} }
  businessClaimsAggregate {
    count
  }
  businessClaims { ${businessClaimFields} }
  userBusinessRoles { ${userOwnerFields} }
  ${userAggregateFields}
`;

export const user = { detail, list };
