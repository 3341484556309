import { useState } from 'react';
import { Avatar, Chip, Typography } from '@material-ui/core';

export const BusinessProfile = ({ data, ...props }) => {
  const {
    attributes = [],
    avatarImage,
    backgroundImage,
    blackOwned,
    businessRating,
    description,
    location,
    name,
    subtitle,
  } = data?.reportedItem;
  const [visible, setVisible] = useState(true);
  let style = {};

  if (backgroundImage) {
    style.backgroundImage = `url(${backgroundImage.url})`;
  }

  return (
    <div className="Profile Profile--business" {...props} onClick={() => setVisible(!visible)}>
      <div className="Profile-background" style={{ ...style }} />
      <div className="Profile-card" style={{ opacity: visible ? 1 : 0 }}>
        <Avatar
          className="Profile-avatar"
          alt={name}
          src={avatarImage?.url}
          style={{ width: '4em', height: '4em', fontSize: '1em', marginRight: '0.5em', borderRadius: '0.5em' }}
          onClick={() => setVisible(false)}
        />

        <div>
          <Typography style={{ fontSize: '1em', lineHeight: '1', fontWeight: 600 }}>{name}</Typography>
          <Typography style={{ fontSize: '0.875em' }}>{subtitle}</Typography>
          {businessRating && (
            <Typography style={{ fontSize: '0.75em', fontWeight: 600 }}>★ {businessRating}</Typography>
          )}
          {/* TODO: BlackOwned */}
        </div>
      </div>

      <div style={{ padding: '0 1em' }}>
        <h4 style={{ margin: '0 0 0.5em' }}>Traits</h4>
        {attributes.map(({ label }) => (
          <Chip
            key={label}
            label={label}
            size="small"
            style={{
              backgroundColor: 'var(--colorBrandBlueLight)',
              lineHeight: 2,
              margin: '0 0.5em 0.5em 0',
              padding: '1em 0.5em',
              textTransform: 'capitalize',
            }}
          />
        ))}
        <h4 style={{ margin: '0.5em 0' }}>Details</h4>
        <Typography style={{ fontSize: '0.875em' }}>{description}</Typography>

        {/* TODO: Location */}
      </div>
    </div>
  );
};
