import { memo } from 'react';
import { useRedirect } from 'react-admin';
import { Box, ListItem, ListItemButton, ListItemText } from '@mui/material';

import { components } from '../../common/styles/component';

const { list } = components.detailList;

export const UserItem = memo(({ record, user, removeUser, ...props }) => {
  const redirect = useRedirect();

  return (
    <ListItem disableGutters disablePadding sx={list.item} {...props}>
      <Box>
        <ListItemButton
          component="a"
          sx={list.button}
          onClick={() => {
            redirect('edit', '/user', user.id, {}, { redirectRecord: record });
          }}
          disableGutters
        >
          <ListItemText disableTypography>{user.fullName}</ListItemText>
        </ListItemButton>
      </Box>
      <Box>
        <ListItemButton component="a" sx={list.button} disableGutters onClick={() => removeUser(user.id)}>
          <ListItemText disableTypography>Remove</ListItemText>
        </ListItemButton>
      </Box>
    </ListItem>
  );
});
UserItem.displayName = 'UserItem';
