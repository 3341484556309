import { Avatar, Box, Typography } from '@material-ui/core';

import { FormatDistance } from '../utils';
import { MediaThumbnail, Stars } from './';

export const Review = ({ data, style, ...props }) => {
  const {
    author: { fullName, avatarImage },
    business: { name },
    createdAt,
    title,
    description,
    media,
    rating,
  } = data?.reportedItem;

  return (
    <Box style={{ fontSize: '16px', color: 'var(--PRIMARY_MAIN)', ...style }} {...props}>
      <Box sx={{ alignItems: 'center', display: 'flex', gap: '0.5em', marginBottom: '0.75em' }}>
        <Avatar alt={fullName} src={avatarImage?.url} style={{ width: '3rem', height: '3rem' }} />

        <Typography style={{ fontSize: '1em' }}>
          {fullName}
          {name && <span style={{ fontSize: '0.75em', display: 'block' }}>{name}</span>}
        </Typography>
      </Box>

      <Box style={{ alignItems: 'center', display: 'flex', gap: '0.5em', marginBottom: '0.75em' }}>
        <Stars rating={rating} />

        <Typography style={{ fontSize: '0.75em' }}>
          <FormatDistance date={createdAt} />
        </Typography>
      </Box>

      <Box mb={2} style={{ fontSize: '1em', fontWeight: 'bold' }}>
        {title}
      </Box>

      <Box mb={2} style={{ fontSize: '0.875em' }}>
        {description}
      </Box>

      <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
        {media.map((item) => (
          <MediaThumbnail key={item.id} media={item} style={{ width: 'calc(50% - 0.25em)' }} />
        ))}
      </Box>
    </Box>
  );
};
