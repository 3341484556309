import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { components } from '../styles/component';

const useStyles = makeStyles({ ...components.emptyList });

export const EmptyList = ({ searchQuery }) => {
  const { container } = useStyles();
  return (
    <Box className={container}>
      <Typography variant="body1" className={container}>
        {searchQuery ? (
          <>
            No results for: <strong>{searchQuery}</strong>
          </>
        ) : (
          <></>
        )}
      </Typography>
    </Box>
  );
};
