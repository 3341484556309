import { doQuery } from '../../common/providers/data-provider';

export const queryActivityDetail = async (activityId) => {
  const query = `
    query customQueryActivityDetail {
      customQueryActivityDetail(activityId: "${activityId}")
    }
  `;

  return await doQuery(query, {});
};

export const queryActivityComment = async (activityId, commentId) => {
  const query = `
    query customQueryActivityComment($activityId: ID!, $commentId: ID!) {
      customQueryActivityComment(activityId: $activityId, commentId: $commentId)
    }
  `;

  return await doQuery(query, {
    activityId,
    commentId,
  });
};
