import { checkEnvVars } from '@jupiter/server/src/utils/checkVars';
import { toLower } from 'lodash';

const isDev = toLower(process.env.NODE_ENV) === 'development';
const isTest = toLower(process.env.NODE_ENV) === 'test';

export const config = {
  apiUrl: process.env.REACT_APP_ADMIN_API_URL || 'https://alpha.juptr-uat.hg-prototypes.com/graphql',
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || 'juptr-ua.us.auth0.com',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || 'DfvNrHCB8huZpVMUNlpHbCF0nnIeCOUm',
  },
  cloudinary: {
    name: process.env.REACT_APP_CLOUDINARY_NAME || 'hypergiant',
    uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || 'hypergiant-signed',
  },
  userOverride: {
    token: process.env.REACT_APP_USER_OVERRIDE_TOKEN,
  },
  mapbox: {
    token: process.env.REACT_APP_MAPBOX_TOKEN,
  },
};

const varsToSkip = {
  userOverride: true,
};

if (!isDev && !isTest) {
  const result = checkEnvVars(config, varsToSkip);
  if (result && result.length) throw new Error(`Missing env vars: \n${result.join('\n')}`);
}
