export default [
  {
    value: 'Pacific/Honolulu',
    label: '(GMT-10:00) Hawaii (Hawaii-Aleutian Standard Time)',
    offset: -10,
    abbrev: 'HAST',
    altName: 'Hawaii-Aleutian Standard Time',
  },
  {
    value: 'America/Juneau',
    label: '(GMT-8:00) Alaska (Alaska Daylight Time)',
    offset: -8,
    abbrev: 'AKDT',
    altName: 'Alaska Daylight Time',
  },
  {
    value: 'America/Phoenix',
    label: '(GMT-7:00) Arizona (Mountain Standard Time)',
    offset: -7,
    abbrev: 'MST',
    altName: 'Mountain Standard Time',
  },
  {
    value: 'America/Los_Angeles',
    label: '(GMT-7:00) Pacific Time (Pacific Daylight Time)',
    offset: -7,
    abbrev: 'PDT',
    altName: 'Pacific Daylight Time',
  },
  {
    value: 'America/Boise',
    label: '(GMT-6:00) Mountain Time (Mountain Daylight Time)',
    offset: -6,
    abbrev: 'MDT',
    altName: 'Mountain Daylight Time',
  },
  {
    value: 'America/Chicago',
    label: '(GMT-5:00) Central Time (Central Daylight Time)',
    offset: -5,
    abbrev: 'CDT',
    altName: 'Central Daylight Time',
  },
  {
    value: 'America/Detroit',
    label: '(GMT-4:00) Eastern Time (Eastern Daylight Time)',
    offset: -4,
    abbrev: 'EDT',
    altName: 'Eastern Daylight Time',
  },
];
