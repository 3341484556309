import { useCallback } from 'react';
import { SimpleShowLayout } from 'react-admin';
import { Button, Typography, Grid, Tooltip, makeStyles } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import { isUndefined } from 'lodash';

import {
  BusinessProfile,
  ButtonConfirm,
  Chip,
  Device,
  Post,
  Review,
  SimpleTable,
  SimpleTableRow,
  SubNav,
  SubNavActions,
  SubNavTitle,
  UserProfile,
} from '../../common/components';
import { ConditionalWrap, FormatDate, useCustomNotify } from '../../common/utils';
import { detail } from '../styles';
import { TicketReporter, TicketActions, TicketActionRow } from './';
import { useReportStatus, useReportedItemStatus } from '../hooks/report-status';
import { useResolveReport } from '../hooks/resolve-report';
import { useReview } from '../hooks/review';
import { useActivityDetail } from '../hooks/activity-detail';

import { updateUserBannedStatus } from '../../user/mutations/update-ban-status';
import { updateBusinessHiddenStatus } from '../../business/mutations/update-status';
import { removeActivity, removeReaction } from '../mutations/remove-activity-reaction';
const tooltips = {
  closed: 'This report has been addressed',
};

const useStyles = makeStyles(detail.edit.view);
export const TicketDetails = ({ data }) => {
  const { NavButton } = useStyles();

  const { id, reportedItem, __typename: typename } = data;

  let TYPE =
    reportedItem?.__typename === 'ActivityFeedReportedPost'
      ? 'POST'
      : reportedItem?.__typename === 'ActivityFeedReportedComment'
      ? 'COMMENT'
      : reportedItem?.__typename?.toUpperCase();
  const ACTIVITYFEED = TYPE === 'POST' || TYPE === 'COMMENT';
  const deleteTypeText = TYPE === 'COMMENT' ? 'Comment' : 'Post';

  const businessId = TYPE === 'REVIEW' ? reportedItem?.business?.id : reportedItem?.id;
  const businessName = reportedItem?.name || reportedItem?.business?.name;
  const businessLink = `/#/business/${businessId}`;
  const businessFeatured = reportedItem?.business?.featured;
  const businessOwner = reportedItem?.businessUserRoles?.find((userRole) => userRole.role === 'OWNER');
  const businessOwnerName = businessOwner?.user?.fullName;
  const businessOwnerEmail = businessOwner?.user?.protectedData?.email;
  const businessOwnerId = businessOwner?.user?.id;
  const businessOwnerLink = `/#/user/${businessOwnerId}`;
  const businessOwnerMailTo = `mailto:${businessOwnerEmail}?subject=Your Business has been reported - Ticket ID ${id} –`;
  const reportCount = data.reportCount.count;

  const notify = useCustomNotify();
  const reportStatus = useReportStatus(data?.closedAt);
  const reportedItemStatus = useReportedItemStatus(reportedItem, TYPE);
  const { toggleHideReview } = useReview(reportedItem, notify);
  const { resolveReportValid, resolveReportInvalid, makeReportResolveable, reportResolveable } = useResolveReport(
    notify,
    id,
    typename,
    reportStatus
  );
  const reportedPost = useActivityDetail({
    activityId: reportedItem?.postId,
    TYPE,
    notify,
    reportedItemStatus,
  });
  const reportedComment = useActivityDetail({
    activityId: reportedItem?.postId,
    commentId: reportedItem?.commentId,
    TYPE,
    notify,
    reportedItemStatus,
  });

  const reportee = TYPE === 'REVIEW' || ACTIVITYFEED ? reportedItem?.author : reportedItem;
  const reporteeId = reportee?.id || reportedItem?.id;
  const reporteeEmail = reportee?.protectedData?.email;
  const reporteeMailto = `mailto:${reporteeEmail}?subject=Your Recent Activity on Squad - Ticket ID ${id} –`;
  const reporteeName = reportee?.fullName;
  const reporteeLink = `/#/user/${reporteeId}`;

  const detailsTableContent = {
    type: [
      {
        label: 'Report Type',
        value: TYPE,
      },
    ],
    ReporteeStatus: [
      {
        label: 'Account Status',
        value: reportedItemStatus.banned ? '<span style="color: \'var(--colorDanger)\'">Banned</span>' : 'Active',
      },
    ],
    Reportee: [
      {
        label: TYPE === 'REVIEW' || ACTIVITYFEED ? 'Content Author' : 'Reported user',
        value: (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <a href={reporteeLink}>{reporteeName}</a>
            <a href={reporteeMailto} style={{ display: 'contents' }}>
              <Tooltip title={`Email ${reporteeName}`}>
                <EmailIcon style={{ fontSize: '1rem' }} />
              </Tooltip>
            </a>
          </div>
        ),
      },
    ],
    ReportCount: [
      {
        label: 'Report Count',
        value: reportCount,
      },
    ],
    BusinessReviewContext: [
      {
        label: TYPE === 'BUSINESS' ? 'Business' : 'Review on',
        value: `<a href=${businessLink}>${businessName}</a>`,
      },
    ],
  };

  const detailsTable = {
    BUSINESS: [
      ...detailsTableContent.type,
      ...detailsTableContent.BusinessReviewContext,
      {
        label: 'Business status',
        value: reportedItemStatus.hidden ? '<span style="color: \'var(--colorDanger)\'">Hidden</span>' : 'Visible',
      },
      {
        label: 'Business owner',
        value: businessOwner ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <a href={businessOwnerLink}>{businessOwnerName}</a>
            <a href={businessOwnerMailTo} style={{ display: 'contents' }}>
              <Tooltip title={`Email ${businessOwnerName}`}>
                <EmailIcon style={{ fontSize: '1rem' }} />
              </Tooltip>
            </a>
          </div>
        ) : (
          'No owner'
        ),
      },
      ...detailsTableContent.ReportCount,
    ],
    REVIEW: [
      ...detailsTableContent.type,
      ...detailsTableContent.BusinessReviewContext,
      ...detailsTableContent.Reportee,
      ...detailsTableContent.ReporteeStatus,
      ...detailsTableContent.ReportCount,
    ],
    USER: [
      ...detailsTableContent.type,
      ...detailsTableContent.Reportee,
      ...detailsTableContent.ReporteeStatus,
      ...detailsTableContent.ReportCount,
    ],
    POST: [
      ...detailsTableContent.type,
      ...detailsTableContent.Reportee,
      ...detailsTableContent.ReporteeStatus,
      ...detailsTableContent.ReportCount,
    ],
    COMMENT: [
      ...detailsTableContent.type,
      ...detailsTableContent.Reportee,
      ...detailsTableContent.ReporteeStatus,
      ...detailsTableContent.ReportCount,
    ],
  }[TYPE];

  const actionBan = useCallback(
    async ({ user }) => {
      const response = await updateUserBannedStatus(user.id, !reportedItemStatus.banned);
      const banText = reportedItemStatus.hidden ? 'unban' : 'ban';

      if (!isUndefined(response?.errors)) {
        return notify({
          message: `Unable to ${banText} ${user.fullName}`,
          res: response,
        });
      }

      notify({
        message: `${user.fullName} has been ${banText}ned`,
      });
      makeReportResolveable();
      reportedItemStatus.setBanned(!reportedItemStatus.banned);
    },
    [makeReportResolveable, notify, reportedItemStatus]
  );

  const actionHideBusiness = useCallback(
    async ({ business }) => {
      const response = await updateBusinessHiddenStatus(business, !reportedItemStatus.hidden);
      const notifyText = !reportedItemStatus.hidden ? 'hidden' : 'unhidden';

      if (!isUndefined(response?.errors)) {
        return notify({
          message: `Unable to ${notifyText}e review.`,
          res: response,
        });
      }

      notify({
        message: `${business.name} is now ${notifyText}`,
      });
      makeReportResolveable();
      reportedItemStatus.setHidden(!reportedItemStatus.hidden);
    },
    [makeReportResolveable, notify, reportedItemStatus]
  );

  const actionHideReview = useCallback(
    async ({ review }) => {
      const noErrors = await toggleHideReview();
      reportedItemStatus.setHidden(!reportedItemStatus.hidden);
      if (noErrors) makeReportResolveable();
    },
    [makeReportResolveable, reportedItemStatus, toggleHideReview]
  );

  const actionDeleteActivity = useCallback(
    async ({ activityId, isBusiness, authorId }) => {
      const response = await removeActivity(activityId, isBusiness, authorId);

      if (!isUndefined(response?.errors)) {
        return notify({
          message: `Unable to delete ${deleteTypeText}`,
          res: response,
        });
      }

      notify({
        message: `Deleted ${deleteTypeText}`,
      });
      makeReportResolveable();
      reportedItemStatus.setDeleted(true);
    },
    [deleteTypeText, makeReportResolveable, notify, reportedItemStatus]
  );

  const actionDeleteReaction = useCallback(
    async ({ reactionId, isBusiness, authorId }) => {
      const response = await removeReaction(reactionId, isBusiness, authorId);

      if (!isUndefined(response?.errors)) {
        return notify({
          message: `Unable to delete ${deleteTypeText}`,
          res: response,
        });
      }

      notify({
        message: `Deleted ${deleteTypeText}`,
      });
      makeReportResolveable();
      reportedItemStatus.setDeleted(true);
    },
    [deleteTypeText, makeReportResolveable, notify, reportedItemStatus]
  );

  const banMessage = !reportedItemStatus.banned
    ? reporteeName
      ? `ban ${reporteeName}`
      : 'ban'
    : reporteeName
    ? `Unban ${reporteeName}`
    : 'Unban';
  const actionsListContent = {
    BanUser: [
      {
        confirm: true,
        confirmContent: `Are you sure you want to ${banMessage}?`,
        label: !reportedItemStatus.banned ? 'Ban User' : 'Unban User',
        button: banMessage,
        action: () => {
          actionBan({ user: reportee });
        },
      },
    ],
  };

  const actionsList = {
    BUSINESS: [
      {
        label: !reportedItemStatus.hidden ? 'Hide Content' : 'Unhide Content',
        button: !reportedItemStatus.hidden ? 'Hide Business' : 'Unhide Business',
        action: () => {
          actionHideBusiness({ business: reportedItem });
        },
      },
    ],
    REVIEW: [
      {
        label: !reportedItemStatus.hidden ? 'Hide Content' : 'Unhide Content',
        button: !reportedItemStatus.hidden ? 'Hide Review' : 'Unhide Review',
        action: () => {
          actionHideReview({ review: reportedItem });
        },
      },
      ...actionsListContent.BanUser,
      ,
    ],
    USER: [...actionsListContent.BanUser],
    POST: [
      ...actionsListContent.BanUser,
      {
        label: 'Delete content',
        button: 'Delete Post',
        disabled: !reportedPost.loaded || reportedItemStatus.deleted,
        confirm: true,
        confirmContent: 'Are you sure you want to delete this post? This action is irreversible.',
        action: () => {
          actionDeleteActivity({
            activityId: reportedItem.postId,
            isBusiness: reportedPost.businessUser ? true : false,
            authorId: reportedPost.businessUser ? reportedPost.businessUser : reportedPost?.detail?.author,
          });
        },
      },
    ],
    COMMENT: [
      ...actionsListContent.BanUser,
      {
        label: 'Delete content',
        button: !reportedItemStatus.deleted ? 'Delete comment' : 'Comment deleted',
        disabled: !reportedComment.loaded || reportedItemStatus.deleted,
        confirm: true,
        confirmContent: 'Are you sure you want to delete this comment? This action is irreversible.',
        action: () => {
          actionDeleteReaction({
            reactionId: reportedItem.commentId,
            isBusiness: reportedComment.detail?.businessUser || false,
            authorId: reportedComment.businessUser
              ? reportedComment.businessUser
              : reportedComment?.detail?.data?.author,
          });
        },
      },
    ],
  }[TYPE];

  reportType: return (
    <SimpleShowLayout component="div">
      {/* Fragment used to silence console warn https://github.com/marmelab/react-admin/issues/4209 */}
      <>
        <SubNav>
          <SubNavTitle>
            Group ID: {id} <Chip label="Priority" size="small" priority={3} />
          </SubNavTitle>

          <SubNavActions>
            {reportStatus.closed && (
              <div
                style={{
                  alignItems: 'center',
                  color: 'var(--colorGreyLighter)',
                  display: 'flex',
                  fontSize: '0.875rem',
                }}
              >
                Ticket closed <FormatDate date={!data?.closedAt ? reportStatus.closed : data?.closedAt} />
              </div>
            )}
            <ConditionalWrap
              condition={reportStatus.closed}
              IfWrap={(children) => <Tooltip title={tooltips.closed}>{children}</Tooltip>}
            >
              <>
                <Button
                  variant="outlined"
                  disabled={reportStatus.closed}
                  onClick={resolveReportInvalid}
                  className={NavButton}
                >
                  Dismiss
                </Button>
                <Button
                  variant="outlined"
                  disabled={reportStatus.closed ? true : !reportResolveable}
                  onClick={resolveReportValid}
                  className={NavButton}
                >
                  Mark as Resolved
                </Button>
              </>
            </ConditionalWrap>
          </SubNavActions>
        </SubNav>

        <Grid container spacing={4}>
          <Grid item md={4}>
            <Device
              overlay={Object.keys(reportedItemStatus).filter((key) => reportedItemStatus[key] === true)}
              type={TYPE}
              loaded={ACTIVITYFEED ? reportedPost.loaded : true}
            >
              <div>
                {
                  {
                    REVIEW: <Review data={data} style={{ padding: '2.5em 1em 1em' }} />,
                    USER: <UserProfile data={data} />,
                    BUSINESS: <BusinessProfile data={data} />,
                    POST: <Post data={data} activityData={reportedPost} style={{ padding: '2.5em 1em 1em' }} />,
                    COMMENT: (
                      <Post
                        data={data}
                        activityData={reportedPost}
                        reportedCommentId={reportedItem?.commentId}
                        style={{ padding: '2.5em 1em 1em' }}
                      />
                    ),
                  }[TYPE]
                }
              </div>
            </Device>
          </Grid>

          <Grid item md={8}>
            <Grid container spacing={4}>
              <Grid item md={6} style={{ marginBottom: '1.5rem' }}>
                <h2>Details</h2>

                <SimpleTable>
                  {detailsTable.map(({ label, value }) => (
                    <SimpleTableRow key={label} label={label} value={value} />
                  ))}
                </SimpleTable>
              </Grid>

              <Grid item md={6}>
                <TicketActions>
                  {actionsList.map(({ label, button, action, confirm = false, confirmContent, disabled = false }) => {
                    const isDisabled = !reportStatus.closed ? disabled : true;
                    const isUndo = label.toLowerCase().includes('un'); // Fragile, but works for now
                    const ticketActionStyles = !isDisabled
                      ? { backgroundColor: !isUndo ? 'var(--colorDanger)' : 'var(--PRIMARY_MAIN)', color: 'white' }
                      : {};

                    return (
                      <TicketActionRow key={label}>
                        <Typography>{label}</Typography>
                        {confirm ? (
                          <ButtonConfirm
                            variant="contained"
                            confirmTitle={label}
                            confirmContent={confirmContent}
                            onConfirm={action}
                            buttonLabel={button}
                            disabled={isDisabled}
                            style={ticketActionStyles}
                          />
                        ) : (
                          <Button variant="contained" onClick={action} disabled={isDisabled} style={ticketActionStyles}>
                            {button}
                          </Button>
                        )}
                      </TicketActionRow>
                    );
                  })}
                </TicketActions>
              </Grid>
            </Grid>

            <Grid item md={12}>
              <TicketReporter data={data} reportStatus={reportStatus} />
            </Grid>
          </Grid>
        </Grid>
      </>
    </SimpleShowLayout>
  );
};
