export const userClaimFields = `
  id
  user {
    id
    fullName
  }
`;

export const userOwnerFields = `
	business {
		id
	 	name
		location {
			address1
			city
			state
		}
	}
`;
