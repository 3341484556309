import React from 'react';
import { format, formatDistance } from 'date-fns';

const defaultStructure = 'MMM d, yyyy h:mma';

import { toNumber } from 'lodash';

/**
 * Modularized solution for formating DateTime (commonly createdAt)
 *
 * @param   {String}  date       Such as createdAt
 * @param   {String}  structure  Defaults to 'h:mm, a MMM d, yyyy'
 *
 * @return  {String}             Formated DateTime
 * @TODO Have ability to Double format for MMM d, yyyy && h:mm, so we can style just the
 * timecode, or the day individually. Effectively splitting what is intended to be formatted into two outputs.
 * @ticket [JUPTR-1411](https://gohypergiant.atlassian.net/browse/JUPTR-1411)
 */
export const FormatDate = ({ date, structure }) => {
  return format(new Date(date), structure ? structure : defaultStructure);
};

/**
 * Wrapper around date-fns's [`formatDistance`](https://date-fns.org/v2.28.0/docs/formatDistance).
 *
 * @param   {String}  date       Such as createdAt
 *
 * @return  {String}             Such as 'in about 1 hour'
 */
export const FormatDistance = ({ date }) => {
  return formatDistance(new Date(date), new Date(), { includeSeconds: false, addSuffix: true });
};

/**
 * Allow to pass-in format to react-admin's component such as DateField.
 *
 * Ref: https://marmelab.com/react-admin/Fields.html#datefield
 * Ref: https://marmelab.com/react-admin/Fields.html#usage-2
 */
FormatDate.DateField = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

/**
 * Straight from `./packages/server/src/utils/time.ts`
 */

// Parse 30 from 1230
export function militaryTimeMinute(militaryTime) {
  return toNumber(militaryTime.toString().slice(-2));
}

// Parse 24 from 2400
export function militaryTimeHour(militaryTime) {
  return toNumber(militaryTime.toString().slice(0, -2));
}

export const formatMilitaryDate = (time) => {
  time = time || 0;

  const converted = new Date();

  converted.setHours(militaryTimeHour(+time === 0 ? '0000' : time));
  converted.setMinutes(militaryTimeMinute(+time === 0 ? '0000' : time));

  return converted;
};

export const formatMilitaryTime = (date) => {
  date = date instanceof Date ? date : new Date(date);
  return format(date, 'HHmm');
};
/**
 * Checks if the received input is of instance date.
 *
 * @param   {any}     value  Check if Date type or not.
 *
 * @return  {boolean}
 */
export const isValidDate = (value) => {
  return value instanceof Date && !isNaN(value);
};
