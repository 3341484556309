import { doQuery } from '../../common/providers/data-provider';

export const resolveReport = async (reportId, reportType, reportStatus) => {
  const query = `
    mutation ResolveReportGroup($reportGroupResolution: ReportGroupResolution) {
      resolveReportGroup(reportGroupResolution: $reportGroupResolution)
    }
  `;

  const variables = {
    reportGroupResolution: {
      reportGroupId: reportId,
      reportStatus,
    },
  };

  return await doQuery(query, variables);
};
