import { reviewBusinessListFields, reviewBusinessDetailFields } from './review';
import { userClaimFields } from './user-claim';

const businessAggregateFields = `
  reportsAggregateValid: userReportsReceivedAggregate(filter: {reportResolution: {eq: VALID}}) {
    count
  }
  reportsValid: userReportsReceived(filter: { reportResolution: { eq: VALID } }) {
	  createdAt
	}
  reportsAggregateAll: userReportsReceivedAggregate {
    count
  }
  userClaimsAggregate {
    count
  }
  # count of any unresolved tickets against the busineses reviews
  # We only need to look for one review, to know that >= 1 reviews are unresolved.
  flaggedReviewsNest: reviews(filter: {has: userReportsReceived}) @cascade {
		userReportsReceived(filter: {reportResolution: {eq: null}}, first: 1) {
			id
		}
	}
  # count of any unresolved tickets against the business
  flaggedBusinessAggregate: userReportsReceivedAggregate(filter: {reportResolution: {eq: null}}) {
		count
	}
`;

const businessFields = `
  __typename
  id
  name
  flexibleLocation
  typeformResponseId
  businessRating: rating
  blackOwned
  onlineOnly
  hidden
  featured
  phoneNumber
  description
  websiteUrl
  createdAt
  facilitatesTransactions
  subtitle
  avatarImage {
    url
    publicId
    resourceType
    type
  }
  backgroundImage {
    url
    publicId
    resourceType
    type
  }
  categories {
    id
    label
    image {
      url
    }
  }
  location {
    address1
    address2
    city
    state
    zip
    coordinates {
      longitude
      latitude
    }
  }
  timezone
  open24hours
  hours {
	  day
		start
		end
	}
  userClaims { ${userClaimFields} }
`;
export const businessListFields = `
  ${businessFields}
  ${businessAggregateFields}
`;

export const businessDetailFields = `
  ${businessFields}
  ${businessAggregateFields}
  attributes {
    id
    label
  }
`;

export const businessUserReportedContentListFields = `
  ${businessFields}
  ${businessAggregateFields}
`;

export const businessUserReportedContentDetailFields = `
  ${businessFields}
  attributes {
    id
    label
  }
  businessUserRoles {
    role
    user {
      id
      fullName
      avatarImage {
        url
      }
      protectedData {
        email
      }
    }
  }
`;

const list = `
  ${businessListFields}
  reviews { ${reviewBusinessListFields} }
  businessUserRoles {
    role
    user {
      id
      fullName
      avatarImage {
        url
      }
    }
  }
`;

const detail = `
  ${businessDetailFields}
  reviews { ${reviewBusinessDetailFields} }
  userClaims { ${userClaimFields} }
  businessUserRoles {
    role
    user {
      id
      fullName
      protectedData {
        email
      }
    }
  }
`;

export const business = { detail, list };
