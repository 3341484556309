import { Avatar, Box, Typography, Badge } from '@material-ui/core';
import ReviewsIcon from '@mui/icons-material/Reviews';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import ChatIcon from '@mui/icons-material/Chat';
import DescriptionIcon from '@mui/icons-material/Description';

import { list } from '../styles';

const { avatar, container } = list.name;

const config = {
  REVIEW: {
    badge: <ReviewsIcon style={{ ...list.badge }} />,
  },
  USER: {
    badge: null,
    icon: <PersonIcon />,
  },
  BUSINESS: {
    badge: null,
    icon: <BusinessIcon />,
    style: {
      borderRadius: '0.25rem',
    },
  },
  POST: {
    badge: <DescriptionIcon style={{ ...list.badge }} />,
  },
  COMMENT: {
    badge: <ChatIcon style={{ ...list.badge }} />,
  },
};

export const TicketListDetails = ({ record }) => {
  if (!record) return null;

  const { reportedItem } = record;

  let TYPE =
    reportedItem?.__typename === 'ActivityFeedReportedPost'
      ? 'POST'
      : reportedItem?.__typename === 'ActivityFeedReportedComment'
      ? 'COMMENT'
      : reportedItem?.__typename?.toUpperCase();

  const author = reportedItem?.author?.fullName || 'Unknown';
  const name = reportedItem?.author?.fullName || reportedItem?.name || reportedItem?.fullName;
  const imgSrc = reportedItem?.author?.avatarImage?.url || reportedItem?.avatarImage?.url;

  return (
    <Box sx={container}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={config[TYPE]?.badge}
        style={{ marginRight: '1.5rem' }}
      >
        <Avatar src={imgSrc} alt={name} style={{ ...avatar, ...config[TYPE]?.style }}>
          {config[TYPE]?.icon}
        </Avatar>
      </Badge>

      <div>
        <Typography variant="body2" style={{ fontSize: '0.875rem' }}>
          {
            {
              REVIEW: `Review by ${author}`,
              USER: `${name}`,
              BUSINESS: `${name}`,
              POST: `Post by ${author}`,
              COMMENT: `Comment by ${author}`,
            }[TYPE]
          }
        </Typography>

        <Typography variant="body2" color="textSecondary">
          {
            {
              REVIEW: `for ${reportedItem?.business?.name}`,
              USER: '',
              BUSINESS: '',
              POST: '',
              COMMENT: '',
            }[TYPE]
          }
        </Typography>
      </div>
    </Box>
  );
};
