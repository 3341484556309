import { useEffect, useState, useCallback, useRef } from 'react';
import { formatMilitaryDate, formatMilitaryTime, isValidDate } from '../utils';
import { useChange } from './change';

export const useDayHours = (props = {}) => {
  const { day, hours = { start: null, end: null }, form, open24Hours = false, validate } = props;
  const { label, day: dayNumber } = day;
  const { incrementErrors, decrementErrors } = validate;

  const customHours = {
    start: `customHours.${label}.start`,
    end: `customHours.${label}.end`,
    day: `customHours.${label}.day`,
  };

  const prevHours = useRef({ ...hours, open24Hours });
  const [startInvalidInput, setStartInvalidInput] = useState(null);
  const [endInvalidInput, setEndInvalidInput] = useState(null);
  const [, _set24Hours] = useState();
  const [startValue, setStartValue] = useState(() =>
    open24Hours ? formatMilitaryDate('0000') : !hours?.start && !hours?.end ? null : formatMilitaryDate(hours?.start)
  );
  const [endValue, setEndValue] = useState(() =>
    open24Hours ? formatMilitaryDate('2400') : !hours?.end ? null : formatMilitaryDate(hours?.end)
  );

  // Splitting the is24Hours state to a useRef to allow for external linked
  // influence while retaining the ability to independently update local state.
  const is24Hours = useRef(open24Hours || (+hours?.start === 0 && +hours?.end === 2400));
  const set24Hours = useCallback((value) => {
    prevHours.current.open24Hours = is24Hours.current;
    if (typeof value === 'function') {
      is24Hours.current = value(is24Hours.current);
    } else {
      is24Hours.current = value;
    }
    _set24Hours(Date.now());
  }, []);

  // Update the `start` time
  const handleStartChange = useCallback(
    (value, updateDayForm = true) => {
      /**
       * Display validation errors until user completes typing the full
       * time mask. Prevents users from submitting partial hours that are
       * ultimitely omitted on save.
       *
       * @param   {Date|String}  value  Typed or otherwise selected Date.
       *
       */
      if (value !== null && !isValidDate(value)) {
        setStartInvalidInput('Invalid time');
        incrementErrors(dayNumber);
      }
      /**
       * If someone is typing a time, don't send changes until date is
       * valid. Passes through `null`'s for processing.
       *
       * @param   {Date|String}  value  Typed or otherwise selected Date.
       *
       */
      if (isValidDate(value) || value === null) {
        const startTime = value === null ? value : formatMilitaryTime(value);
        setStartValue(value === null ? value : new Date(value));
        if (!is24Hours.current) {
          prevHours.current.start = startTime;
        }
        form.change(customHours.start, startTime);
        if (updateDayForm) {
          form.change(customHours.day, dayNumber);
        }
        /**
         * If startValue & endValue are valid, reflect by setting both in customHours
         */
        if (endValue !== null && isValidDate(endValue)) {
          form.change(customHours.end, formatMilitaryTime(endValue));
        }
      }
    },
    [endValue, incrementErrors, dayNumber, form, customHours.start, customHours.day, customHours.end]
  );

  // Update the `end` time
  const handleEndChange = useCallback(
    (value, updateDayForm = true) => {
      /**
       * Display validation errors until user completes typing the full
       * time mask. Prevents users from submitting partial hours that are
       * ultimitely omitted on save.
       *
       * @param   {Date|String}  value  Typed or otherwise selected Date.
       *
       */
      if (value !== null && !isValidDate(value)) {
        setEndInvalidInput('Invalid time');
        incrementErrors(dayNumber);
      }
      /**
       * If someone is typing a time, don't send changes until date is
       * valid. Passes through `null`'s for processing.
       *
       * @param   {Date|String}  value  Typed or otherwise selected Date.
       *
       */
      if (isValidDate(value) || value === null) {
        let endTime = value === null ? value : formatMilitaryTime(value);
        if (endTime === '0000') {
          endTime = '2400';
        }
        if (!is24Hours.current) {
          prevHours.current.end = endTime;
        }
        setEndValue(value === null ? value : new Date(value));
        form.change(customHours.end, endTime);
        if (updateDayForm) {
          form.change(customHours.day, dayNumber);
        }
      }
      /**
       * If startValue & endValue are valid, reflect by setting both in customHours
       */
      if (startValue !== null && isValidDate(startValue)) {
        form.change(customHours.start, formatMilitaryTime(startValue));
      }
    },
    [startValue, incrementErrors, dayNumber, form, customHours.end, customHours.day, customHours.start]
  );

  // Toggling local is24Hours state
  const handle24HoursChange = useCallback(() => {
    const is24HoursPrevious = is24Hours.current;
    is24Hours.current = !is24HoursPrevious;

    if (is24HoursPrevious) {
      const { start, end } = prevHours.current;
      handleStartChange(!start && !end ? null : formatMilitaryDate(start), false);
      handleEndChange(!start && !end ? null : formatMilitaryDate(end), false);
    } else {
      handleStartChange(formatMilitaryDate('0000'), false);
      handleEndChange(formatMilitaryDate('2400'), false);
    }

    form.change(customHours.day, dayNumber);
    // eslint-disable-next-line
  }, []);

  // Handle main `open24Hours` switch toggling
  // When switched off, recall previously stored `start` and `end` hours.
  useChange(() => {
    const { start, end } = prevHours.current;
    const _is24Hours = open24Hours || (+start === 0 && +end === 2400);
    set24Hours(_is24Hours);
    if (!_is24Hours) {
      handleStartChange(start && formatMilitaryDate(start));
      handleEndChange(end && formatMilitaryDate(end));
    }
    // eslint-disable-next-line
  }, [open24Hours]);

  // Whenever the form/record props `hours` changes its value, not its reference, ensure the updated values are
  // reflected in the input.
  useChange(() => {
    const { start, end } = hours;
    const _is24Hours = open24Hours || (+start === 0 && +end === 2400);
    set24Hours(_is24Hours);
    if (!_is24Hours) {
      handleStartChange(start && formatMilitaryDate(start));
      handleEndChange(end && formatMilitaryDate(end));
    }
    // eslint-disable-next-line
  }, [hours?.start, hours?.end]);

  // Validate `start` and `end` times as they change, or the app toggles 24hours for the day
  useEffect(() => {
    // if the entire segment is set to open 24/7, clear the input validation
    // if these specific hours are set to open 24 hours, clear the input validation
    if (is24Hours.current) {
      setStartInvalidInput(null);
      setEndInvalidInput(null);
      decrementErrors(dayNumber);
      return;
    }

    const { start, end } = prevHours.current;

    if (start === null && end !== start) {
      setStartInvalidInput('Must set open');
      incrementErrors(dayNumber);
    } else {
      setStartInvalidInput(null);
      decrementErrors(dayNumber);
    }

    if (end === null && end !== start) {
      setEndInvalidInput('Must set closing');
      incrementErrors(dayNumber);
    } else {
      setEndInvalidInput(null);
      decrementErrors(dayNumber);
    }
    // eslint-disable-next-line
  }, [open24Hours, is24Hours.current, startValue, endValue]);

  return {
    label,
    is24Hours: is24Hours.current,
    open247: open24Hours,
    handleStartChange,
    handleEndChange,
    handle24HoursChange,
    startValue,
    endValue,
    startInvalid: startInvalidInput,
    endInvalid: endInvalidInput,
  };
};
