import { useCallback, useState, useMemo } from 'react';
import { validateBusinessInformation, characterLimit } from '../validators';
import { fill } from '../../common/utils/array';

const defaultValidationErrors = fill(() => 0, 7);

export const useBusinessHoursValidator = () => {
  const [hoursValidationErrors, _setHoursValidationErrors] = useState(defaultValidationErrors);

  const clearErrors = useCallback(() => {
    _setHoursValidationErrors(defaultValidationErrors);
  }, []);

  const incrementErrors = useCallback((day) => {
    _setHoursValidationErrors((prev) =>
      prev.map((v, i) => {
        if (day === i) {
          return Math.min(v + 1, 1);
        }
        return v;
      })
    );
  }, []);

  const decrementErrors = useCallback((day) => {
    _setHoursValidationErrors((prev) =>
      prev.map((v, i) => {
        if (day === i) {
          return Math.max(v - 1, 0);
        }
        return v;
      })
    );
  }, []);

  const anyErrors = hoursValidationErrors.some((v) => v > 0);

  return useMemo(
    () => ({
      anyErrors,
      clearErrors,
      incrementErrors,
      decrementErrors,
    }),
    // eslint-disable-next-line
    [anyErrors]
  );
};

export const useBusinessInformationValidator = () => {
  const validateHours = useBusinessHoursValidator();

  return {
    validateHours,
    validate: validateBusinessInformation,
    characterLimit,
  };
};
