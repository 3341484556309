import { useContext, useState } from 'react';
import { TextInput, useRecordContext } from 'react-admin';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Delete } from '@mui/icons-material';
import { useForm } from 'react-final-form';

import { createUploadWidget } from '../services/cloudinary';
import { AuthTokenContext } from '../../auth/providers/auth-token-provider';
import { NoImage as BaseNoImage } from './NoImage';
import { components } from '../styles/component';
import { useCustomNotify } from '../utils';
import { useChange } from '../hooks/change';

const { avatarContainer, avatar, deleteButton, iconButton, imgTag, imageContainer } = components.cloudinaryUpload;

export const CloudinaryUpload = ({ field, recordType, NoImage = BaseNoImage }) => {
  const record = useRecordContext();

  const notify = useCustomNotify();
  const hasImage = !!(record && record[field] && record[field].url);
  const authToken = useContext(AuthTokenContext);
  const [cloudinaryImageUrl, setCloudinaryImageUrl] = useState(hasImage ? record[field]['url'] : null);
  const [showDialog, setShowDialog] = useState(false);
  const form = useForm();

  useChange(() => {
    if (hasImage) {
      setCloudinaryImageUrl(record[field]['url']);
    }
  }, [hasImage]);

  const isAvatar = recordType === 'user';
  const isUserBackground = isAvatar && field === 'backgroundImage';
  const aspectRatio = isAvatar && !isUserBackground ? '1/1' : '390/200';

  const uploadWidget = createUploadWidget(authToken, (error, result) => {
    if (!error) {
      if (result.event === 'success') {
        const newUrl = result.info.secure_url;
        setCloudinaryImageUrl(newUrl);
        form.change(`${field}.url`, newUrl);
        form.change(`${field}.publicId`, result.info.public_id);
        form.change(`${field}.resourceType`, result.info.resource_type.toUpperCase());
        form.change(`${field}.type`, result.info.type.toUpperCase());
        notify({
          message: 'Uploaded image to cloudinary',
        });
      }
    } else {
      console.log('Error uploading image: ', error);
      notify({
        message: `Error uploading image. \n${error}`,
        type: 'error',
      });
    }
  });

  const showUploadWidget = () => {
    uploadWidget.open();
  };

  const handleDialogOpen = () => {
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleDelete = () => {
    setCloudinaryImageUrl(null);
    setShowDialog(false);
    form.change(field, null);
  };

  return (
    <>
      <Box sx={{ ...avatarContainer, aspectRatio }}>
        {isAvatar && !isUserBackground ? (
          <Avatar src={cloudinaryImageUrl} style={{ ...avatar }} onClick={showUploadWidget} />
        ) : (
          <>
            {cloudinaryImageUrl === null && <NoImage />}
            <img src={cloudinaryImageUrl} style={{ ...imgTag }} onClick={showUploadWidget} />
          </>
        )}

        {cloudinaryImageUrl !== null && (
          <IconButton aria-label="delete" style={{ ...iconButton }} onClick={handleDialogOpen}>
            <Delete />
          </IconButton>
        )}
        <Box sx={imageContainer}>
          {!!cloudinaryImageUrl ? (
            <>
              <TextInput source={`${field}.url`} />
              <TextInput source={`${field}.publicId`} />
              <TextInput source={`${field}.resourceType`} />
              <TextInput source={`${field}.type`} />
            </>
          ) : (
            <TextInput source={field} />
          )}
        </Box>
      </Box>

      <Dialog
        open={showDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete this image?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You must save changes for this to take effect.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} autoFocus style={deleteButton}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
