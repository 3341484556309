import { useEffect, useMemo, useState } from 'react';

export const useReportStatus = (closedAt) => {
  const [closed, setClosedAt] = useState(closedAt !== null);
  const [reportResolveable, setReportResolvable] = useState(false);

  /**
   * Calculate if the report has already been resolved.
   *  If resolved, the report is not resolveable.
   */
  useEffect(() => {
    setReportResolvable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closedAt]);

  return useMemo(() => ({ closed, setClosedAt, reportResolveable, setReportResolvable }), [closed, reportResolveable]);
};

export const useReportedItemStatus = (reportedItem, TYPE) => {
  const [hidden, setHidden] = useState(false);
  const [banned, setBanned] = useState(false);
  const [deleted, setDeleted] = useState(false);

  /**
   * Ensure we are not getting undefined states
   */
  useEffect(() => {
    switch (TYPE) {
      case 'BUSINESS':
        setHidden(reportedItem.hidden);
        break;
      case 'REVIEW':
        setHidden(reportedItem.hidden);
        if (reportedItem.author) setBanned(reportedItem.author.banned);
        break;
      case 'USER':
        setBanned(reportedItem.banned);
        break;

      default:
        break;
    }
  }, [TYPE, reportedItem]);

  return useMemo(() => ({ hidden, banned, deleted, setHidden, setBanned, setDeleted }), [banned, deleted, hidden]);
};
