import { useCallback, useContext, useEffect, useState, useRef, createContext } from 'react';
import { useRecordContext } from 'react-admin';
import { useForm } from 'react-final-form';

import { getCategories } from '../queries/categories';
import { useMounted } from './mounted';

export const useCategoriesProvider = (_categories = []) => {
  const record = useRecordContext();
  const form = useForm();
  const mounted = useMounted();
  const categoryOptions = useRef([]);
  const [categories, setCategories] = useState(() => _categories.map((c) => ({ ...c, name: c.name || c.label })));
  const [category, setCategory] = useState(() =>
    _categories?.[0] ? { ..._categories?.[0], name: _categories?.[0].name || _categories?.[0].label } : null
  );

  // Fetch and cache category options
  const fetchCategories = useCallback(async () => {
    if (!categoryOptions.current.length) {
      categoryOptions.current = await getCategories();
    }
    if (mounted.current) {
      setCategories(categoryOptions.current);
      // If the form does not yet have a category, default one.
      if (!category) {
        form.change('categories', [categoryOptions.current?.[0]]);
        setCategory(categoryOptions.current?.[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, setCategory, setCategories]);

  // Fetch categories list on mount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchCategories, []);

  // Ensure image properties are present for the category
  useEffect(() => {
    if (category?.id && !category?.image) {
      const _category = categories.find((c) => c.id === category.id && !!c.image);
      if (_category) {
        setCategory(_category);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, category?.id, category?.image, record?.categories]);

  return { category, setCategory, categories, fetchCategories };
};

export const CategoriesContext = createContext({});
export const CategoriesContextProvider = ({ children, categories }) => {
  const form = useForm();

  const context = useCategoriesProvider(categories?.length ? categories : form.getState().values.categories || []);

  return <CategoriesContext.Provider value={context}>{children}</CategoriesContext.Provider>;
};

export const useCategories = () => useContext(CategoriesContext);
