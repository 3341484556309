// Styles for all user reported content components
export const list = {
  view: {
    searchInput: {
      margin: '0rem 1rem',
    },
    empty: {
      border: 0,
    },
  },
  name: {
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      width: '3rem',
      height: '3rem',
    },
  },
  badge: {
    backgroundColor: 'var(--PRIMARY_MAIN)',
    borderRadius: '50%',
    boxShadow: '0px 0px 0px 2px currentColor',
    color: 'white',
    fontFamily: 'sans-serif',
    fontWeight: 900,
    height: '0.8rem',
    padding: '4px',
    textAlign: 'center',
    width: '0.8rem',
  },
};

export const detail = {
  edit: {
    view: {
      NavButton: {
        '&.Mui-disabled': {
          border: '1px solid rgba(0, 0, 0, 1)',
          borderColor: 'currentColor',
          color: 'inherit',
          opacity: '0.4',
        },
      },
      NavButtonSelected: {
        '&.Mui-disabled': {
          border: '1px solid rgba(0, 0, 0, 1)',
          borderColor: 'currentColor',
          color: 'inherit',
          opacity: '0.9',
        },
      },
    },
    user: {},
    business: {},
    review: {},
    general: {},
  },
  reporter: {},
};
