import { useRecordContext } from 'react-admin';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { list } from '../styles';

const useStyles = makeStyles({ ...list.status });

export const BusinessListStatus = () => {
  const record = useRecordContext();
  const classes = useStyles();
  if (!record) return null;
  const statusColor = record.hidden ? 'hidden' : 'active';

  return record ? (
    <Typography className={`${classes.label} ${classes[statusColor]}`}>
      {record.hidden ? 'Hidden' : 'Active'}
    </Typography>
  ) : null;
};
