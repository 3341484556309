// JS port of Google's libphonenumber library
// https://www.npmjs.com/package/google-libphonenumber
import { PhoneNumberUtil, PhoneNumberFormat as phoneFormat } from 'google-libphonenumber';
import { isNull, isString } from 'lodash';
import { PhoneNumberMask } from './masks';

const phoneUtil = PhoneNumberUtil.getInstance();

// @TODO: Add more types
const PhoneNumberFormatTypes = {
  US: 'US',
  E164: phoneFormat.E164,
  NATIONAL: phoneFormat.NATIONAL,
  INTERNATIONAL: phoneFormat.INTERNATIONAL,
};

const parsePhoneNumber = (phoneNumber, regionCode = 'US') => {
  try {
    return phoneUtil.parseAndKeepRawInput(phoneNumber, regionCode);
  } catch (err) {
    console.log('Error parsing phone number into raw input: ', err);
  }
};

// Expects a raw input
const parseCountryCode = (phoneNumber) => {
  try {
    return phoneNumber.getCountryCode();
  } catch (err) {
    console.log('Error parsing the country code: ', err);
  }
};

// Can call directly to parse other types -- e.g. back to E164
export const Format = (phoneNumber, type = 'NATIONAL') => {
  try {
    const number = parsePhoneNumber(phoneNumber);
    return phoneUtil.format(number, PhoneNumberFormatTypes[type]);
  } catch (err) {
    console.log('Error formatting the phone number: ', phoneNumber);
  }
};

/**
 * @param {String} phoneNumber
 * @returns String
 */
export const FormatPhoneNumberRaw = (phoneNumber = '') => {
  try {
    if (phoneNumber) {
      const phone = phoneNumber.replace(/^\+[0-9]/g, '');
      // Only format numbers that are the correct amount of
      // numbers in a phone number otherwise we break the
      // caret placement
      if (phone.length === 10) {
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }
    }
    return phoneNumber;
  } catch (err) {
    console.log('Error formatting phone number: ', err);
    return phoneNumber;
  }
};

/**
 * @TODO: Revisit this. Leaving in since this library is really powerful
 * and we will probably need some of the functionality.
 * In order to use this properly, we need to caret track
 * Looks like the library breaks the caret placement when formatting
 * the phone number with chars (,),-, which cause the cursor to jump
 * to the end when the caret is incorrect.
 * @param {String} phoneNumber
 * @returns String
 */
export const FormatPhoneNumber = (phoneNumber = '') => {
  try {
    return phoneNumber && isString(phoneNumber) && phoneNumber.length > 1 ? Format(phoneNumber) : phoneNumber;
  } catch (err) {
    console.log('Error formatting phone number: ', err);
    return phoneNumber;
  }
};

export const PhoneNumberValidation = (value, allValues) => {
  if (!value) return undefined;
  value = PhoneNumberMask(value);
  return isNull(value.match(/^[+]?[0-9]{11,14}$/)) ? 'Must be in the format +12223334444' : undefined;
};
