// Styles for all reusable components
import theme from './theme';

export const components = {
  selectInput: {
    minWidth: '100%',
    marginTop: '0',
  },
  detailList: {
    fieldset: {
      width: '100%',
    },
    list: {
      button: {
        cursor: 'pointer',
        fontSize: '0.9rem',
      },
      item: {
        justifyContent: 'space-between',
        color: theme.palette.primary.light,
      },
    },
  },
  cloudinaryUpload: {
    avatarContainer: {
      position: 'relative',
      cursor: 'pointer',
    },
    avatar: {
      width: '100%',
      height: '100%',
    },
    iconButton: {
      position: 'absolute',
      right: '0.5rem',
      bottom: '0.5rem',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    imgTag: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      display: 'block',
    },
    imageContainer: {
      display: 'none',
    },
    deleteButton: {
      backgroundColor: '#f12b2c',
      color: 'white',
    },
  },
  detailHeader: {
    container: {
      display: 'flex',
    },
    header: {
      paddingTop: '1.5rem',
      marginBottom: '2rem',
    },
    headerTop: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '2rem',
    },
    headerTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    noticeContainer: {
      marginLeft: '1rem',
      display: 'inline-flex',
      alignItems: 'center',
    },
    noticeIcon: {
      marginRight: '.25rem',
      height: '1.25rem',
      width: '1.25rem',
    },
    chevronLeftIcon: {
      marginRight: '.5rem',
      color: '#232F78',
      height: '1.5rem',
      width: '1.5rem',
    },
    backButton: {
      color: '#232F78',
      cursor: 'pointer',
      display: 'flex',
      fontSize: '1rem',
      fontWeight: '700',
      lineHeight: '1.4',
    },
    headerTitleCtas: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    ctas: {
      '& > Button:not(:last-of-type)': {
        marginRight: '.875rem',
      },
    },
    subtitleText: {
      color: 'RGBA(0,0,0,0.5)',
    },
  },
  emptyList: {
    container: {
      'margin-left': '1.75rem',
      'margin-bottom': '1.75rem',
    },
  },
  lastActivity: {
    reviewText: {
      fontSize: '0.875rem',
    },
  },
  layout: {
    avatar: {
      height: '1.7rem',
      width: '1.7rem',
    },
    customSidebar: {
      drawerPaper: {
        height: '100%',
        color: '#fff',
      },
    },
  },
  listActions: {
    container: {
      marginBottom: '2rem',
      display: 'flex',
      width: '100%',
      '& Button + Button': {
        marginLeft: '1rem',
      },
    },
    muiButtonRoot: {
      marginRight: '1rem',
      backgroundColor: '#fefefe',
      borderRadius: '12px',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      color: '#232F78',
      textDecoration: 'uppercase',
      '&:hover': {
        backgroundColor: '#fefefe',
        outline: '0.5px solid #000',
      },
    },
    btnSelected: {
      backgroundColor: '#232F78',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#232F78',
        color: '#fff',
      },
    },
    btnCreate: {},
    titleText: {
      marginTop: '2.25rem',
      marginBottom: '2rem',
      textTransform: 'capitalize',
    },
    buttonAlignment: {
      flexGrow: '1',
    },
  },
  mediaThumbnail: {
    modalContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    highlightContainer: {
      display: 'inline-block',
      marginTop: '5%',
      padding: '1rem',
      maxWidth: '50%',
      minHeight: '100px',
      maxHeight: '90%',
      borderRadius: '10px',
      position: 'relative',
      textAlign: 'center',
      background: '#fff',
    },
    highlightWrapper: {
      display: 'inline-block',
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    highlight: {
      position: 'absolute',
      top: '-25px',
      right: '-25px',
      color: '#fff',
    },
    modalSettingsStyle: {
      borderRadius: '8px',
      minWidth: '100px',
      maxWidth: '800px',
      width: '100%',
      minHeight: '100px',
      maxHeight: '700px',
      height: 'auto',
    },
    miniSettingsStyle: {
      aspectRatio: '144/88',
      borderRadius: '8px',
      display: 'block',
      height: '100%',
      objectFit: 'cover',
      width: '100%',
    },
    playCircle: {
      color: 'white',
      filter: 'drop-shadow(0 0 0.5rem hsl(0, 0%, 0%, 0.5))',
      height: '3rem',
      left: '50%',
      marginLeft: '-1.5rem',
      marginTop: '-1.5rem',
      position: 'absolute',
      textAlign: 'center',
      top: '50%',
      width: '3rem',
    },
    container: {
      position: 'relative',
      textAlign: 'center',
      cursor: 'pointer',
    },
  },
  noImage: {
    container: {
      backgroundColor: '#737373',
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
      color: 'white',
      fontSize: '1rem',
    },
    noImage: {
      textAlign: 'left',
    },
    imgTag: {
      width: '4rem',
      height: '4rem',
      display: 'block',
      marginRight: '1rem',
      objectFit: 'cover',
    },
  },
  status: {
    fieldset: {
      width: '100%',
    },
    header: {
      justifyContent: 'space-between',
      marginBottom: '1.5rem',
      width: '100%',
    },
    violations: {
      marginBottom: '1rem',
    },
  },
  attributeArrayContainer: {
    display: 'flex',
    marginBottom: '1rem',
  },
};
