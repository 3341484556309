export function checkEnvVars(vars, varsToSkip) {
  const result = [];

  checkEmptyObject(vars, result, varsToSkip, null);
  return result;
}

function checkEmptyObject(obj, arr, varsToSkip, oldKey) {
  if (isEmpty(obj)) {
    return true;
  }
  return isEmpty(
    Object.entries(obj).map(([key, value]) => {
      if (isEmpty(value) && !varsToSkip[key]) {
        const missingVar = oldKey ? `${oldKey}.${key}` : `${key}`;
        return arr.push(missingVar);
      }

      if (value instanceof Object && varsToSkip[key]) {
        return;
      }

      if (value instanceof Object) {
        return checkEmptyObject(value, arr, varsToSkip, key);
      }
    })
  );
}

function isEmpty(data) {
  if (data === null || data === undefined || data === '') {
    return true;
  }

  return false;
}
