import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRedirect, useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Link, Typography, Button } from '@material-ui/core';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

import { components } from '../styles/component';

const { subtitleText } = components.detailHeader;
const useStyles = makeStyles({ ...components.detailHeader });

/**
 * Reusable component that displays a chevron back button, two CTA's, along with
 * a title.
 *
 * @param   {String}   title         Displayed title of the detail header
 * @param   {String}   subtitle      Displayed subtitle of the detail header (h3 variant)
 * @param   {String}   chevronLabel  "Go back" label, such as "Go to businesses"
 * @param   {String}   chevronLink   "Go back" link, used if we have a history.length > 1
 *
 * @param   {Boolean}  showHeader    Toggles showing of the "Header" portion of
 *                                   the DetailHeader, currently just the Chevron.
 *
 * @param   {Object}   ctaPrimary    Object of label & events for primary CTA
 * @param   {Object}   ctaSecondary  Object of label & events for secondary CTA
 *
 * @return  {React.ReactNode} React.ReactNode
 */
export const DetailHeader = ({
  title = 'Business Detail',
  subtitle = null,
  showHeader = true,
  notice = null,
  chevronLabel = 'Back to businesses',
  chevronLink = '/business',
  ctaPrimary,
  ctaSecondary,
}) => {
  const {
    header,
    headerTop,
    headerTitle,
    noticeIcon,
    container,
    backButton,
    chevronLeftIcon,
    headerTitleCtas,
    ctas,
    noticeContainer,
  } = useStyles();
  const history = useHistory();
  const record = useRecordContext();
  const redirect = useRedirect();

  const redirectRecord = history.location?.state?.redirectRecord;
  return (
    <header className={header}>
      {showHeader || showHeader === undefined ? (
        <Box className={headerTop}>
          <Box className={container}>
            <Link
              className={backButton}
              onClick={() => {
                /**
                 * If we got navigated through a 'new tab', don't goBack to last location.
                 * This preserves us navigating back to lists with filters applied when not starting
                 * new history states.
                 */
                if (history.length <= 1 || !redirectRecord) {
                  history.push(chevronLink);
                } else if (redirectRecord !== undefined) {
                  /**
                   * If redirected from user to business, support redirecting back to origin user.
                   */
                  const resourceId = redirectRecord.id;
                  const isUser = redirectRecord.__typename === 'User';
                  redirect(
                    'edit',
                    isUser ? '/user' : '/business',
                    resourceId,
                    {},
                    {
                      redirectRecord: record,
                    }
                  );
                } else {
                  history.goBack();
                }
              }}
            >
              <ChevronLeft className={chevronLeftIcon} />
              {redirectRecord ? (
                <>
                  {redirectRecord.__typename === 'User'
                    ? `Back to ${redirectRecord.fullName}`
                    : `Back to ${redirectRecord.name}`}
                </>
              ) : (
                <>{chevronLabel}</>
              )}
            </Link>
          </Box>
        </Box>
      ) : null}
      <Box className={headerTitleCtas}>
        <Box>
          <Typography variant="h1" className={headerTitle}>
            {title}
            {notice ? (
              <Box className={noticeContainer}>
                <notice.Icon className={noticeIcon} />
                <Typography variant="h3" style={{ ...subtitleText }}>
                  {notice.label}
                </Typography>
              </Box>
            ) : null}
          </Typography>

          {subtitle ? (
            <>
              <Typography mt={1} variant="h3" style={{ ...subtitleText }}>
                {subtitle}
              </Typography>
            </>
          ) : null}
        </Box>
        <Box className={ctas}>
          {ctaPrimary ? (
            <Button variant="outlined" color="primary" {...ctaPrimary?.events}>
              {ctaPrimary.label}
            </Button>
          ) : null}
          {ctaSecondary ? (
            <Button variant="outlined" color="primary" {...ctaSecondary?.events}>
              {ctaSecondary.label}
            </Button>
          ) : null}
        </Box>
      </Box>
    </header>
  );
};
