import { useState } from 'react';
import { Avatar, Typography } from '@material-ui/core';

export const UserProfile = ({ data, ...props }) => {
  const { avatarImage, backgroundImage, bio, fullName } = data?.reportedItem;
  const [visible, setVisible] = useState(true);
  let style = {};

  if (backgroundImage) {
    style.backgroundImage = `url(${backgroundImage.url})`;
  }

  return (
    <div className="Profile Profile--user" {...props} onClick={() => setVisible(!visible)}>
      <div className="Profile-background" style={{ ...style }} />
      <div className="Profile-card" style={{ opacity: visible ? 1 : 0 }}>
        <Avatar
          className="Profile-avatar"
          alt={fullName}
          src={avatarImage?.url}
          style={{ width: '6.25em', height: '6.25em', fontSize: '1em' }}
          onClick={() => setVisible(false)}
        />

        <Typography style={{ fontSize: '1.1875em', lineHeight: '1' }}>{fullName}</Typography>
        <Typography style={{ fontSize: '0.875em', marginTop: '1.7143em', textAlign: 'center' }}>{bio}</Typography>
      </div>
    </div>
  );
};
