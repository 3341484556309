import { useCallback } from 'react';
import { resolveReport as resolveReportMutation } from '../mutations';
import { isUndefined } from 'lodash';

export const useResolveReport = (notify, reportId, reportType, reportStatus) => {
  const resolveReport = useCallback(
    async (status) => {
      const response = await resolveReportMutation(reportId, reportType, status);
      if (!isUndefined(response?.errors)) {
        return notify({
          message: 'Unable to update ticket.',
          res: response,
        });
      }

      const notifyMessage = status === 'VALID' ? 'resolved' : 'dismissed';
      await reportStatus.setClosedAt(new Date());
      notify(`Ticket ${notifyMessage}`, { duration: 1 });
    },
    [reportStatus, notify, reportId, reportType]
  );

  const resolveReportValid = useCallback(() => {
    if (!reportStatus.reportResolveable) {
      return notify({
        message: 'Report must have an action taken against it to be resolved',
        type: 'warning',
      });
    }
    /**
     * Prevent Users from changing decisions on reports
     */
    !reportStatus.closed ? resolveReport('VALID') : notify('This report has already been addressed.');
  }, [reportStatus, resolveReport, notify]);

  const resolveReportInvalid = useCallback(() => {
    /**
     * Prevent Users from changing decisions on reports
     */
    !reportStatus.closed ? resolveReport('INVALID') : notify('This report has already been addressed.');
  }, [notify, reportStatus, resolveReport]);

  const makeReportResolveable = useCallback(() => {
    reportStatus.setReportResolvable(true);
  }, [reportStatus]);

  return {
    resolveReportInvalid,
    resolveReportValid,
    makeReportResolveable,
    reportResolveable: reportStatus.reportResolveable,
  };
};
