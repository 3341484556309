import { SimpleShowLayout } from 'react-admin';
import { Box, List, Paper, Typography } from '@material-ui/core';

import { ReviewItem } from '../../ticket/components';
import { NonInput } from '../../common/utils';
import { detail } from '../styles';

const { container, listContainer } = detail.activity;

export const UserActivities = ({ data }) => {
  const { reviews = [] } = data;

  if (reviews.length === 0) {
    return (
      <Box sx={container}>
        <Typography variant="h6">There are no activities for this user.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={container}>
      <Paper>
        <SimpleShowLayout>
          <NonInput>
            <Box sx={listContainer}>
              <List>
                {reviews.map((reviewRecord) => (
                  <ReviewItem key={reviewRecord.id} record={reviewRecord} />
                ))}
              </List>
            </Box>
          </NonInput>
        </SimpleShowLayout>
      </Paper>
    </Box>
  );
};
