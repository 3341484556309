import { createContext, useMemo, useState } from 'react';

export const AuthTokenContext = createContext(null);

const AuthTokenProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const value = useMemo(() => ({ token, setToken }), [token, setToken]);

  return <AuthTokenContext.Provider value={value}>{children}</AuthTokenContext.Provider>;
};

export default AuthTokenProvider;
