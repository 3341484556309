import { useMemo, useCallback } from 'react';
import { Edit } from 'react-admin';
import { useCustomNotify } from '../../common/utils';
import { Title } from '../../common/components';
import { BusinessForm, transformData } from './BusinessForm';

export const BusinessEditForm = ({ classNameSimpleForm = '', classNameEdit = '', formProps, data }) => {
  const notify = useCustomNotify();

  const onFailure = useCallback(
    (error) => {
      notify({
        message: error.message,
        type: 'error',
      });
    },
    [notify]
  );

  const title = useMemo(() => <Title basePath={formProps.basePath} />, [formProps.basePath]);

  const simpleFormProps = useMemo(
    () => (classNameSimpleForm ? {} : { style: { padding: '1rem' } }),
    [classNameSimpleForm]
  );

  return (
    <Edit
      className={classNameEdit}
      title={title}
      onFailure={onFailure}
      mutationMode="pessimistic"
      transform={transformData}
      {...formProps}
    >
      <BusinessForm simpleFormProps={simpleFormProps} data={data} />
    </Edit>
  );
};
