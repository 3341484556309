import { useCallback, useState } from 'react';
import { Show, FunctionField } from 'react-admin';
import { Tabs, Tab } from '@material-ui/core';
import { get } from 'lodash';

import { Div, DetailHeader, ScrollToTop, TabPanel } from '../../common/components';
import { UserInformation, UserActivities } from '../components';
import theme from '../../common/styles/theme';

const UserEdit = (props) => {
  const cardContainerStyles = get(theme, 'overrides.CardContainer');
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = useCallback(
    (_, newValue) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab]
  );

  return (
    <ScrollToTop>
      <Show title="&#8203;" actions={null} {...props} style={cardContainerStyles}>
        <>
          <FunctionField
            render={(record) => {
              const email = record?.protectedData?.email;
              const phoneNumber = record?.protectedData?.phoneNumber;

              const ctaEmail = email
                ? {
                    label: 'Message',
                    events: {
                      title: `Email ${email}`,
                      onClick: () => {
                        window.open(`mailto:${email}`);
                      },
                    },
                  }
                : null;

              const ctaPhone = phoneNumber
                ? {
                    label: 'Call',
                    events: {
                      title: `Call ${phoneNumber}`,
                      onClick: () => {
                        window.open(`tel:${phoneNumber}`);
                      },
                    },
                  }
                : null;

              return (
                <DetailHeader
                  ctaPrimary={ctaPhone}
                  ctaSecondary={ctaEmail}
                  chevronLabel="Back to Users"
                  chevronLink="/user/"
                  title={record.fullName}
                />
              );
            }}
          />
          <Div>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Account information and activities">
              <Tab label="Account information" aria-label="Account information" />
              <Tab label="Activities" aria-label="Activities" />
            </Tabs>
          </Div>
          <TabPanel value={selectedTab} index={0}>
            <FunctionField render={(record) => <UserInformation props={props} data={record} />} />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <FunctionField render={(record) => <UserActivities props={props} data={record} />} />
          </TabPanel>
        </>
      </Show>
    </ScrollToTop>
  );
};

export default UserEdit;
