/**
 * Checks if a value is empty by `undefined`, and empty string, or a null is placed.
 *
 * @param   {any}  value  Value to check
 *
 * @return  {boolean}
 */
export const emptyValue = (value) => {
  return value === undefined || value === '' || value === ' ' || value === 'NULL' || value === 'null' || value === null;
};
