import { Box, Typography } from '@material-ui/core';

import { components } from '../styles/component';

const { container, noImage, imgTag } = components.noImage;

// Note: This is currently made for use within the CloudinaryUpload component
// for businesses that do not have images. It could be expanded in the future to
// work for other use cases.
export const NoImage = ({ category }) => {
  if (category === null) return null;

  return (
    <Box sx={container}>
      <img src={category?.image?.url} style={imgTag} />
      <Box sx={noImage}>
        <Typography variant="body1">No image found</Typography>
        <Typography variant="body1" mt={1}>
          Using category fallback
        </Typography>
      </Box>
    </Box>
  );
};
