import { useEffect, useCallback, useState } from 'react';
import { updateReviewVisibility } from '../mutations/update-review-visibility';

export const useReview = (review, notify) => {
  const { id, hidden } = review;
  const [anchorEl, setAnchorEl] = useState(null);
  const [reviewHidden, setHidden] = useState(null);

  useEffect(() => {
    if (reviewHidden === null) {
      setHidden(hidden);
    }
  }, [hidden, setHidden, reviewHidden]);

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const toggleHideReview = useCallback(async () => {
    const mutationCall = reviewHidden ? 'Unhidden' : 'Hidden';
    const notifyText = !reviewHidden ? 'Hid' : 'Unhid';
    const response = await updateReviewVisibility({ id, hidden: reviewHidden });
    if (
      (response.errors === undefined &&
        response[`custom${mutationCall}Review`] !== undefined &&
        response[`custom${mutationCall}Review`] === true) ||
      !(response[`custom${mutationCall}Review`] !== undefined && response[`custom${mutationCall}Review`] === false)
    ) {
      notify({
        message: `${notifyText} review`,
      });
      setHidden(!reviewHidden);
      handleClose();
      return true;
    } else {
      notify({
        message: `Unable to ${notifyText}e review.`,
        response: response,
      });
      handleClose();
      return false;
    }
  }, [id, reviewHidden, notify, handleClose]);

  return {
    reviewHidden,
    handleClick,
    handleClose,
    toggleHideReview,
    anchorEl,
  };
};
