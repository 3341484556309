import { doQuery } from '../providers/data-provider';

export const getCategory = async (search) => {
  const query = `
      query QueryCategories {
        queryCategory(filter: { label: { allofterms: "${search}" } }) {
          id
          label
        }
      }
    `;

  const result = await doQuery(query, {});

  if (result.errors) {
    console.warn('Error getting categories', result.errors);
  }

  return (
    result.data?.queryCategory?.map(({ id }) => {
      return { id: id };
    }) || []
  );
};

export const getCategories = async () => {
  const query = `
    query QueryCategories {
      queryCategory(order: { asc: label }) {
        id
        label
        image {
          url
        }
      }
    }
  `;

  const result = await doQuery(query, {});

  if (result.errors) {
    console.warn('Error getting categories', result.errors);
  }

  return result.data.queryCategory.map(({ id, label, image }) => {
    return { id, name: label, image };
  });
};
