import { doQuery } from '../providers/data-provider';

let jwt = '';

export const createUploadWidget = (token, callback) => {
  jwt = token;

  const generateSignature = async (callback, params_to_sign) => {
    try {
      const signature = await getCloudinaryUploadWidgetSignature(params_to_sign);
      callback(signature);
    } catch (error) {
      console.log('Error generating image upload signature: ', error);
    }
  };

  const getCloudinaryUploadWidgetSignature = async (params_to_sign) => {
    const query = `
      query getCloudinaryUploadWidgetSignature($params_to_sign: CloudinaryUploadWidgetSignatureInput!) {
        getCloudinaryUploadWidgetSignature(params_to_sign: $params_to_sign) {
          signature
        }
      }
    `;

    const response = await doQuery(query, { params_to_sign });

    return response.data.getCloudinaryUploadWidgetSignature.signature;
  };

  return window.cloudinary.createUploadWidget(
    {
      cloudName: 'hypergiant',
      uploadPreset: 'hypergiant-signed',
      apiKey: '972617317855775',
      uploadSignature: generateSignature,
      sources: ['local', 'url'],
      showAdvancedOptions: false,
      cropping: true,
      multiple: false,
      defaultSource: 'local',
      clientAllowedFormats: ['jpg', 'png', 'heic'],
      styles: {
        frame: {
          background: '#4B506D99',
        },
        palette: {
          window: '#F7F8FC',
          windowBorder: '#90A0B3',
          tabIcon: '#646BF6',
          menuIcons: '#5A616A',
          textDark: '#2D3748',
          textLight: '#FFFFFF',
          link: '#646BF6',
          action: '#EFA400',
          inactiveTabIcon: '#232F78',
          error: '#F12B2C',
          inProgress: '#646BF6',
          complete: '#4EA663',
          sourceBg: '#E4EBF1',
        },
        fonts: {
          default: null,
          'Mulish, sans-serif': { url: 'https://fonts.googleapis.com/css2?family=Mulish', active: true },
        },
      },
    },
    callback
  );
};
