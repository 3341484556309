import { useMemo } from 'react';
import { Show, FunctionField } from 'react-admin';

import { Title, ScrollToTop } from '../../common/components';
import { TicketDetails } from '../components';

const TicketEdit = (props) => {
  const title = useMemo(() => <Title basePath={props.basePath} />, [props.basePath]);

  return (
    <ScrollToTop>
      <Show component="div" className="Container" title={title} actions={null} {...props}>
        <FunctionField source="id" render={(record) => <TicketDetails props={props} data={record} />} />
      </Show>
    </ScrollToTop>
  );
};

export default TicketEdit;
