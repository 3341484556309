import { useEffect, useState } from 'react';
import { AutocompleteArrayInput } from 'react-admin';

export const AttributeArrayInput = ({ attributes: fetchedAttributes, fullWidth, validate }) => {
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    if (fetchedAttributes) {
      setAttributes(fetchedAttributes.sort((a, b) => (a.label > b.label ? 1 : -1)));
    }
  }, [fetchedAttributes]);

  if (fetchedAttributes.length === 0) return null;

  return (
    <AutocompleteArrayInput
      chip={{ size: 'small' }}
      choices={attributes}
      format={(value) => value && value.map((v) => v.id)}
      options={{ variant: 'outlined' }}
      optionText="label"
      parse={(value) => value && value.map((v) => ({ id: v }))}
      source="attributes"
      translateChoice={false}
      fullWidth={fullWidth}
      validate={validate}
    />
  );
};
