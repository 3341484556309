import { doQuery } from '../../common/providers/data-provider';

export const updateReviewVisibility = async (review) => {
  const query = `
      mutation {
        custom${review.hidden ? 'Unhide' : 'Hide'}Review(reviewId: "${review.id}")
      }
    `;

  return await doQuery(query, {});
};
