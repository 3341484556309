import { Typography } from '@material-ui/core';
import { FormatDate } from '../utils';

export const ListCreatedAt = ({ record }) => {
  if (!record || !record.createdAt) return null;

  return (
    <Typography variant="body2" color="textSecondary" style={{ minWidth: '128px' }}>
      <b>
        <FormatDate date={record.createdAt} />
      </b>
    </Typography>
  );
};
