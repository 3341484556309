import { useRecordContext } from 'react-admin';
import { Box, Typography, Avatar } from '@mui/material';

import { list } from '../styles';

const { container, avatar } = list.name;

const containerStyle = { display: 'flex', alignItems: 'center' };

export const UserListName = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Box sx={container} style={containerStyle}>
      <Avatar src={record?.avatarImage?.url} alt={record?.fullName} style={avatar} />
      <Box>
        <Typography variant="body1">{record.fullName}</Typography>
      </Box>
    </Box>
  );
};
