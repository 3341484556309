import { useForm, useFormState } from 'react-final-form';
import { memo, useCallback, useMemo } from 'react';
import { BooleanInput, SelectInput, TextInput } from 'react-admin';
import { Box, FormLabel, Tooltip, Typography } from '@mui/material';

import { Div, CustomInput } from '../../common/components';
import { detail } from '../styles';

const { checkboxContainer, subtitleLabel, subtitleContainer } = detail.information;

export const BusinessAttributes = memo(({ validate, helperText, characterLimit }) => {
  const form = useForm();
  const { values } = useFormState();
  const tooltips = {
    hidden: values.hidden ? 'A hidden business cannot be featured.' : null,
    hiddenToggle: !values.hidden
      ? values.featured
        ? 'Hiding will unfeature this business.'
        : 'Hide business content'
      : 'Toggle to unhide',
  };

  return (
    <>
      <Div style={checkboxContainer}>
        <BooleanInput label="Featured" source="featured" disabled={values.hidden} />
        <Tooltip title={tooltips.hiddenToggle} placement="top">
          <Box>
            <BooleanInput
              label="Hidden"
              source="hidden"
              onChange={(value) => value && form.change('featured', false)}
            />
          </Box>
        </Tooltip>
        <BooleanInput label="Black owned" source="blackOwned" />
        <BooleanInput label="Online Transactions" source="facilitatesTransactions" />
      </Div>
      <Div style={checkboxContainer}>
        <BooleanInput
          label="Flexible Location"
          source="flexibleLocation"
          onChange={(value) => {
            if (value) {
              form.change('location.coordinates', null);
            }
          }}
        />
      </Div>
      <Typography variant="body2" color="textSecondary">
        {tooltips.hidden}
      </Typography>
      <Div style={subtitleContainer}>
        <CustomInput
          source="subtitle"
          formLabel="Subtitle"
          tooltipText={helperText.subtitle}
          characterLimit={characterLimit.subtitle}
          validate={validate.subtitle}
        />
      </Div>
    </>
  );
});
BusinessAttributes.displayName = 'BusinessAttributes';
