// Styles for all user components
export const list = {
  view: {
    title: {
      marginTop: '2.25rem',
      textTransform: 'capitalize',
    },
    topToolBar: {
      marginRight: '1rem',
    },
    searchInput: {
      margin: '0rem 1rem',
    },
    empty: {
      border: 0,
    },
  },
  name: {
    container: {
      display: 'flex',
    },
    avatar: {
      marginRight: '1.5rem',
      height: '3rem',
      width: '3rem',
    },
  },
  status: {
    container: {
      display: 'flex',
    },
    status: {
      fontSize: '.875rem',
      fontWeight: '600',
    },
    Active: {
      color: '#1BC172',
    },
    Banned: {
      color: '#F12B2C',
    },
  },
};

export const detail = {
  avatarLabel: {
    fontSize: '0.5rem',
  },
  activity: {
    container: {
      marginTop: '.75rem',
      padding: '.5rem',
    },
    listContainer: {
      width: '100%',
    },
  },
  information: {
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    container: {
      paddingTop: '2rem',
    },
    generalInformation: {
      marginBottom: '.5rem',
    },
    name: {
      marginBottom: '1rem',
    },
    bio: {
      marginBottom: '1rem',
    },
  },
};
