import { useCallback, useEffect, useRef } from 'react';

export const useDebounce = (fn, timeInMs = 1000) => {
  const timeoutRef = useRef(null);
  const fnRef = useRef();

  fnRef.current = fn;

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [timeInMs]);

  return useCallback(
    async (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      return new Promise((resolve, reject) => {
        timeoutRef.current = setTimeout(() => {
          timeoutRef.current = null;
          try {
            resolve(fnRef.current(...args));
          } catch (err) {
            reject(err);
          }
        }, timeInMs);
      });
    },
    [timeInMs]
  );
};
