import { doQuery } from '../../common/providers/data-provider';

export const updateBusinessHiddenStatus = async (business, hidden) => {
  const mutation = `
    mutation CustomUpdateBusiness($input: CustomUpdateBusinessInput!) {
      customUpdateBusiness(input: $input)
    }
  `;
  const variables = {
    input: {
      filter: {
        id: business.id,
      },
      set: {
        hidden: hidden,
        featured: business.featured,
        blackOwned: business.blackOwned,
        facilitatesTransactions: business.facilitatesTransactions,
      },
    },
  };
  return await doQuery(mutation, variables);
};
