import { makeStyles } from '@material-ui/core/styles';
import { Box, Link, Typography } from '@material-ui/core';
import { Fieldset } from '../components';

import { components } from '../styles/component';

const useStyles = makeStyles({ ...components.status });

export const Status = ({
  reportsReceivedText,
  numberReportsReceivedText,
  reportsMadeText,
  reportsMadeValidText,
  resource,
}) => {
  const { fieldset, header, violations } = useStyles();

  return (
    <Fieldset className={fieldset}>
      <Box className={header}>
        <Typography variant="h2">Status</Typography>
        <Typography className={violations} variant="body1">
          Violation details for this {resource}:
        </Typography>
      </Box>
      <Typography variant="body1">
        {reportsReceivedText} <Link href="#">Community Guidelines</Link>
        {numberReportsReceivedText}
      </Typography>
      {reportsMadeText && (
        <Typography variant="body1">
          {reportsMadeText}
          {reportsMadeValidText}
        </Typography>
      )}
    </Fieldset>
  );
};
