import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { CloudinaryContext } from 'cloudinary-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthTokenProvider from './auth/providers/auth-token-provider';
import { config } from './common/config';
import './common/styles/global.css';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <AuthTokenProvider>
        <CloudinaryContext cloudName={config.cloudinary.name} uploadPreset={config.cloudinary.uploadPreset}>
          <App />
        </CloudinaryContext>
      </AuthTokenProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
