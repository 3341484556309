import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-final-form';

import timezoneList from '../data/timezones';
import { useMounted } from './mounted';

export const useTimezoneProvider = ({ defaultSelection }) => {
  const form = useForm();
  const mounted = useMounted();
  const [timezones, setTimezones] = useState([]);
  const [timezone, setTimezone] = useState(form.getState().values.timezone);

  // Use local timezone list
  const fetchTimezones = useCallback(async () => {
    if (mounted.current) {
      setTimezones(timezoneList);
      // If the form does not yet have a timezone, default one.
      if (!timezone) {
        form.change('timezone', defaultSelection);
        setTimezone(defaultSelection);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchTimezones, []);

  return { timezones };
};

export const useTimezones = (data) => useTimezoneProvider(data);
