export const reviewBusinessListFields = `
  hidden
  rating
`;

export const reviewBusinessDetailFields = `
  id
  hidden
  title,
  description
  createdAt
  rating
  userReportsReceived {
    id
    createdAt
    reporterComment
  }
  outstandingReports: userReportsReceived(filter: {not: {currentStatus: {eq: RESOLVED}}}) {
    id
    reportGroup {
		  id
	  }
  }
  business {
    id
    name
  }
  author {
		id
		banned
		fullName
		reputation
	}
  media(order: { asc: sortOrder }) {
    id
    url
    publicId
    resourceType
    sortOrder
  }
`;

export const reviewUserListFields = `
  id
  createdAt
  hidden
  business {
    id
    name
  }
  userReportsReceived {
    id
    createdAt
    reporterComment
  }
  outstandingReports: userReportsReceived(filter: {not: {currentStatus: {eq: RESOLVED}}}) {
    id
  }
`;

export const reviewUserDetailFields = `
  id
  createdAt
  description
  hidden
  title
  business {
    id
    name
  }
  author {
		id
		banned
		fullName
		reputation
	}
  media(order: { asc: sortOrder }) {
    id
    url
    publicId
    resourceType
    sortOrder
  }
  userReportsReceived(filter: {reportResolution: {eq: VALID}, or: {currentStatus: {eq: UNREAD}}}) {
    id
    createdAt
    reporterComment
  }
  outstandingReports: userReportsReceived(filter: {not: {currentStatus: {eq: RESOLVED}}}) {
    id
    reportGroup {
		  id
	  }
  }
`;

export const reviewUserReportedContentListFields = `
  createdAt
  description
  rating
  author {
    id
    fullName
    avatarImage {
      url
      publicId
      resourceType
      type
    }
    protectedData {
      id
      email
    }
  }
  business {
    id
    name
  }
  media(order: { asc: sortOrder }) {
    id
    url
    publicId
    resourceType
    sortOrder
  }
`;

export const reviewUserReportedContentDetailFields = `
  id
  createdAt
  title
  description
  rating
  hidden
  author {
    id
    fullName
    banned
    avatarImage {
      url
      publicId
      resourceType
      type
    }
    protectedData {
      email
    }
  }
  business {
    id
    name
  }
  media(order: { asc: sortOrder }) {
    id
    url
    publicId
    resourceType
    sortOrder
  }
`;
