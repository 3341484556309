import React, { useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

/**
 * Resets scroll position on location change (Used for edit view navigation)
 *
 * @param   {React.Children}  children  Such as `<Show />`
 * @param   {Object}          location  The current router location
 *
 * @return  {React.Component}
 */
const ScrollTo = ({ children, location }) => {
  const prevLocation = useRef();

  useEffect(() => {
    // check if the
    if (prevLocation.current !== location.pathname) {
      window.scrollTo(0, 0);
      prevLocation.current = location.pathname;
    }
  }, [location]);

  return children;
};

export const ScrollToTop = withRouter(ScrollTo);
