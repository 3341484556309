import { reporterFields } from './reporter';
import { businessUserReportedContentListFields, businessUserReportedContentDetailFields } from './business';
import { reviewUserReportedContentListFields, reviewUserReportedContentDetailFields } from './review';
import { userUserReportedContentListFields, userUserReportedContentDetailFields } from './user';

const activityFeedReportedPost = `
  id
  author { ${reporterFields} }
  postId
`;

const activityFeedReportedComment = `
  ${activityFeedReportedPost}
  commentId
`;

const list = `
  id
  reporterComment
  createdAt
  priority
  reason {
    label
  }
  currentStatus
  reportedItem {
    __typename
    userReportsReceivedAggregate {
      count
    }
    ... on ActivityFeedReportedPost { ${activityFeedReportedPost} }
    ... on ActivityFeedReportedComment { ${activityFeedReportedComment} }
    ... on Review { ${reviewUserReportedContentListFields} }
    ... on User { ${userUserReportedContentListFields} }
    ... on Business { ${businessUserReportedContentListFields} }
  }
  reporter { ${reporterFields} }
`;

const detail = `
  id
  createdAt
  priority
  reason {
    label
  }
  currentStatus
  reporterComment
  reportResolution
  moderatorComments {
    id
    createdAt
    comment
    admin {
      name
    }
  }
  reportedItem {
    __typename
    userReportsReceivedAggregate {
      count
    }
    ... on Review { ${reviewUserReportedContentDetailFields} }
    ... on User { ${userUserReportedContentDetailFields} }
    ... on Business { ${businessUserReportedContentDetailFields} }
    ... on ActivityFeedReportedPost { ${activityFeedReportedPost} }
    ... on ActivityFeedReportedComment { ${activityFeedReportedComment} }
    reportsAggregateValid: userReportsReceivedAggregate(filter: {reportResolution: {eq: VALID}}) {
      count
    }
    reportsAggregateAll: userReportsReceivedAggregate {
      count
    }
  }
  reporter { ${reporterFields} }
`;

export const userReportedContent = { detail, list };
