export const Stars = ({ rating, count = 5, color = 'var(--PRIMARY_LIGHT)', ...props }) => {
  return (
    <div style={{ height: '0.875em', display: 'flex', gap: '0.25em', color }} {...props}>
      {[...Array(count).keys()].map((num) => (
        <svg key={num} viewBox="0 0 12 11" fill={num < Math.floor(rating) ? 'currentColor' : 'none'}>
          <path
            d="M5.52447 1.08156C5.67415 0.620904 6.32585 0.620902 6.47553 1.08156L7.09607 2.99139C7.29688 3.60943 7.87282 4.02786 8.52265 4.02786H10.5308C11.0151 4.02786 11.2165 4.64767 10.8247 4.93237L9.20006 6.11271C8.67433 6.49468 8.45434 7.17173 8.65516 7.78976L9.2757 9.69959C9.42537 10.1602 8.89813 10.5433 8.50628 10.2586L6.88168 9.07827C6.35595 8.6963 5.64405 8.69631 5.11832 9.07827L3.49372 10.2586C3.10187 10.5433 2.57463 10.1603 2.7243 9.69959L3.34484 7.78976C3.54566 7.17173 3.32567 6.49468 2.79994 6.11271L1.17534 4.93237C0.783482 4.64767 0.984868 4.02786 1.46923 4.02786H3.47735C4.12719 4.02786 4.70312 3.60942 4.90393 2.99139L5.52447 1.08156Z"
            stroke="currentColor"
          />
        </svg>
      ))}
    </div>
  );
};
