import { useRecordContext } from 'react-admin';
import { Box, Typography } from '@material-ui/core';

import { FormatDate } from '../utils';
import { components } from '../styles/component';

const { reviewText } = components.lastActivity;

const ListReview = ({ review }) => {
  const { business, createdAt } = review;
  const businessId = business.id ?? 'FALLBACK_ID';

  return (
    <Box key={businessId}>
      <Typography style={reviewText}>
        Review on <FormatDate date={createdAt} structure="MMM d, yyyy" />
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <FormatDate date={createdAt} structure="h:mma" />
      </Typography>
    </Box>
  );
};

export const LastActivity = () => {
  const record = useRecordContext();

  if (!record) return null;

  const reviews = record?.reviews || [];
  const latestReview = reviews.length > 0 ? reviews[0] : undefined;
  return (
    <>{latestReview ? <ListReview review={latestReview} /> : <Typography variant="body2">No Activity</Typography>}</>
  );
};
