import { doQuery } from '../../common/providers/data-provider';

export const updateUserBannedStatus = async (userId, banStatus) => {
  const updateBannedQuery = `
    mutation AdminUpdateUserBannedStatus {
      updateUserBannedStatus(userId: "${userId}", banned: ${banStatus})
    }
  `;
  return await doQuery(updateBannedQuery, {});
};
