import { Button } from '@mui/material';
import { login } from '../styles';

const { button } = login;

const LoginButton = ({ onClick }) => {
  return (
    <Button style={button} onClick={onClick}>
      Log In
    </Button>
  );
};

export default LoginButton;
