import { FormLabel } from '@mui/material';

import { Div } from './SanitizedElements';
import { AttributeArrayInput } from './AttributeArrayInput';
import { useCategories } from '../hooks/categories';
import { useAttributes } from '../hooks/attributes';

import { components } from '../styles/component';

const { attributeArrayContainer } = components;

const flexVerticalCenterStyle = { display: 'flex', alignItems: 'center', height: '3rem' };

export const CategoryAttributesSelect = ({ validate }) => {
  const { category } = useCategories();
  const { attributes } = useAttributes();

  if (!category) return null;

  return (
    <Div style={attributeArrayContainer}>
      {attributes.length > 0 ? (
        <AttributeArrayInput fullWidth attributes={attributes} validate={validate} />
      ) : (
        <FormLabel style={flexVerticalCenterStyle}>
          There are no attributes for {category?.label || category?.name} category
        </FormLabel>
      )}
    </Div>
  );
};
