import { Chip as MuiChip } from '@material-ui/core';
import { get } from 'lodash';
import theme from '../styles/theme';

export const Chip = ({ basePath, color, ...props }) => {
  const priority = props?.record?.reportPriority || props?.priority;
  const backgroundColor = get(theme, `palette.${color}.main`);
  const priorityProps = {};

  if (priority) {
    const priorityStyles = {};

    function assignColors(severity) {
      priorityStyles.backgroundColor = theme.palette[severity].main;
      priorityStyles.color = theme.palette[severity].contrastText;
    }
    switch (priority) {
      case 4:
        assignColors('error');
        priorityProps.label = 'High*';
        priorityProps.title = 'Unknown reason code. Priority inferred.';
        break;
      case 3:
        assignColors('error');
        priorityProps.label = 'High';
        break;
      case 2:
        assignColors('warning');
        priorityProps.label = 'Medium';
        break;
      case 1:
        assignColors('success');
        priorityProps.label = 'Low';
        break;
      default:
        priorityProps.label = 'Unknown';
        return;
    }

    priorityProps.style = priorityStyles;
  }

  return <MuiChip style={{ backgroundColor, ...props.style }} {...props} {...priorityProps} />;
};
