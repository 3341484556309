const userUpdate = [
  '__typename',
  'banned',
  'businessClaims',
  'businessClaimsAggregate',
  'fullName',
  'reportsAggregateAll',
  'reportsAggregateValid',
  'reviews',
  'userReportsReceivedAggregate',
  'userBusinessRoles',
];

const businessUpdate = [
  'categories[0].label',
  'categories[0].image',
  'categorySelect',
  'createdAt',
  'id',
  '__typename',
  'reportsValid',
  'reportsAggregateAll',
  'reportsAggregateValid',
  'userClaims',
  'reviews',
  'userClaimsAggregate',
  'businessUserRoles',
  'hours',
  'customHours', // we pass hours over to the
  'businessRating',
  'flaggedBusinessAggregate',
  'flaggedReviewsNest',
  'typeformResponseId',
];

// Any fields from a resource we want to omit from updating
export const omitFields = {
  update: {
    user: userUpdate,
    business: businessUpdate,
  },
};
