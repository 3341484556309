import { useCallback, useEffect } from 'react';
import { SelectInput, useRecordContext } from 'react-admin';
import { useForm } from 'react-final-form';
import { useCategories } from '../hooks/categories';
import { useChange } from '../hooks/change';

import { components } from '../styles/component';

export const CategorySelect = () => {
  const form = useForm();
  const record = useRecordContext();
  const { categories, category, setCategory } = useCategories();

  const getSelectedCategory = useCallback(
    (e) => {
      const selectedCategory = categories.find((category) => category.id === e.target.value);
      form.change('attributes', undefined);
      setCategory(selectedCategory);
    },
    [form, categories, setCategory]
  );

  useChange(() => {
    if (record.categories && record.categories.length > 0 && category?.id !== record.categories[0].id) {
      const selectedCategory = categories.find((category) => category.id === record.categories[0].id);
      form.change('categories', selectedCategory);
      setCategory(selectedCategory);
    }
  }, [record.categories]);

  if (categories?.length === 0) return null;

  return (
    <SelectInput
      value={category}
      source="categories[0].id"
      choices={categories}
      onChange={getSelectedCategory}
      style={components.selectInput}
    />
  );
};
