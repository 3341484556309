import { Typography } from '@material-ui/core';
import { FormatDate } from '../utils';

export const ListClosedAt = ({ record }) => {
  if (!record || !record.closedAt) return null;

  return (
    <Typography variant="body2" color="textSecondary" style={{ minWidth: '128px' }}>
      <b>
        <FormatDate date={record.closedAt} />
      </b>
    </Typography>
  );
};
