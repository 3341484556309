import { fetchUtils } from 'react-admin';
import { Promise as BluebirdPromise } from 'bluebird';
import { stringify } from 'query-string';
import _, { startCase, random, pick, omit, includes, find, isUndefined } from 'lodash';
import { config } from '../config';
import { customOmitFilters, selectionSets, updateOmitFields } from './selection-sets';
import { transformTypeformData } from '../utils';

const httpClient = fetchUtils.fetchJson;
let jwt = '';

export function getDataProvider(token) {
  jwt = token;
  return dataProvider;
}

const handleCustomHours = (customHours, hours) => {
  hours = hours || [];

  Object.keys(customHours).map((key) => {
    const dayChanged = customHours[key];
    const start = typeof dayChanged.start === 'string' ? parseInt(dayChanged.start) : null;
    const end = typeof dayChanged.end === 'string' ? parseInt(dayChanged.end) : null;
    const dayNumber = dayChanged.day;

    /**
     * Check if we're doing an update, or inserting hours for a new day.
     */
    const update = hours.find((day) => day.day === dayNumber);
    /**
     * Update hours for a day
     */

    if (update) {
      // Remove a cleared day which is now closed
      if (start === null && end === null) {
        hours.splice(
          hours.findIndex((h) => h === update),
          1
        );
      } else {
        // update to the new start/end times
        update.start = start || 0;
        update.end = end || 2400;
      }
    } else if (start !== null && end !== null) {
      // Add hours to previously closed day
      hours.push({
        start: parseInt(start),
        end: parseInt(end),
        day: dayNumber,
      });
    }
  });

  return hours;
};

const attributeQuery = `
        query QueryBusinessAttributes {
          queryAttribute {
            id
            label
          }
        }
      `;

const categoryQuery = `
        query QueryBusinessCategories {
          queryCategory {
            id
            label
          }
        }
      `;

export async function doQuery(query, variables = {}) {
  const response = await fetch(config.apiUrl, {
    method: 'POST',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...(jwt && { Authorization: `Bearer ${jwt}` }),
    },
    body: JSON.stringify({ query, variables }),
  });
  return response.json();
}

const getOne = async (resource, params) => {
  const upperResource = startCase(resource).replace(/ /g, '');

  const query = `
    query AdminQuery${upperResource}($id: [ID!]) {
      query${upperResource}(filter: { id: $id }) {
        ${selectionSets[resource].detail}
      }
    }
  `;

  const variables = {
    id: params.id || params.ids,
  };

  const result = await doQuery(query, variables);

  if (result.errors) {
    console.error('getOne error: ', result.errors);
    throw new Error('getOne query Error');
  }

  return {
    data: result.data[`query${upperResource}`][0],
  };
};

export const dataProvider = {
  getList: async (resource, params) => {
    const upperResource = startCase(resource).replace(/ /g, '');
    let customSearchCount = 0;

    // Shortcut upperResource for non-standard Typeform query and response
    if (upperResource === 'BusinessApplications') {
      const result = await doQuery('query AdminQueryTypeform { customQueryTypeform }');
      const attributes = await doQuery(attributeQuery, {});
      const categories = await doQuery(categoryQuery, {});

      if (result.errors) {
        console.error('getList error: ', result.errors[0].message);
        throw new Error(result.errors[0].message);
      }

      const { items, total_items } = JSON.parse(result.data.customQueryTypeform);

      return {
        data: items.map((item) =>
          transformTypeformData(item, attributes?.data?.queryAttribute, categories?.data?.queryCategory)
        ),
        total: total_items,
      };
    }

    let pagination = {
      first: params.pagination.perPage,
      offset:
        params.pagination.offset || params.pagination.page > 0
          ? (params.pagination.page - 1) * params.pagination.perPage
          : params.pagination.perPage,
    };

    const customFilters = {};
    const adminQueryNameMap = {
      UserReportedContentGroup: 'Tickets',
      User: 'Users',
      Business: 'Businesses',
    };
    /**
     * Query resource ids from custom search queries frist
     */
    if (
      !params.filter.customSearchUserInputFilter &&
      !params.filter.customSearchInputTicketFilter &&
      !params.filter.customSearchInputBusinessFilter
    ) {
      const customResource = adminQueryNameMap[upperResource];
      const onTicketView = customResource === 'Tickets';
      const searchTicketStatus =
        params.filter.customResolvedTicketsFilter === true || params.filter.customResolvedTicketsFilter === undefined
          ? 'UNRESOLVED'
          : 'RESOLVED';

      delete params.filter.customResolvedTicketsFilter;
      const filter = params.filter ? JSON.stringify(params.filter).replace(/"/g, '') : null;
      const query = `
        query CustomAdminQuery${customResource} {
          customAdminQuery${customResource}(
            order: ${params.sort.order.toUpperCase()}
            sort: ${params.sort.field.toUpperCase()}
            offset: ${pagination.offset},
		        first: ${pagination.first},
            ${params.filter ? `filter: ${filter}` : ''},
            ${onTicketView ? `status: ${searchTicketStatus},` : ''}
            searchTerm: ""
          ) {
            ids
            totalCount
          }
        }
      `;
      const search = await doQuery(query);

      if (search.errors) {
        console.error('search error: ', search.errors[0].message);
        throw new Error(search.errors[0].message);
      }

      customFilters.id = search.data[`customAdminQuery${customResource}`].ids;
      customSearchCount = search.data[`customAdminQuery${customResource}`].totalCount;

      /**
       * The search query paginates for us, we `null` the offset of the list query
       * to respect this. Otherwise for example, querying for 10 ID's would result in
       * the offsetting of 10 ID's, giving no results.
       */
      pagination.offset = null;
    }

    /**
     * Support filtering by Datetime if customCreatedAtFilter is supplied.
     * Currently filters between one day only.
     */
    if (params.filter.customCreatedAtFilter) {
      const max = new Date(params.filter.customCreatedAtFilter);
      max.setDate(max.getDate() + 1);

      customFilters.createdAt = {
        between: {
          min: new Date(params.filter.customCreatedAtFilter).toISOString(),
          max: max.toISOString(),
        },
      };
    }
    /**
     * Support User search functionality
     *
     * @var {Filter}
     */
    if (params.filter.customSearchUserInputFilter) {
      const searchTerm = params.filter.customSearchUserInputFilter;
      delete params.filter.customSearchUserInputFilter;
      const filter = params.filter ? JSON.stringify(params.filter).replace(/"/g, '') : null;
      const query = `
        query customAdminQueryUsers {
          customAdminQueryUsers(
            order: ${params.sort.order.toUpperCase()}
            sort: ${params.sort.field.toUpperCase()}
            offset: ${pagination.offset},
		        first: ${pagination.first},
            ${params.filter ? `filter: ${filter}` : ''},
            searchTerm: "${searchTerm}"
          ) {
            ids
            totalCount
          }
        }
      `;
      const search = await doQuery(query);

      if (search.errors) {
        console.error('search error: ', search.errors[0].message);
        throw new Error(search.errors[0].message);
      }

      customFilters.id = search.data.customAdminQueryUsers.ids;
      customSearchCount = search.data.customAdminQueryUsers.totalCount;

      /**
       * The search query paginates for us, we `null` the offset of the list query
       * to respect this. Otherwise for example, querying for 10 ID's would result in
       * the offsetting of 10 ID's, giving no results.
       */
      pagination.offset = null;
    }

    /**
     * Support Ticket search functionality
     *
     * @var {Filter}
     */
    if (params.filter.customSearchInputTicketFilter) {
      const searchTicketStatus =
        params.filter.customResolvedTicketsFilter === true || params.filter.customResolvedTicketsFilter === undefined
          ? 'UNRESOLVED'
          : 'RESOLVED';
      const searchTerm = params.filter.customSearchInputTicketFilter;
      delete params.filter.customResolvedTicketsFilter;
      delete params.filter.customSearchInputTicketFilter;

      const filter = params.filter ? JSON.stringify(params.filter).replace(/"/g, '') : null;
      const query = `
        query customAdminQueryTickets {
          customAdminQueryTickets(
            order: ${params.sort.order.toUpperCase()}
            sort: ${params.sort.field.toUpperCase()}
            offset: ${pagination.offset},
		        first: ${pagination.first},
            status: ${searchTicketStatus},
            ${params.filter ? `filter: ${filter}` : ''},
            searchTerm: "${searchTerm}"
          ) {
            ids
            totalCount
          }
        }
      `;

      const search = await doQuery(query);

      if (search.errors) {
        console.error('search error: ', search.errors[0].message);
        throw new Error(search.errors[0].message);
      }

      customFilters.id = search.data.customAdminQueryTickets.ids;
      customSearchCount = search.data.customAdminQueryTickets.totalCount;

      /**
       * The search query paginates for us, we `null` the offset of the list query
       * to respect this. Otherwise for example, querying for 10 ID's would result in
       * the offsetting of 10 ID's, giving no results.
       */
      pagination.offset = 0;
    }

    /**
     * Support Business search functionality
     *
     * @var {Filter}
     */
    if (params.filter.customSearchInputBusinessFilter) {
      const searchTerm = params.filter.customSearchInputBusinessFilter;
      delete params.filter.customSearchInputBusinessFilter;

      const filter = params.filter ? JSON.stringify(params.filter).replace(/"/g, '') : null;
      const query = `
        query customAdminQueryBusinesses {
          customAdminQueryBusinesses(
            order: ${params.sort.order.toUpperCase()}
            sort: ${params.sort.field.toUpperCase()}
            offset: ${pagination.offset},
		        first: ${pagination.first},
            ${params.filter ? `filter: ${filter}` : ''},
            searchTerm: "${searchTerm}"
          ) {
            ids
            totalCount
          }
        }
      `;

      const search = await doQuery(query);

      if (search.errors) {
        console.error('search error: ', search.errors[0].message);
        throw new Error(search.errors[0].message);
      }

      customFilters.id = search.data.customAdminQueryBusinesses.ids;
      customSearchCount = search.data.customAdminQueryBusinesses.totalCount;

      /**
       * The search query paginates for us, we `null` the offset of the list query
       * to respect this. Otherwise for example, querying for 10 ID's would result in
       * the offsetting of 10 ID's, giving no results.
       */
      pagination.offset = null;
    }
    const customSort = {};
    /**
     * Support Order sorting
     */
    if (params.sort && params.sort.field !== 'id') {
      customSort.order = `${params.sort.order}`.toLowerCase();
      customSort.field = params.sort.field;
    }

    const variables = {
      first: pagination.first,
      offset: pagination.offset,
      filter: {
        ...omit(params.filter, customOmitFilters),
        ...customFilters,
      },
      order: customSort.order
        ? {
            [customSort.order]: customSort.field,
          }
        : null,
    };

    const query = `
      query AdminQuery${upperResource}($first: Int, $offset: Int, $filter: ${upperResource}Filter, $order: ${upperResource}Order) {
        query${upperResource}(first: $first, offset: $offset, filter: $filter, order: $order) {
          ${selectionSets[resource].list}
        }
        aggregate${upperResource}(filter: $filter) {
          count
        }
      }
    `;

    const result = await doQuery(query, variables);

    if (result.errors) {
      console.error('getList error: ', result.errors[0].message);
      throw new Error(result.errors[0].message);
    }

    return {
      data: result.data[`query${upperResource}`],
      total: customSearchCount,
    };
  },

  getOne,

  getMany: async (resource, params) => {
    const upperResource = startCase(resource).replace(/ /g, '');
    const query = `
      query AdminQuery${upperResource}($id: [ID!]) {
        query${upperResource}(filter: { id: $id }, order: ${params.order}) {
          ${selectionSets[resource].list}
        }
      }
    `;

    const variables = {
      id: params.ids,
    };
    const result = await doQuery(query, variables);

    return {
      data: result.data[`query${upperResource}`],
    };
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },

  update: async (resource, params) => {
    console.log('%c Resource', 'color:white; background:magenta; font-style:italic', resource);
    console.log('%c Params', 'color:white; background:cyan; font-style:italic', params);
    const upperResource = startCase(resource).replace(/ /g, '');

    const query = `
      mutation AdminUpdate${upperResource}($input: CustomUpdate${upperResource}Input!) {
        customUpdate${upperResource}(input: $input)
      }
    `;
    const customData = {};
    /**
     * Support User specific mutations
     */
    // if (resource === 'user') {
    // }

    /**
     * Support business specific mutations
     */
    if (resource === 'business') {
      if (params.data?.location?.coordinates) {
        params.data.location.coordinates.latitude = Number(params.data.location.coordinates.latitude);
        params.data.location.coordinates.longitude = Number(params.data.location.coordinates.longitude);
      }

      if (params.data.userClaims !== undefined) {
        /**
         * Unclaim a user from a business
         * TODO: Might be better to iterate the array on the backend - revisit this
         */
        params.data.userClaims.forEach(async ({ id: claimId, isDeleted = false }) => {
          const query = `
            mutation unClaimBusiness($claimId: String!) {
              unClaimBusiness(claimId: $claimId)
            }
          `;

          if (isDeleted) {
            const result = await doQuery(query, { claimId });
            if (result.errors) {
              console.error('Unclaim Business Error: ', result.errors);
              throw new Error('Unclaim business Error');
            }
          }
        });
      }
      /**
       * Strip `label` from attributes
       *
       * Labels are necessary for initial load.
       */
      if (params.data.attributes) {
        customData.attributes = params.data.attributes.map((attr) => {
          return {
            id: attr.id,
          };
        });
      }

      /**
       * Handle editing of hours
       */

      if (params.data.customHours) {
        customData.hours = handleCustomHours(params.data.customHours, params.data.hours);
      }
    }

    const variables = {
      input: {
        filter: pick(params.data, 'id'),
        set: {
          ...omit(params.data, ['id', 'createdAt', ...(updateOmitFields[resource] || [])]),
          ...customData,
        },
      },
    };
    const result = await doQuery(query, variables);

    if (result.errors) {
      console.error('updateOne error: ', result.errors[0].message);
      throw new Error(result.errors[0].message);
    }

    return getOne(resource, { ids: params.id });
  },

  updateMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  createMany: async (resource, params) => {
    if (resource === 'business') {
      const attributeData = await doQuery(attributeQuery, {});
      const categoryData = await doQuery(categoryQuery, {});

      const importedBusinesses = params?.data?.map((d) => {
        const attributeIds = d?.attributes.map((a) => {
          // NOTE: Some of our dataset breaks our attributes once parsed
          // due to format differences in the dataset for the same attribute.
          // This just checks for the other variation if the first isn't found.
          const checkVariation = find(attributeData?.data?.queryAttribute, { label: a });
          const found = !isUndefined(checkVariation)
            ? checkVariation
            : find(attributeData?.data?.queryAttribute, { label: a.replaceAll('/', ' / ') });
          return { id: found?.id };
        });
        const categoryIds = d?.categories.map((c) => {
          // NOTE: Some of our dataset breaks our categories once parsed
          // due to format differences in the dataset for the same category.
          // This just checks for the other variation if the first isn't found.
          const checkVariation = find(categoryData?.data?.queryCategory, { label: c });
          const found = !isUndefined(checkVariation)
            ? checkVariation
            : find(categoryData?.data?.queryCategory, { label: c.replaceAll('/', ' / ') });
          return { id: found?.id };
        });

        // NOTE: The subtitle is the first in the attribute list
        const subtitleId = attributeIds[0]?.id;
        return {
          ...d,
          subtitleId,
          attributes: attributeIds,
          categories: categoryIds,
        };
      });

      const mutation = `
        mutation AdminAddBusiness($input: CustomAddBusinessInput!) {
          customAddBusiness(business: $input)
        }
      `;

      let numberOfBusinessesLeftToInsert = importedBusinesses.length;

      const data = await BluebirdPromise.map(
        importedBusinesses,
        (business) => {
          async function insert(r, numRetries) {
            if (numRetries <= 0) {
              console.error(`There was a problem saving ${business?.name}`);
              return;
            }

            const variables = { input: r };
            const result = await doQuery(mutation, variables);

            if (!result.errors) {
              numRetries = 0;
              numberOfBusinessesLeftToInsert--;
              console.info(
                `%c🌟 [Number of businesses left to insert ${numberOfBusinessesLeftToInsert}]`,
                'color: white; background: green'
              );
              if (numberOfBusinessesLeftToInsert <= 0) {
                console.warn('%c🚀 All businesses have been inserted!', 'color: white; background: green');
              }
            }

            if (result?.errors) {
              // NOTE: https://discuss.dgraph.io/t/running-concurrent-update-mutations-to-the-same-node-id-causes-rpc-error-and-transaction-to-be-aborted-doesnt-auto-retry/8848
              // TODO: Investigate more thoroughly and figure out a better workaround.
              // There is an rpc error issue with dgraph mutations. It happens
              // sending up each mutation individually and it also
              // happens sending up an array and iterating on the
              // server each mutation
              result.errors.forEach((err) => {
                numRetries--;
                if (err.message.includes('Please retry')) {
                  console.warn(
                    `%c[Insert RPC error occurred. Retrying...] 🌟[${numRetries} attempt(s) left]`,
                    'color: white; background: red',
                    err.message
                  );

                  const delay = parseInt(`${random(1, 5)}000`);

                  // Recursively retry for any RPC errors
                  setTimeout(() => insert(r, numRetries), delay);
                } else {
                  console.error('%cAn Error occurred: ', 'color: white; background: red', err.message, err.path);
                }
              });
            }
            return { data: { id: result?.data?.customAddBusiness } };
          }

          insert(business, 5);
        },
        { concurrency: 10 }
      );

      return {
        data,
      };
    }
  },

  create: async (resource, params) => {
    console.log(`Create(data-provider): ${resource}`);
    console.log('%c Resource', 'color:white; background:magenta; font-style:italic', resource);
    console.log('%c Params', 'color:white; background:cyan; font-style:italic', params);
    const supportedResources = ['business', 'businessApplications'];

    if (includes(supportedResources, resource)) {
      const query = `
        mutation AdminAddBusiness($input: CustomAddBusinessInput!) {
          customAddBusiness(business: $input)
        }
      `;
      const {
        // Handle separate and set to hours field if present
        customHours,
        // businessApplications fields that don't match
        id: typeformResponseId,
        email: _email,
        createdAt: _createdAt,
        fastTrackReferrals: _fastTrackReferrals,
        open24Hours: _open24Hours,

        // correct fields
        ...input
      } = params.data;

      // fields are required, default them so undefined's don't throw
      input.blackOwned = !!input.blackOwned;
      input.open24hours = !!(_open24Hours || input.open24hours);
      input.hidden = !!input.hidden;
      input.featured = !!input.featured;
      input.onlineOnly = !!input.onlineOnly;
      input.typeformResponseId = typeformResponseId;

      const variables = {
        input,
      };

      /**
       * Handle adding of hours
       */

      if (customHours) {
        variables.input.hours = handleCustomHours(customHours);
      }

      const result = await doQuery(query, variables);

      if (result.errors) {
        console.error('Creation error: ', result.errors);
        throw new Error('Create Business Error');
      }
      return {
        data: {
          id: result.data.customAddBusiness,
        },
      };
    }
  },

  delete: async (resource, params) => {
    const upperResource = startCase(resource).replace(/ /g, '');
    console.log(`Delete(data-provider): ${resource}`);
    console.log('%c Resource', 'color:white; background:magenta; font-style:italic', resource);
    console.log('%c Params', 'color:white; background:cyan; font-style:italic', params);
    const supportedResources = ['user'];

    if (includes(supportedResources, resource)) {
      const query = `
        mutation AdminDelete${upperResource} {
          customDelete${upperResource}(${resource}Id: "${params.id}")
        }
      `;

      const result = await doQuery(query, {});

      if (result.errors) {
        console.error('Delete error: ', result.errors[0].message);
        throw new Error(result.errors[0].message);
      }
      return {
        data: {
          id: result.data.customAddBusiness,
        },
      };
    }
  },

  deleteMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }));
  },
};
