const custom = [
  'customCreatedAtFilter',
  'customSearchUserInputFilter',
  'customSearchInputTicketFilter',
  'customSearchInputBusinessFilter',
  'customResolvedTicketsFilter',
  'owned',
  'featured',
  'claimed',
  'active',
  'valid',
  'blessed',
];

export const omitFilters = custom;
