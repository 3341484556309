import { useState } from 'react';
import { Image, Video } from 'cloudinary-react';
import { Avatar, Typography } from '@material-ui/core';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Skeleton } from '@mui/material';
import { Comment } from './index';
import { FormatDate } from '../utils';

export const Post = ({ data, activityData, reportedCommentId, ...props }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const { author } = data?.reportedItem;

  const postObjectData = activityData?.detail?.object?.data;
  const createdAt = activityData?.detail?.time;
  const comments = activityData?.detail?.own_reactions?.comment || [];
  const postMedia = postObjectData?.media || [];
  const postText = postObjectData?.text;
  const commentReported = reportedCommentId ? { opacity: '0.6' } : {};

  return (
    <div className="Post" style={commentReported} {...props}>
      <div className="Post-author">
        <Avatar
          className="Post-avatar"
          alt={author?.fullName}
          src={author?.avatarImage?.url}
          style={{ width: '2em', height: '2em', fontSize: '1em', marginRight: '0.5em' }}
        />

        <div>
          <Typography style={{ fontSize: '0.75em', lineHeight: '1', fontWeight: 600 }}>{author?.fullName}</Typography>
          <Typography style={{ fontSize: '0.625em' }}>
            {activityData.loaded && createdAt ? (
              <FormatDate date={createdAt} />
            ) : (
              <Skeleton variant="text" width="60%" />
            )}
          </Typography>
        </div>
      </div>

      {postMedia.length > 0 && (
        <div className="Post-media" style={commentReported}>
          <button
            className="Post-media-control"
            disabled={activeSlide === 0}
            onClick={() => setActiveSlide(activeSlide === 0 ? activeSlide : activeSlide - 1)}
          >
            <ArrowBackIosNewIcon />
          </button>

          <div className="Post-media--container">
            {activityData.loaded ? (
              postMedia.map(({ resourceType, publicId }, i) => {
                const settings = {
                  dpr: 2,
                  publicId,
                  crop: 'fill',
                  key: publicId,
                  style: {
                    zIndex: activeSlide === i ? 1 : 0,
                  },
                };

                return {
                  IMAGE: <Image {...settings} />,
                  VIDEO: <Video {...settings} autoPlay muted loop />,
                }[resourceType];
              })
            ) : (
              <Skeleton variant="rectangular" height={180} />
            )}
          </div>

          <button
            className="Post-media-control"
            disabled={activeSlide === postMedia.length - 1}
            onClick={() => setActiveSlide(activeSlide === postMedia.length - 1 ? activeSlide : activeSlide + 1)}
          >
            <ArrowForwardIosIcon />
          </button>

          <div className="Post-media-pagination">{`${activeSlide + 1} / ${postMedia.length}`}</div>
        </div>
      )}
      <div style={{ marginBottom: '1em', ...commentReported }}>
        {activityData.loaded ? (
          <Typography style={{ fontSize: '0.875em' }}>{postText}</Typography>
        ) : (
          <>
            <Skeleton variant="text" height="0.9375em" />
            <Skeleton variant="text" width="80%" height="0.9375em" />
            <Skeleton variant="text" width="65%" height="0.9375em" />
          </>
        )}
      </div>
      {/* Comments */}
      {comments && activityData.loaded ? (
        comments.map((comment, key) => {
          return (
            <>
              <Comment key={key} comment={comment} reportedCommentId={reportedCommentId} />
            </>
          );
        })
      ) : (
        <Typography style={{ textAlign: 'center', opacity: '0.6', fontSize: '0.785em' }}>No comments</Typography>
      )}
    </div>
  );
};
