import { useCallback, useMemo, useState } from 'react';
import { List, Datagrid, SearchInput, TopToolbar, FilterButton, BooleanInput } from 'react-admin';
import {
  Chip,
  CustomListActions,
  CustomListActionsTrigger,
  EmptyList,
  Pagination,
  ListCreatedAt,
  ListClosedAt,
  ScrollToTop,
} from '../../common/components';
import { TicketListDetails, TicketListReporter, TicketListReason } from '../components';
import { list } from '../styles';

const { topToolBar, searchInput, empty } = list.view;

const LISTACTIONSFILTER = 'customResolvedTicketsFilter';

const Actions = () => (
  <TopToolbar style={topToolBar}>
    <FilterButton />
  </TopToolbar>
);

const defaultSort = { field: 'reportPriority', order: 'desc' };

const TicketList = (props) => {
  const [filterContext, setfilterContext] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const searchTicket = useCallback((e) => setSearchQuery(e.target?.value), []);

  const disableFilterUnderFlagged = filterContext?.filterValues['customResolvedTicketsFilter'] === false;

  const listFilters = !disableFilterUnderFlagged
    ? [
        <SearchInput
          key={1}
          style={searchInput}
          source="customSearchInputTicketFilter"
          alwaysOn
          placeholder="Search Business/Reporter name or group ID"
          className="searchField"
          onChange={searchTicket}
        />,
      ]
    : [
        <SearchInput
          key={1}
          style={searchInput}
          source="customSearchInputTicketFilter"
          alwaysOn
          placeholder="Search Business/Reporter name or group ID"
          className="searchField"
          onChange={searchTicket}
        />,
        <BooleanInput key={2} label="Valid" source="valid" defaultValue={false} />,
      ];

  const pagination = useMemo(() => <Pagination limit={<EmptyList searchQuery={searchQuery} />} />, [searchQuery]);

  return (
    <ScrollToTop>
      <CustomListActions
        title="Tickets"
        btnPrimary="New tickets"
        btnSecondary="Resolved"
        filter={LISTACTIONSFILTER}
        listContext={filterContext}
      />
      <List
        {...props}
        actions={<Actions />}
        bulkActionButtons={false}
        filters={listFilters}
        style={empty}
        pagination={pagination}
        perPage={100}
        sort={defaultSort}
      >
        <>
          <CustomListActionsTrigger setfilterContext={setfilterContext} />
          <Datagrid rowClick="show">
            <TicketListDetails label="Reported Item" sortable={false} />
            <TicketListReporter source="Reported By" sortable={false} />
            <TicketListReason source="Reason" sortable={false} />
            <ListCreatedAt label="Created" source="createdAt" />
            <ListClosedAt label="Closed" source="closedAt" />
            <Chip label="Priority" size="small" source="reportPriority" />
          </Datagrid>
        </>
      </List>
    </ScrollToTop>
  );
};

export default TicketList;
