import { UserMenu, AppBar, Layout, Sidebar, MenuItemLink, getResources, Menu } from 'react-admin';
import { useSelector } from 'react-redux';
import { Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DefaultIcon from '@mui/icons-material/ViewList';

import LogoutButton from '../../auth/components/LogoutButton';
import { useAuth0Wrapper } from '../../auth/hooks/auth0';

import { components } from '../styles/component';

const { avatar } = components.layout;

const { customSidebar } = components.layout;
const useStyles = makeStyles({ ...customSidebar });

const UserIcon = ({ picture }) => {
  return <Avatar alt="User Avatar" src={picture} style={{ ...avatar }} />;
};

const CustomUserMenu = (props) => {
  const { user } = useAuth0Wrapper();

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '-.75rem' }}>
      <Typography variant="body2">{user.name}</Typography>

      <UserMenu logout={null} label="User controls" icon={<UserIcon picture={user?.picture} />}>
        <LogoutButton />
      </UserMenu>
    </div>
  );
};

/**
 * Necessary for introducing custom sidebar & logo to Menu
 *
 * Reference: https://marmelab.com/react-admin/Theming.html#sidebar-customization
 */
export const CustomMenu = (props) => {
  const resources = useSelector(getResources);
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  return (
    <Menu {...props}>
      {resources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={(resource.options && resource.options.label) || resource.name}
          leftIcon={
            resource.icon ? (
              <resource.icon style={{ fontSize: '1.5em', color: 'var(--PRIMARY_MAIN)' }} />
            ) : (
              <DefaultIcon />
            )
          }
          onClick={props.onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
    </Menu>
  );
};

const CustomSidebar = (props) => {
  const { customSidebar } = useStyles();

  return <Sidebar classes={customSidebar} {...props} />;
};

/**
 * Necessary for introducing custom Menu Items
 *
 * Reference: https://marmelab.com/react-admin/Theming.html#using-a-custom-menu
 */
const CustomAppBar = (props) => <AppBar {...props} userMenu={<CustomUserMenu />} />;
const CustomLayout = (props) => <Layout {...props} appBar={CustomAppBar} sidebar={CustomSidebar} menu={CustomMenu} />;

export default CustomLayout;
