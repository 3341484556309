import { useCallback, useEffect, useState, useRef } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { emptyValue } from '../../common/utils';
import { debounce } from 'lodash';
import MapBox from '../../common/services/mapbox';

export const useCoordinateCalculation = () => {
  const form = useForm();
  const { values } = useFormState();
  const [geocodeRun, setGeocodeRan] = useState(false);

  let geocodeQuery = useRef(
    values.location === undefined
      ? undefined
      : `${values.location?.address1} ${values.location?.city} ${values.location?.state}`
  );
  const invalidCoordinateQuery =
    emptyValue(values.location?.address1) || emptyValue(values.location?.city) || emptyValue(values.location?.state);

  const emptyCoordinateQuery =
    emptyValue(values.location?.address1) && emptyValue(values.location?.city) && emptyValue(values.location?.state);

  const emptyCoordinates =
    emptyValue(values.location?.coordinates) ||
    (emptyValue(values.location?.coordinates?.latitude) && emptyValue(values.location?.coordinates?.longitude));

  const previousGeocodeQuery = useRef({
    query: geocodeQuery.current,
    result: !emptyCoordinates
      ? {
          lat: values.location.coordinates.latitude,
          long: values.location.coordinates.longitude,
        }
      : undefined,
    run: false,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCalculateCoords = useCallback(
    debounce(
      (override = false) => {
        if (geocodeQuery.current !== previousGeocodeQuery.current?.query || override) {
          (async () => {
            const res = await MapBox.geocode(geocodeQuery.current);
            const coordinates = {
              lat: res.features[0].center[1],
              long: res.features[0].center[0],
            };
            form.change('location.coordinates.latitude', coordinates.lat);
            form.change('location.coordinates.longitude', coordinates.long);
            setGeocodeRan(true);
            previousGeocodeQuery.current = {
              query: geocodeQuery.current,
              result: coordinates,
              run: true,
            };
          })();
        }
      },
      1600,
      { leading: false, trailing: true }
    ),
    [debounce]
  );

  useEffect(() => {
    geocodeQuery.current = `${values.location?.address1} ${values.location?.city} ${values.location?.state}`;
    if (
      !values.flexibleLocation &&
      geocodeQuery.current !== undefined &&
      geocodeQuery.current !== previousGeocodeQuery.current.query &&
      !invalidCoordinateQuery
    ) {
      handleCalculateCoords();
    } else if (
      !values.flexibleLocation &&
      geocodeQuery.current !== undefined &&
      geocodeQuery.current === previousGeocodeQuery.current.query &&
      previousGeocodeQuery.current?.result === undefined &&
      !invalidCoordinateQuery &&
      emptyCoordinates
    ) {
      /**
       * We've toggled flexible location to off, but have never set coordinates.
       */
      handleCalculateCoords(true);
    } else {
      if (!values.flexibleLocation && !invalidCoordinateQuery && previousGeocodeQuery.current.result) {
        if (previousGeocodeQuery.current?.run) {
          /**
           * If we've previously calculated, we re-used that result
           */
          setGeocodeRan(true);
        }

        form.change('location.coordinates.latitude', previousGeocodeQuery.current.result.lat);
        form.change('location.coordinates.longitude', previousGeocodeQuery.current.result.long);
      }
      if (emptyCoordinateQuery) {
        /**
         * remove coordinates if no address 1, city & state supplied
         */
        form.change('location.coordinates', null);
        setGeocodeRan(false);
      }
      if (invalidCoordinateQuery && !emptyCoordinates) {
        /**
         * Clear coordinates if address1, city, and state are not all filled.
         */
        form.change('location.coordinates', null);
        setGeocodeRan(false);
      }
      if (emptyCoordinates) {
        /**
         * Remove label if coordinates empty
         */
        setGeocodeRan(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.location, values.flexibleLocation, invalidCoordinateQuery]);

  return { geocodeRun };
};
