function transformTypeformData({ answers, hidden, response_id, submitted_at }, attributes = [], categories = []) {
  // Filter out placeholder 'xxxxx' referralIds from Typeform share URL
  const b = hidden?.b !== 'xxxxx' ? hidden.b : null;
  const x = hidden?.x !== 'xxxxx' ? hidden.x : null;
  const referralId = b || x || null;
  const fastTrackReferrals = !!b;

  const result = answers.reduce((acc, answer) => {
    const { field } = answer;
    const { ref } = field;

    if (ref === 'fixedLocation') acc.flexibleLocation = answer.boolean;
    if (ref === 'description') acc.description = answer.text;
    if (ref === 'email') acc.email = answer.email;
    if (ref === 'facilitatesTransactions') acc.facilitatesTransactions = answer.boolean;
    if (ref === 'locationAddress') acc.location = { ...acc.location, address1: answer.text };
    if (ref === 'locationCity') acc.location = { ...acc.location, city: answer.text };
    if (ref === 'locationState') acc.location = { ...acc.location, state: answer.text };
    if (ref === 'locationZip') acc.location = { ...acc.location, zip: `${answer.number}` };
    if (ref === 'name') acc.name = answer.text;
    if (ref === 'onlineOnly') acc.onlineOnly = answer.boolean;
    if (ref === 'open24Hours') acc.open24Hours = answer.boolean;
    if (ref === 'phoneNumber') acc.phoneNumber = answer.phone_number;
    if (ref === 'websiteUrl') acc.websiteUrl = answer.url;

    if (ref === 'blackOwned') {
      // In Typeform blackOwned is presented as a multiple choice question
      // i.e. "My business is NOT at least 51% owned and controlled..."
      acc.blackOwned = !answer.choice.label.toLowerCase().includes('not');
    }

    if (ref === 'category') {
      const choiceIndex = categories.findIndex(({ label }) => label === answer.choice.label);

      acc.categories = choiceIndex >= 0 ? [categories[choiceIndex]] : [];
    }

    if (ref.includes('attributes')) {
      const choices = answer?.choices?.labels || [];
      const newAttributes = attributes.filter((attribute) => choices?.includes(attribute.label));

      acc.attributes = acc.attributes ? [...acc.attributes, ...newAttributes] : newAttributes;
    }

    // Handle subtitleFashion, subtitleRestaurantCuisine, etc.
    if (ref.includes('subtitle')) {
      acc.subtitle = answer?.text || '';
    }

    return acc;
  }, {});

  return { ...result, referralId, id: response_id, createdAt: submitted_at, fastTrackReferrals };
}

export { transformTypeformData };
