import { useCallback } from 'react';
import { useNotify } from 'react-admin';

/**
 * Wrapper for react-admin's notify function, allows for us to have global settings on notification alerts.
 *
 * Optionally takes a response object on mutation calls, checks if errors exist in response and if so
 * throws a long duration error type notify.
 *
 * Example:
 *
 * ```
 * import { useCustomNotify } from './util';
 * [...]
 *  const notify = useCustomNotify();
 *
 *  if(res.errors) {
 *   notify({
 *     message: 'Unable to query attributes',
 *     res: res, // Will take res.errors & process error output
 *   });
 *  }
 * [...]
 * ```
 *
 * @param   {notify}           notify    Passed in through useCustomNotify, result of returned useNotify.
 * @param   {string}           message   Notify message
 * @param   {string}           type      Takes either info, warning, or error
 * @param   {object}           res       Response object from a query.
 * @param   {settingsOveride}  settings  Override the [notify options](https://marmelab.com/react-admin/Actions.html#usenotify)
 *
 * @return  {void}
 */
const customNotify = ({ notify, message, type, res, settings: settingsOveride = undefined }) => {
  /**
   * If we have an error in the response object, then treat as fatal error
   * and increase autoHideDuration for screenshotting purposes.
   */
  if (res && res.errors !== undefined && res?.errors.length > 0) {
    const errors = res.errors;
    const firstError = `\n\nError: ${errors[0].message}`;
    const messageError = message ? `${message} ${firstError}` : `An error occured. ${firstError}`;
    /**
     * Handles if errors.length > 1, console logs whole errors object:
     */
    console.error(`${messageError} ${errors.length > 1 ? '\nMore then one error:' : ''}\n`, errors);
    notify(messageError, {
      type: 'error',
      multiLine: true,
      autoHideDuration: 40000,
    });
  } else {
    /**
     * Otherwise treat as normal notify.
     */
    let settings = {
      type: 'info',
      multiLine: false,
    };

    switch (type) {
      case 'info':
        settings.type = 'info';
        break;
      case 'warning':
        settings.type = 'warning';
        settings.autoHideDuration = 10000;
        break;
      case 'error':
        settings.type = 'error';
        settings.multiLine = true;
        settings.autoHideDuration = 20000;
        break;
    }

    notify(message, {
      ...settings,
      ...settingsOveride,
    });
  }
};

/**
 * Wrapper state used to populate customNotify.
 *
 * Example:
 *
 * ```
 * import { useCustomNotify } from './util';
 * [...]
 *  const notify = useCustomNotify()
 *  notify({
 *    message: 'Hello world!',
 *  });
 * ```
 *
 * @return  {function}       Wrapped function of customNotify.
 */
export const useCustomNotify = () => {
  const notify = useNotify();

  return useCallback((options) => {
    if (typeof options === 'string') {
      notify(options);
    } else {
      customNotify({
        notify,
        ...options,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
