export const confirmBan = {
  banButtonContainer: {
    width: '15%',
  },
  confirmDialog: {
    margin: 'auto',
  },
  confirmDialogUserContentText: {
    marginBottom: '0.5rem',
  },
  confirmDialogSubText: {
    textAlign: 'center',
  },
  label: {
    display: 'inline-block',
    marginTop: '1rem',
  },
  yesButton: {
    backgroundColor: '#f12b2c',
    color: 'white',
  },
  banButton: {
    backgroundColor: '#f12b2c',
    color: 'white',
    marginTop: '0.25rem',
    marginBottom: '0.5rem',
  },
};
