import { useRecordContext } from 'react-admin';
import { Box } from '@mui/material';

import { list } from '../styles';

const { container, tag } = list.image;

export const BusinessListImage = () => {
  const record = useRecordContext();
  if (!record) return null;
  const { backgroundImage = null } = record;
  const hasImage = backgroundImage && backgroundImage.url;
  /**
   * Magician math: 78px is a magic number that transulates height nicely to
   *                40px.
   */
  return record ? (
    <Box
      sx={{
        ...container,
        background: hasImage ? '' : 'RGBA(222, 222, 222, 0.5)',
      }}
    >
      {backgroundImage && backgroundImage.url ? <img src={backgroundImage.url} style={{ ...tag }} /> : null}
    </Box>
  ) : null;
};
