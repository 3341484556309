import { Grid, Typography, FormLabel } from '@mui/material';
import { TextInput } from 'react-admin';
import { Fieldset, CustomInput } from '../../common/components';

import { useCoordinateCalculation } from '../hooks/business-location';

export const BusinessLocation = (props) => {
  const { validate, helperText, characterLimit } = props;
  const { geocodeRun } = useCoordinateCalculation();

  return (
    <Fieldset>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Location: </Typography>
          <FormLabel>(not required if Online Only)</FormLabel>
        </Grid>

        <Grid item xs={3}>
          <CustomInput
            formLabel="Street Address"
            tooltipText={helperText.location.address1}
            characterLimit={characterLimit.location.address1}
            source="location.address1"
            validate={validate.location.address1}
          />
        </Grid>

        <Grid item xs={3}>
          <CustomInput
            formLabel="City"
            tooltipText={helperText.location.city}
            characterLimit={characterLimit.location.city}
            source="location.city"
            validate={validate.location.city}
          />
        </Grid>

        <Grid item xs={3}>
          <FormLabel>State</FormLabel>
          <TextInput validate={validate.location.state} source="location.state" variant="outlined" />
        </Grid>

        <Grid item xs={3}>
          <FormLabel>Zip code</FormLabel>
          <TextInput validate={validate.location.zip} source="location.zip" variant="outlined" />
        </Grid>

        <Grid item xs={12} mt={4}>
          <Typography variant="h6">Coordinates</Typography>
          <FormLabel>Coordinates calculated from Location</FormLabel>
        </Grid>

        <Grid item xs={3}>
          <FormLabel>Latitude</FormLabel>
          <TextInput
            source="location.coordinates.latitude"
            disabled={true} // make read-only
            helperText={geocodeRun ? 'Calculated by address' : null}
            validate={validate.location.lat}
          />
        </Grid>

        <Grid item xs={3}>
          <FormLabel>Longitude</FormLabel>
          <TextInput
            source="location.coordinates.longitude"
            disabled={true} // make read-only
            helperText={geocodeRun ? 'Calculated by address' : null}
            validate={validate.location.long}
          />
        </Grid>
      </Grid>
    </Fieldset>
  );
};
