import { SimpleShowLayout } from 'react-admin';
import { Box, List, Typography, Paper, makeStyles } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';

import { NonInput } from '../../common/utils';
import { ReviewItem } from '../../ticket/components';
import { detail } from '../styles';

const { header, headerRatings } = detail.review;
const useStyles = makeStyles({ ...detail.review });

export const BusinessReviews = ({ data }) => {
  const { container, ratings } = useStyles();
  const ratingAverage = data?.businessRating === null ? 0 : data.businessRating;

  return (
    <Paper className={container}>
      <SimpleShowLayout>
        <Box sx={{ ...header }}>
          <Box sx={{ ...headerRatings }}>
            <Typography variant="h2">
              Current rating: <span>{ratingAverage}</span>
            </Typography>
            <Rating className={ratings} name="Business rating" value={ratingAverage} readOnly />
          </Box>
          <Typography variant="caption">Most recent reviews for the business - view and remove if necessary</Typography>
        </Box>

        <NonInput>
          <List>
            {data.reviews.map((review) => (
              <ReviewItem key={review.id} record={review} resource="business" />
            ))}
          </List>
        </NonInput>
      </SimpleShowLayout>
    </Paper>
  );
};
