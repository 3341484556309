import React, { memo, useEffect, useMemo } from 'react';
import { BooleanInput } from 'react-admin';
import { useForm } from 'react-final-form';

import { Grid, FormLabel } from '@mui/material';

import DateFnsUtils from '@date-io/date-fns';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Hr, TimezoneSelect } from '../../common/components';
import DayHours from '../../common/components/DayHours';

import { useChange } from '../../common/hooks/change';

import { detail } from '../styles';
const { gridStyles } = detail;

const days = [
  {
    day: 0,
    label: 'Sun',
  },
  {
    day: 1,
    label: 'Mon',
  },
  {
    day: 2,
    label: 'Tue',
  },
  {
    day: 3,
    label: 'Wed',
  },
  {
    day: 4,
    label: 'Thu',
  },
  {
    day: 5,
    label: 'Fri',
  },
  {
    day: 6,
    label: 'Sat',
  },
];

const BusinessHoursGrid = memo(({ hours, validate, open24Hours, form }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <br />
      <br />
      <Grid container alignItems="center" rowSpacing={2} style={gridStyles}>
        {days.map((day) => {
          return (
            <DayHours
              key={day.day}
              day={day}
              hours={hours.find((dh) => dh.day === day.day)}
              open24Hours={open24Hours}
              validate={validate}
              form={form}
            />
          );
        })}
      </Grid>
    </MuiPickersUtilsProvider>
  );
});
BusinessHoursGrid.displayName = 'BusinessHoursGrid';

/**
 * Renders the hours matrix for `<BusinessForm />`, from a pre-defined week list
 * of Sun - Sat with [`<DayHours />`](./DayHours.js)
 *
 * **Interally runs:**
 * ```js
 * {days.map((day, i) => {
 *  <DayHours
        key={i}
        day={day}
        hours={record.hours}
        open24Hours={record.open24hours}
        validate={validate}
        form={form}
        field={field}
      />
    );
 * ```
 *
 * @param   {Object}    record    `formData` info containing business query info.
 *                                 Passes through `hours`, `open24Hours`, and `customHoursValidationError`
 *                                 for [`<DayHours />`](./DayHours.js).
 * @param   {String}    field      Passed in field for [`<DayHours />`](./DayHours.js). Default "hours"
 * @param   {Object}    validate   Passed in valdiation states for [`<DayHours />`](./DayHours.js).
 *
 * @return  {React.Component}     [return description]
 */
export const BusinessHours = memo(({ record, defaultData, validate }) => {
  const form = useForm();
  const validateHours = useMemo(
    () => ({ incrementErrors: validate.incrementErrors, decrementErrors: validate.decrementErrors }),
    // eslint-disable-next-line
    []
  );

  const hours = record.hours || [];
  const open24Hours = !!record.open24hours;

  useChange(() => {
    if (open24Hours) {
      validate.clearErrors();
    }
    // eslint-disable-next-line
  }, [open24Hours]);

  return (
    <Grid container spacing={0} justifyContent="space-between">
      <Hr />
      <Grid item xs={3}>
        <FormLabel>If NOT Online Only, adjust this businesses open hours.</FormLabel>
        <BooleanInput label="Open 24 Hours" source="open24hours" />
      </Grid>
      <br />
      <Grid item xs={12}>
        {!!hours && <BusinessHoursGrid hours={hours} open24Hours={open24Hours} validate={validateHours} form={form} />}
        <br />
        <Grid item xs={6} style={gridStyles}>
          <FormLabel>Timezone</FormLabel>
          <TimezoneSelect disabled={form.getState().values.open24hours} defaultSelection={defaultData.timezone} />
        </Grid>
      </Grid>
    </Grid>
  );
});
BusinessHours.displayName = 'BusinessHours';
