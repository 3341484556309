import { useMemo } from 'react';
import {
  Avatar,
  Box,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete, MoreVert } from '@mui/icons-material';

import { MediaThumbnail } from '../../common/components';
import { reviewItem } from '../styles';
import { emptyValue, FormatDate, useCustomNotify } from '../../common/utils';
import { useReview } from '../hooks/review';
import { TicketMenuItem } from './TicketMenuItem';

const { list, chip } = reviewItem;
const useStyles = makeStyles(reviewItem);

export const ReviewItem = ({ record, resource = 'user' }) => {
  const { avatar, avatarImage, createdAt, description, id, media, title, userReportsReceived, outstandingReports } =
    record;
  const isFlagged = userReportsReceived && userReportsReceived.length > 0;

  const notify = useCustomNotify();
  const { deleteIcon, actionMenu, mediaContainer, mediaThumbnail, typeLine } = useStyles();

  const { reviewHidden, toggleHideReview, handleClose, handleClick, anchorEl } = useReview(record, notify);

  const isBusiness = resource === 'business';

  const ReviewByFor = ({ data }) => {
    const author = data.author;
    const business = data.business;

    return (
      <Typography component="span" variant="body2">
        <br />
        {isBusiness ? (
          <>
            {'By '}
            <a href={`#/user/${author.id}`} target="_blank" rel="noreferrer">
              {author.fullName}
            </a>
          </>
        ) : (
          <>
            Review {'for '}
            <a href={`#/business/${business.id}`} target="_blank" rel="noreferrer">
              {business.name}
            </a>
          </>
        )}
        {', '}
        <Typography component="span" variant="body2" color="textSecondary">
          <FormatDate date={createdAt} />
        </Typography>
      </Typography>
    );
  };

  const opacity = reviewHidden ? '0.5' : '1';

  const listItemTextStyle = useMemo(() => ({ opacity }), [opacity]);

  /**
   * Hide if only a star review
   */
  if (emptyValue(description) && emptyValue(title) && media.length === 0) {
    return null;
  }

  return (
    <ListItem key={id} alignItems="flex-start" sx={list.item.container}>
      {/* ListItemAvatar expects a child, so we'll conditionally render it */}
      {avatar && (
        <ListItemAvatar>
          <Avatar alt={`Avatar image for ${id}`} src={avatarImage?.url} />
        </ListItemAvatar>
      )}

      <ListItemText
        style={listItemTextStyle}
        primary={
          <>
            {title}
            <ReviewByFor data={record} />
          </>
        }
        secondary={
          <>
            <Typography component="span" variant="body2" color="textSecondary" className={typeLine}>
              {description}
            </Typography>

            {media && media.length > 0 && (
              <Box component="span" className={mediaContainer}>
                {media.map((_media) => (
                  <MediaThumbnail key={_media.id} media={_media} autoPlay={false} className={mediaThumbnail} />
                ))}
              </Box>
            )}
          </>
        }
      />
      <Box style={chip.container}>
        <Box style={chip.internalContainer}>
          {isFlagged && (
            <Chip
              color="secondary"
              label="FLAGGED"
              size="small"
              /**
               * @TODO Figure out consistent color and space theming
               * @Ticket https://gohypergiant.atlassian.net/browse/JUPTR-1408
               */
              style={chip.component}
            />
          )}

          <MoreVert color="action" onClick={handleClick} mb={1} />

          <Menu
            id="ReviewItem_more-actions"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <TicketMenuItem outstandingReports={outstandingReports} />
            <MenuItem onClick={toggleHideReview} className={actionMenu}>
              <Typography variant="inherit">{reviewHidden ? 'UnHide' : 'Hide'} Review</Typography>
              <ListItemIcon>
                <Delete fontSize="small" className={deleteIcon} />
              </ListItemIcon>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </ListItem>
  );
};
