import { business } from './business';
import { user } from './user';
import { userReportedContent } from './user-reported-content';
import { userReportedContentGroup } from './user-reported-content-group';
import { omitFields } from './omit-fields';
import { omitFilters } from './omit-filters';

export const selectionSets = { business, user, userReportedContent, userReportedContentGroup };
export const updateOmitFields = { ...omitFields.update };
export const customOmitFilters = omitFilters;
