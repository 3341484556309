import { useEffect, useMemo, useState, useCallback } from 'react';
import { isUndefined } from 'lodash';

import { queryActivityDetail, queryActivityComment } from '../queries/activity-detail';

export const useActivityDetail = ({ activityId, commentId, TYPE, notify, reportedItemStatus }) => {
  const [loaded, setLoaded] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [activityDetail, setActivityDetail] = useState(null);
  const [businessUser, setBusinessUser] = useState(null);

  const isBusinessUser = useCallback(
    (detail) => {
      if (TYPE === 'POST' && detail?.businessUser) {
        setBusinessUser(detail?.businessId);
        return true;
      }

      if (TYPE === 'COMMENT' && detail?.data?.businessUser) {
        setBusinessUser(detail?.data?.businessId);
        return true;
      }
      return false;
    },
    [TYPE]
  );

  const getActivityDetail = useCallback(
    async ({ activityId: id }) => {
      const response = commentId ? await queryActivityComment(activityId, commentId) : await queryActivityDetail(id);
      if (!isUndefined(response?.errors)) {
        return notify({
          message: `Unable to retrieve ${TYPE} details`,
          res: response,
        });
      }

      const result = await JSON.parse(
        commentId ? response.data.customQueryActivityComment : response.data.customQueryActivityDetail
      );

      if (result.results.length === 0) {
        setActivityDetail([]);
        setLoaded(true);
        setDeleted(true); // for checking which activity is deleted.
        return reportedItemStatus.setDeleted(true); // globally inform an activity is deleted.
      }
      setActivityDetail(result.results[0]);
      setLoaded(true);
      isBusinessUser(result.results[0]);
    },
    [TYPE, activityId, commentId, isBusinessUser, notify, reportedItemStatus]
  );

  /**
   * Query only once for the activity details
   */
  useEffect(() => {
    if (typeof activityId === 'string' && activityDetail === null) {
      getActivityDetail({ activityId });
    }
  }, [activityDetail, activityId, getActivityDetail]);

  return useMemo(
    () => ({ loaded, deleted, businessUser, detail: activityDetail }),
    [loaded, deleted, businessUser, activityDetail]
  );
};
