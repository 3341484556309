import { useState } from 'react';
import { Image, Video } from 'cloudinary-react';
import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Modal, Link } from '@material-ui/core';

import { components } from '../styles/component';

const {
  modalContainer,
  modalSettingsStyle,
  miniSettingsStyle,
  highlight,
  highlightContainer,
  highlightWrapper,
  playCircle,
  container,
} = components.mediaThumbnail;

const MediaModal = ({ open = false, handleClose = () => {}, children }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ ...modalContainer }}
    >
      <Box sx={{ ...highlightContainer }}>
        <Box sx={{ ...highlightWrapper }}>{children}</Box>
        <HighlightOffIcon onClick={handleClose} style={{ ...highlight }} />
      </Box>
    </Modal>
  );
};

export const MediaThumbnail = ({ media, autoPlay, controls, ...props }) => {
  const { publicId, resourceType } = media;
  const [open, setOpen] = useState(false);

  const settings = {
    modal: {
      dpr: 2,
      publicId,
      crop: 'fill',
      style: { ...modalSettingsStyle },
    },
    mini: {
      dpr: 2,
      publicId,
      crop: 'fill',
      style: { ...miniSettingsStyle },
    },
  };

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * There can be performance implications if many thumbnails are rendered on-screen using the autoPlay option.
   * If that option is disabled, ensure we indicate to the admin that this is a video with an icon.
   */
  return (
    <Box {...props} sx={container}>
      {resourceType === 'VIDEO' && !autoPlay && <PlayCircleFilled style={playCircle} />}

      {
        {
          IMAGE: <Image {...settings['mini']} onClick={handleClick} />,
          VIDEO: <Video {...settings['mini']} autoPlay={autoPlay} muted loop onClick={handleClick} />,
        }[resourceType]
      }
      <MediaModal open={open} handleClose={handleClose}>
        <Link key={media.id} href={media.url} title="View Original" target="_blank" rel="noreferrer">
          {
            {
              IMAGE: <Image {...settings['modal']} onClick={handleClick} />,
              VIDEO: <Video {...settings['modal']} autoPlay muted loop onClick={handleClick} />,
            }[resourceType]
          }
        </Link>
      </MediaModal>
    </Box>
  );
};
