import { useEffect, useState } from 'react';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useListContext } from 'react-admin';

import { components } from '../styles/component';

const { buttonAlignment } = components.listActions;

const useStyles = makeStyles({ ...components.listActions });

const ListActionCreateButton = ({ label = 'Create', resource, record }) => {
  const { btnCreate } = useStyles();

  if (resource === undefined || record === undefined) {
    return null;
  }

  const linkTo = {
    pathname: `/${resource}/create`,
    state: {
      record: record,
    },
  };

  return (
    <>
      <Box sx={buttonAlignment} />
      <Button component={Link} to={linkTo} color="primary" variant="outlined" className={`${btnCreate}`}>
        {label}
      </Button>
    </>
  );
};

/**
 * Blank component that hoists `<List />` list context for `<CustomListActions />`.
 *
 * @param   {setState}  setfilterContext  setState hoist
 *
 * @return  {' '}
 */
export const CustomListActionsTrigger = ({ setfilterContext }) => {
  const { setFilters, filterValues } = useListContext();
  useEffect(() => {
    setfilterContext({
      setFilters,
      filterValues,
    });
  }, [setFilters, filterValues, setfilterContext]);
  return ' ';
};

/**
 * CustomListActions is a reusable component built for the List views. Displaying a title
 * and action buttons. If `showFlagged` & `setShowFlagged` are not supplied, we default
 * to just showing the List title.
 *
 * Labeled `Custom` so to not confuse from react-admin's
 * [`ListActions` component](https://marmelab.com/react-admin/List.html#actions).
 *
 * @param   {String}   title           Title displayed above action buttons
 * @param   {String}   btnPrimary      Label for primary button
 * @param   {String}   btnSecondary    Label for secondary button
 * @param   {String}   filter          Target filter for list actions
 * @param   {Object}   listContext     Object of { setFilters, filterValues } = useListContext();
 * @param   {Object}   btnCreate       Object used for populating <Create> button
 *
 * @return  {Component}
 */
export const CustomListActions = ({
  title = 'List title',
  btnPrimary = undefined,
  btnSecondary = undefined,
  filter,
  listContext,
  btnCreate,
}) => {
  const { btnSelected, container, muiButtonRoot, titleText } = useStyles();
  const [showFlagged, setShowFlagged] = useState(true);

  /**
   * Check the URI filters
   */
  useEffect(() => {
    if (listContext) {
      if (listContext.filterValues[filter] !== undefined) {
        setShowFlagged(listContext.filterValues[filter]);
      }
    }
  }, [filter, listContext]);

  /**
   * Toggle functionality
   */
  const handleShowFlagged = (btnType) => {
    const primaryButton = btnType === 'primary' && showFlagged === false;
    const flaggedButton = btnType === 'flag' && showFlagged === true;
    if (typeof listContext === 'object' && filter !== undefined && (primaryButton || flaggedButton)) {
      listContext.setFilters({ [filter]: !showFlagged });
      setShowFlagged(!showFlagged);
    }
  };

  const primarySelected = !showFlagged ? '' : btnSelected;
  const flagSelected = showFlagged ? '' : btnSelected;
  const usesUseState = showFlagged !== undefined && setShowFlagged !== undefined;
  return (
    <>
      <Typography variant="h1" className={titleText}>
        {title}
      </Typography>
      {(usesUseState && btnPrimary) || btnSecondary ? (
        <div className={container}>
          {btnPrimary ? (
            <Button
              color="primary"
              variant="contained"
              className={`${muiButtonRoot} ${primarySelected}`}
              onClick={() => handleShowFlagged('primary')}
            >
              {btnPrimary}
            </Button>
          ) : null}

          {btnSecondary ? (
            <Button
              color="primary"
              variant="outlined"
              className={`${muiButtonRoot} ${flagSelected}`}
              onClick={() => handleShowFlagged('flag')}
            >
              {btnSecondary}
            </Button>
          ) : null}

          {btnCreate ? <ListActionCreateButton {...btnCreate} /> : null}
        </div>
      ) : null}
    </>
  );
};
