import { memo } from 'react';
import { useForm } from 'react-final-form';
import { Typography, List, ListItem } from '@mui/material';

import { Fieldset } from '../../common/components';
import { ClaimItem } from './ClaimItem';
import { components } from '../../common/styles/component';
import { useClaims } from '../hooks/claims';

const { fieldset: fieldsetWidth } = components.detailList;

/**
 * Supports both `<BusinessInformation />` & `<UserInformation />` implementations of
 * the claim section.
 *
 * @param   {object}  record    query result from RA
 * @param   {string}  resource  either `business` or `user`. Defaults to `business`.
 *
 * @return  {React.Component}
 */
export const ClaimList = memo(({ record, resource = 'business' }) => {
  const form = useForm();
  const isBusiness = resource === 'business';

  const { claims, removeClaim } = useClaims(record, form, isBusiness);

  const hasClaims = claims?.length > 0;
  const typographyHeader = isBusiness ? 'Claimed by' : 'Claimed Business(es)';
  const typographySubHeader = isBusiness ? 'Name, Location' : null;
  const typographyNoClaims = isBusiness
    ? 'This business has no claims against it.'
    : 'This User has claimed no businesses.';

  return (
    <Fieldset style={fieldsetWidth}>
      <hr />
      <Typography variant="h6">{typographyHeader}</Typography>
      {hasClaims && <Typography variant="body1">{typographySubHeader}</Typography>}
      <List disablePadding>
        {hasClaims ? (
          claims?.map((claim) => (
            <ClaimItem key={claim.id} record={record} claim={claim} removeClaim={removeClaim} isBusiness={isBusiness} />
          ))
        ) : (
          <ListItem disableGutters disablePadding>
            {typographyNoClaims}
          </ListItem>
        )}
      </List>
    </Fieldset>
  );
});
ClaimList.displayName = 'ClaimList';
