import { useCallback, useEffect, useState } from 'react';
import { isUndefined } from 'lodash';
import { updateUserBannedStatus } from '../mutations/update-ban-status';

export const useUserBan = (user, form, notify, setShowDialog) => {
  const [banned, setBanned] = useState(null);

  const banText = banned ? 'unban' : 'ban';
  const banStatus = banned ? 'Banned' : 'Active';
  const tooltips = {
    banned: banned ? 'Unbanning will reveal this users content.' : 'Banning will hide this users content.',
    confirm: `You must confirm for this ${banText} to take effect.`,
    banButton: `Click to ${banText}, this will not require a save.`,
  };

  useEffect(() => {
    if (banned === null) {
      setBanned(user.isBanned);
    }
  }, [user.isBanned, banned, setBanned]);

  const handleDialog = useCallback(() => {
    form.change('banned', banned);
    setShowDialog((prev) => !prev);
  }, [form, setShowDialog, banned]);

  const handleUserBan = useCallback(async () => {
    setShowDialog((prev) => !prev);
    setBanned((prev) => !prev);
    try {
      const response = await updateUserBannedStatus(user.id, !banned);

      if (isUndefined(response?.errors)) {
        form.change('banned', !banned);
        notify({
          message: `${user.fullName} has been ${banText}ned`,
        });
      } else {
        setBanned(banned);
        notify({
          message: `Unable to ${banText} ${user.fullName}`,
          res: response,
        });
      }
    } catch (err) {
      console.log(`An error occurred when ${banText}ing the user: `, err?.message);
      notify({
        message: `Unknown error when ${banText}ing: ${err.message}`,
        type: 'error',
      });
    }
  }, [notify, form, user, setShowDialog, setBanned, banned, banText]);

  return { handleDialog, handleUserBan, banText, banStatus, tooltips };
};
