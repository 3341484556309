export const SimpleTable = ({ children, ...props }) => (
  <div className="Table" {...props}>
    {children}
  </div>
);

export const SimpleTableRow = ({ label, value }) => (
  <div className="Table-row" key={label}>
    <div className="Table-cell">{label}:</div>
    {typeof value === 'object' ? (
      <div className="Table-cell">{value}</div>
    ) : (
      <div className="Table-cell" dangerouslySetInnerHTML={{ __html: value }} />
    )}
  </div>
);
