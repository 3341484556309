import { Box, Tooltip } from '@material-ui/core';
import HelpIcon from '@mui/icons-material/Help';

export const TicketActions = ({ children }) => {
  return (
    <div>
      <h2>
        Actions{' '}
        <Tooltip title={'If this report is valid, take action(s) below'}>
          <HelpIcon style={{ fontSize: '1.25rem', color: 'hsl(235, 21%, 80%)', verticalAlign: 'text-top' }} />
        </Tooltip>
      </h2>

      {children}
    </div>
  );
};

export const TicketActionRow = ({ children }) => (
  <Box className="u-centerFlexBetween" style={{ marginBottom: '0.75rem' }}>
    {children}
  </Box>
);
