import { useState, useEffect, useRef } from 'react';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { uniqBy } from 'lodash';

import { useDebounce } from '../../common/hooks/debounce';
import { searchBy } from '../queries/search';

const autoCompleteStyles = {
  '.MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, -4px) scale(0.75)',
  },
};

export const UserSelectInput = ({ value, label, onChange: _onChange, ...props }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const baseQueryResults = useRef([]); // let's cache the empty query results so there are always some results to show as options.
  const previousInputValue = useRef(''); // keep track of the previous input value so we know if a query should fire off to load baseline values.
  const loading = useRef(true); // the driving force is the options switching, until React 18 set state on multi useState values causes multiple renders so this stays a ref until then.

  const query = useDebounce(searchBy, 200);

  useEffect(() => {
    if (!open) {
      previousInputValue.current = inputValue;
      return;
    }
    loading.current = true;

    let active = true;

    const requeryBase = !inputValue || inputValue === previousInputValue.current;

    if (requeryBase && baseQueryResults.current.length > 0) {
      let newOptions = [];
      if (Array.isArray(value) && value.length) {
        newOptions = value;
      } else if (value) {
        newOptions = [value];
      }
      loading.current = false;
      setOptions(uniqBy([...newOptions, ...baseQueryResults.current], 'id'));
    } else {
      query(requeryBase ? '' : inputValue).then((results) => {
        // cache the empty query values
        if (requeryBase) {
          baseQueryResults.current = results || [];
        }

        if (active) {
          let newOptions = [];

          if (Array.isArray(value) && value.length) {
            newOptions = value;
          } else if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          loading.current = false;
          setOptions(uniqBy(newOptions, 'id'));
        }
      });
    }

    return () => {
      active = false;
    };
  }, [open, value, inputValue, query]);

  return (
    <Autocomplete
      size="small"
      sx={autoCompleteStyles}
      open={open}
      loading={loading.current}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.fullName)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => {
        return option?.id === value?.id;
      }}
      value={value}
      onChange={(_event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        _onChange?.(newValue || null);
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label={label} fullWidth />}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="body2" color="text.primary">
                  {option?.fullName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {option?.protectedData?.email}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      {...props}
    />
  );
};
