export const Div = ({ basePath, ...props }) => {
  return <div {...props} />;
};

export const Fieldset = ({ basePath, ...props }) => {
  return <fieldset {...props} />;
};

export const Hr = ({ basePath, ...props }) => {
  return <hr {...props} />;
};
