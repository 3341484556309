import { Typography } from '@material-ui/core';

export const TicketListReason = ({ record }) => {
  if (!record) return null;

  const charLimit = 36;
  const label = record?.reportReason?.label || '';
  return (
    <Typography variant="body2" style={{ minWidth: '200px' }}>
      {label.slice(0, charLimit) + (label.length > charLimit ? '...' : '')}
    </Typography>
  );
};
