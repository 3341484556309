import { useCallback, useEffect, useState } from 'react';
import { filter, findIndex } from 'lodash';

export const useClaims = (record, form, isBusiness) => {
  const [claims, setClaims] = useState([]);

  useEffect(() => {
    setClaims(
      (isBusiness
        ? filter(
            record.userClaims,
            (c) => findIndex(record.businessUserRoles, (bu) => bu?.user?.id === c?.user?.id) < 0
          )
        : filter(
            record.businessClaims,
            (c) => findIndex(record.userBusinessRoles, (bu) => bu?.business?.id === c?.business?.id) < 0
          )) || []
    );
  }, [
    setClaims,
    record.userClaims,
    record.businessClaims,
    record.businessUserRoles,
    isBusiness,
    record.userBusinessRoles,
  ]);

  const removeClaim = useCallback(
    async (userClaim) => {
      const { id: userClaimTargetId } = userClaim;

      // Flag which user to be deleted
      const recordIndex = findIndex(record.userClaims, ['id', userClaimTargetId]);

      if (recordIndex > -1) {
        form.change(`userClaims[${recordIndex}]`, { ...userClaim, isDeleted: true });

        // UI state
        setClaims((c) => filter(c, ({ id }) => id !== userClaimTargetId));
      }
    },
    [setClaims, form, record.userClaims]
  );

  return { claims, removeClaim };
};
