import { createTheme } from '@material-ui/core';
import { red } from '@mui/material/colors';

const PRIMARY_MAIN = '#232F78';
const PRIMARY_LIGHT = '#646BF6';
const TEXT_PRIMARY = '#2D3748';

const theme = createTheme({
  typography: {
    fontFamily: 'Mulish, sans-serif',
    fontSize: 16,
    fontWeight: 400,
    subtitle1: {
      fontSize: 12,
      opacity: '0.6',
    },
    h1: {
      color: TEXT_PRIMARY,
      fontSize: 24,
      fontWeight: 700,
    },
    h2: {
      color: TEXT_PRIMARY,
      fontSize: 20,
      fontWeight: 600,
    },
    h3: {
      color: '#4A5568',
      fontSize: 16,
      fontWeight: 600,
    },
    h6: {
      fontSize: 16,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
      fontSize: 12,
    },
    button: {
      fontSize: 12,
      fontWeight: 600,
    },
    caption: {
      fontSize: 14,
      color: '#000',
    },
  },
  palette: {
    primary: {
      light: PRIMARY_LIGHT,
      main: PRIMARY_MAIN,
    },
    gray: {
      600: '#4A5568',
      700: TEXT_PRIMARY,
    },
    success: {
      main: '#1BC172',
      contrastText: '#fff',
    },
    error: {
      main: '#E53935',
      contrastText: '#fff',
    },
    warning: {
      main: '#f57c00',
      contrastText: '#fff',
    },
  },
  overrides: {
    CardContainer: {
      boxShadow: 'none',
      maxWidth: '1440px',
    },
    MuiPaper: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiCard: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    },
    MuiAppBar: {
      root: {
        background: `${PRIMARY_MAIN} !important`,
      },
    },
    MuiLink: {
      root: {
        color: PRIMARY_LIGHT,
        fontSize: 14,
        fontWeight: 600,
      },
    },
    MuiFormLabel: {
      root: {
        // color: TEXT_PRIMARY,
        fontSize: 14,
        fontWeight: 600,
      },
    },
    MuiInputBase: {
      root: {
        color: TEXT_PRIMARY,
        fontSize: 16,
      },
    },
    MuiInputLabel: {
      root: {
        display: 'none',
      },
    },
    MuiOutlinedInput: {
      root: {
        '& legend': {
          display: 'none',
        },
      },
      inputMarginDense: {
        paddingTop: '7.5px',
        paddingBottom: '12.5px',
      },
    },
    PrivateNotchedOutline: {
      legendLabelled: {
        // backgroundColor: 'red'
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: PRIMARY_LIGHT,
      },
    },
    MuiTab: {
      root: {
        fontSize: '1rem',
        textTransform: 'normal',
        '&$selected': {
          '& > span': {
            color: PRIMARY_LIGHT,
          },
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '.875rem',
      },
    },
    RaMenu: {
      main: {
        borderRight: '1px solid var(--colorGreyLighter)',
        boxSizing: 'border-box',
        height: '100%',
        margin: '0 !important',
        paddingTop: '1.25rem',
      },
      closed: {
        width: '3.75rem',
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: '1.375rem',
        paddingRight: '1.375rem',
      },
    },
    RaMenuItemLink: {
      root: {
        color: 'var(--colorGrey)',
      },
      active: {
        fontWeight: '600',
        color: 'var(--PRIMARY_MAIN)',
      },
    },
    MuiMenu: {
      list: {
        maxHeight: '200px',
      },
    },
    MuiChip: {
      root: {
        textTransform: 'uppercase',
      },
      labelSmall: {
        fontSize: '.6875rem',
        lineHeight: '1',
        overflow: 'visible',
      },
    },
    MuiRating: {
      root: {
        color: '#4EA663',
        fontSize: '1rem',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '0px !important',
        paddingRight: '0.75rem',
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: 14,
      },
    },
    MuiCardContent: {
      root: {
        padding: '0px',
      },
    },
    RaLayoutContent: {
      padding: '0px',
    },
    RaShow: {
      main: {
        margin: '0px !important',
      },
    },
    MuiButton: {
      outlined: {
        borderColor: 'currentColor',
      },
      root: {
        color: 'currentColor',
      },
    },
    RaList: {
      root: {
        backgroundColor: 'white',
        border: '1px solid #eee',
        borderRadius: '8px',
        boxShadow:
          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        '& > div:first-child': {
          position: 'relative',
          top: '1px',
          zIndex: '3',
        },
        '& form': {
          marginBottom: '1.75rem',
        },
        '& td': {
          paddingTop: '1.25rem',
          paddingBottom: '1.25rem',
        },
      },
    },
    RaAutocompleteArrayInput: {
      chip: {
        fontSize: '.6875rem',
        lineHeight: '1',
        paddingLeft: '0',
        paddingRight: '0',
        height: '24px',
      },
    },
  },
});

export default theme;
