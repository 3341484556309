import { memo, useCallback, useState } from 'react';
import { TextInput } from 'react-admin';
import { FormLabel, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/NewReleases';

/**
 * Consolidates an input's `<FormLabel />` & Input (Defaults to `<TextInput />`) to a single wrapped element.
 *
 * @param   {string}        formLabel       The label text for `<FormLabel />`
 * @param   {string}        tooltipText     Enables a Info icon beside the input label with a tooltip hover
 * @param   {string}        helperText      The text displayed under the input
 * @param   {number}        characterLimit  Amount of allowed characters for an input
 * @param   {FC}            OverrideInput   Override the internal input component,
 *                                          defaults to [react-admin's `<TextInput />`](https://marmelab.com/react-admin/TextInput.html)
 *
 * @return  {memo(CustomInput)}
 */
export const CustomInput = memo(
  ({
    formLabel,
    tooltipText,
    tooltipPlacement = 'right',
    helperText = undefined,
    characterLimit = undefined,
    requiredInput = false,
    requiredText = 'Required',
    onChange = () => {},
    OverrideInput = TextInput,
    variant = 'outlined',
    ...props
  }) => {
    const [displayCharacterCount, setDisplayCharacterCount] = useState(false);
    const [charactersRemaining, setCharactersRemaining] = useState('');

    const characterCounter = useCallback(
      (event) => {
        if (characterLimit && +characterLimit > 0 && event) {
          const characterCount = event?.target?.value?.length;
          /**
           * Start displaying characters remaining once you've used 50% of your limit
           */
          const displayCount = characterCount ? characterCount >= characterLimit / 2 : false;
          if (!displayCount) {
            return setDisplayCharacterCount(false);
          }

          const remaining = characterLimit - characterCount;
          if (remaining === 0) {
            /**
             * If at exactly the limit, don't display message
             */
            return setDisplayCharacterCount(false);
          }
          /**
           * Replace helperText with characters remaining
           */
          setDisplayCharacterCount(true);
          const plural = remaining > 1 || (remaining < -1 && remaining < 0);
          return setCharactersRemaining(`${remaining} character${plural ? 's' : ''} remaining`);
        }
      },
      [characterLimit]
    );

    return (
      <>
        <FormLabel>
          <span>{formLabel}</span>
          {requiredInput ? (
            <>
              <ErrorIcon style={{ margin: '0 0.25rem -0.09rem 0.5rem' }} color="error" fontSize="small" />
              <Typography style={{ color: '#E53935', display: 'inline-block' }}>{requiredText}</Typography>
            </>
          ) : null}
          {tooltipText ? (
            <Tooltip title={tooltipText} placement={tooltipPlacement}>
              <InfoIcon style={{ margin: '-0.10rem 0.5rem' }} fontSize="small" />
            </Tooltip>
          ) : null}
        </FormLabel>
        <OverrideInput
          hiddenLabel
          label="HUHUHUHUHUH"
          helperText={displayCharacterCount ? charactersRemaining : helperText}
          variant={variant}
          {...props}
          onChange={(event) => {
            {
              characterLimit && characterCounter(event);
            }
            onChange(event);
          }}
        />
      </>
    );
  }
);

CustomInput.displayName = 'CustomInput';
