import MapboxGeocoder from '@mapbox/mapbox-sdk/services/geocoding';

import { config } from '../config';
const client = MapboxGeocoder({ accessToken: config.mapbox.token });

const geocode = async (query) => {
  const res = await client
    .forwardGeocode({
      query: query,
      proximity: 'ip',
      limit: 1,
    })
    .send();
  return res.body;
};

export default {
  geocode,
};
