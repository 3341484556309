import { memo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Grid, Checkbox, FormControlLabel } from '@mui/material';
import { TimePicker, KeyboardTimePicker } from '@material-ui/pickers';

import { useDayHours } from '../hooks/dayHours';

const timePickerStyles = { width: '175px' };

/**
 * Renders a day's opening & closing inputs for [`<BusinessHours />`](../../business/components/BusinessHours.js), two `useEffect`'s are used in monitoring of state,
 * from upstream value changes on `open24Hours` to validation checking on `start` & `end` inputs.
 *
 * Uses [`<KeyboardDatePicker />`](https://material-ui-pickers.dev/api/KeyboardDatePicker).
 *
 * **Validation:** Once an input is filled, it's sibling must also be filled in.
 *
 * **Usage:**
 * ```js
 * <DayHours
      key={day.day}
      day={day}
      hours={record.hours.find((dh) => dh.day === day.day)}
      open24hours={record.open24hours}
      validate={validate}
      form={form}
    />
 * ```
 *
 * @param   {Number}       day              [0-6] day # from Sun - Sat
 * @param   {Object[]}     hours            Result of queried hours
 * @param   {form}         form             Inherited instance of `useForm` from [`<BusinessHours />`](../../business/components/BusinessHours.js)
 * @param   {Boolean}      open24hours      Result of `record.open24Hours`. Defaults to `false`.
 *
 *                                          When `true`, the internal `<FormControlLabel />` will be flipped.
 * @param   {Number}       validate         Contains state controls & value for `validateHours`
 *
 *                                          Used as a numbered stepper, wherin `anyErrors`
 *                                          reflects the state of each input as a collection where the values of the validationErrors is `0` or `1`. If a single input in the collection contains > 0 then we have some errors.
 *                                          Internal validation checking will reduce this value once errors are
 *                                          resolved.
 *
 * @return  {React.Component}
 */
const DayHours = (props) => {
  const {
    label,
    is24Hours,
    open247,
    startValue,
    endValue,
    startInvalid,
    endInvalid,
    handleStartChange,
    handleEndChange,
    handle24HoursChange,
  } = useDayHours(props);

  return (
    <Grid container item xs={12} alignItems="center" style={{ paddingBottom: '20px' }}>
      <Typography variant="body2" color="textSecondary" style={{ margin: '0 1rem 0 0.5rem', width: '30px' }}>
        {label}:
      </Typography>
      <Box>
        <KeyboardTimePicker
          disabled={is24Hours}
          value={is24Hours ? null : startValue}
          onChange={handleStartChange}
          mask="__:__ _M"
          variant="inline"
          autoOk
          clearable
          emptyLabel={is24Hours ? 'Open' : 'Closed'}
          inputVariant="outlined"
          size="small"
          style={timePickerStyles}
        />
        <br />
        {!!startInvalid && (
          <Typography
            style={{
              position: 'absolute',
              paddingBottom: '20px',
            }}
            variant="body2"
            color="error"
          >
            {startInvalid}
          </Typography>
        )}
      </Box>

      <Typography variant="body2" color="textSecondary" style={{ margin: '0 1rem' }}>
        To
      </Typography>
      <Box style={{ position: 'relative' }}>
        <KeyboardTimePicker
          disabled={is24Hours}
          value={is24Hours ? null : endValue}
          onChange={handleEndChange}
          mask="__:__ _M"
          variant="inline"
          autoOk
          clearable
          emptyLabel={is24Hours ? 'Open' : 'Closed'}
          inputVariant="outlined"
          size="small"
          style={timePickerStyles}
        />
        <br />
        {!!endInvalid && (
          <Typography
            style={{
              position: 'absolute',
              paddingBottom: '20px',
            }}
            variant="body2"
            color="error"
          >
            {endInvalid}
          </Typography>
        )}
      </Box>
      <FormControlLabel
        style={{ marginLeft: '1rem' }}
        control={
          <Checkbox
            disabled={open247}
            checked={is24Hours}
            onChange={handle24HoursChange}
            name={`customOpen24Hours${label}`}
          />
        }
        label="Open 24 hours"
      />
    </Grid>
  );
};

export default memo(DayHours);
