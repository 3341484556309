import { useMemo } from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Toolbar, SaveButton } from 'react-admin';
import { DetailHeader } from '../../common/components';
import { BusinessCreateForm } from '../components/BusinessCreateForm';

const defaultData = {
  timezone: 'America/Los_Angeles',
};

export const BusinessCreate = (props) => {
  const fromBusinessApplication = props.match?.url === '/businessApplications/create';
  const detailNotice = {
    label: 'User submitted',
    Icon: AssignmentIcon,
  };

  const CreateToolbar = useMemo((props) => {
    const redirect = (id, data) => {
      return id === '/businessApplications' ? '/businessApplications' : '/business';
    };

    return (
      <Toolbar {...props}>
        <SaveButton label="Create Business" redirect={redirect} submitOnEnter={false} />
      </Toolbar>
    );
  }, []);

  return (
    <>
      <DetailHeader
        title="Create a Business"
        notice={detailNotice}
        chevronLabel={fromBusinessApplication ? 'Back to Business Applications' : 'Back to businesses'}
        chevronLink={fromBusinessApplication ? '/businessApplications' : '/business'}
      />
      <BusinessCreateForm formProps={props} defaultData={defaultData} CustomToolbar={CreateToolbar} />
    </>
  );
};

export default BusinessCreate;
