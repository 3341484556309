import { queryAdminUser } from '../queries/admin';

export const verifyAdmin = async ({ logout = null, user }) => {
  /**
   * If logged in as Testy, don't check
   */
  if (user?.isTestyTesterson) {
    return true;
  }
  /**
   * Call DGraph to see if this Auth0 User is in our Admin users
   */
  if (!user?.sub) return false;

  const adminUser = await queryAdminUser(user.sub);

  /**
   * Report if the logged in Auth0 user is a registered admin user.
   */
  if (!adminUser) {
    if (logout !== null) logout({ returnTo: window.location.origin });
    else return false;
  } else {
    return true;
  }
};
