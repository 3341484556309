import { Link, ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import LaunchIcon from '@mui/icons-material/Launch';
import { reviewItem } from '../styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(reviewItem);

export const TicketMenuItem = ({ outstandingReports: reports = null }) => {
  const { actionMenu, deleteIcon } = useStyles();

  if (!reports?.length) {
    return null;
  }
  const ticketLink = `/#/userReportedContentGroup/${reports[0]?.reportGroup?.id}/show`;
  return (
    <>
      <Link href={ticketLink} target="_blank">
        <MenuItem component={Link} className={actionMenu}>
          <Typography variant="inherit">Go to open ticket</Typography>
          <ListItemIcon>
            <LaunchIcon fontSize="small" className={deleteIcon} />
          </ListItemIcon>
        </MenuItem>
      </Link>
    </>
  );
};
