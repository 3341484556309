// Styles for all business components
export const list = {
  view: {
    topToolBar: {
      marginRight: '1rem',
    },
    searchInput: {
      margin: '0rem 1rem',
    },
    empty: {
      border: 0,
    },
  },
  review: {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    containerInternal: {
      alignItems: 'center',
      display: 'flex',
      maxWidth: '7rem',
    },
    score: {
      color: '#000',
      fontSize: '.875rem',
      fontWeight: '400',
    },
    reviewers: {
      color: '#000',
      fontSize: '.875rem',
      fontWeight: '400',
    },
    starIcon: {
      color: '#1BC172',
      height: '.75rem',
      marginLeft: '.25rem',
      width: '.75rem',
    },
    flagIcon: {
      color: '#F12B2C',
      height: '1.25rem',
      marginLeft: 'auto',
      width: '1.25rem',
    },
  },
  status: {
    label: {
      fontSize: '.875rem',
      fontWeight: '600',
    },
    active: {
      color: '#1BC172',
    },
    hidden: {
      color: '#F12B2C',
    },
  },
  report: {
    text: {
      fontSize: '.875rem',
      fontWeight: '600',
      marginBottom: '.25rem',
    },
    on: {
      color: '#4B506D',
      fontSize: '.75rem',
      fontWeight: '400',
    },
  },
  name: {
    container: {
      '&:first-child': {
        marginRight: '1.25rem',
      },
    },
  },
  image: {
    container: {
      height: '40px',
      width: '78px',
      position: 'relative',
      cursor: 'pointer',
    },
    tag: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      display: 'block',
      backgroundColor: '#EFEFEF',
    },
  },
};

export const detail = {
  information: {
    avatarLabel: {
      fontSize: '0.75rem',
    },
    checkboxContainer: {
      display: 'flex',
    },
    nameContainer: {
      marginBottom: '1rem',
    },
    uploadContainer: {
      textAlign: 'center',
    },
    metadataLabel: {
      display: 'inline-block',
      margin: '1rem 0',
    },
    subtitleContainer: {
      marginTop: '1rem',
    },
    subtitleLabel: {
      display: 'inline-block',
    },
    gridStyles: {
      minWidth: '650px',
    },
  },
  review: {
    container: {
      marginTop: '.75rem',
      padding: '.5rem',
    },
    header: {
      marginBottom: '1.8rem',
    },
    headerRatings: {
      alignItems: 'center',
      display: 'flex',
      marginBottom: '.2rem',
    },
    ratings: {
      marginLeft: '.25rem',
    },
    userName: {
      color: '#252733',
    },
  },
};
