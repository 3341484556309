import { useCallback, useMemo, useState } from 'react';
import { List, Datagrid, SearchInput, TopToolbar, FilterButton, BooleanInput } from 'react-admin';
// import { ImportButton } from 'react-admin-import-csv';
import { BusinessPhoneNumberMask } from '../../common/utils';
import {
  EmptyList,
  Pagination,
  CustomListActions,
  CustomListActionsTrigger,
  ListCreatedAt,
  ScrollToTop,
} from '../../common/components';
import {
  BusinessListClaims,
  BusinessListImage,
  BusinessListName,
  BusinessListOwner,
  BusinessListReport,
  BusinessListReviews,
  BusinessListStatus,
} from '../components';

import { list } from '../styles';

const { topToolBar, searchInput, empty } = list.view;

const LISTACTIONSFILTER = 'active';

const Actions = () => (
  <TopToolbar style={{ ...topToolBar }}>
    <FilterButton />
    {/* <ImportButton {...props} {...Config} /> */}
  </TopToolbar>
);

const defaultSort = { field: 'name', order: 'desc' };

const BusinessList = (props) => {
  const [filterContext, setfilterContext] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const searchBusiness = useCallback((e) => {
    return setSearchQuery(BusinessPhoneNumberMask(e.target?.value));
  }, []);

  const createBusiness = {
    label: 'Create Business',
    resource: 'business',
    record: {},
  };

  /**
   * Disable "Featured" filter if navigated to "FLAGGED" businesses.
   */
  const disableFilterUnderFlagged = filterContext?.filterValues['active'] === false;

  /**
   * Prevent showing featured filter on "FLAGGED" businesses, since hidden businesses cannot be featured.
   */
  const listFilters = [
    <SearchInput
      key={1}
      style={searchInput}
      source="customSearchInputBusinessFilter"
      alwaysOn
      placeholder="Search Business name, phone, or URL"
      className="searchField"
      parse={BusinessPhoneNumberMask}
      onChange={searchBusiness}
      defaultValue=""
    />,
    <BooleanInput
      key={2}
      label="Featured"
      source="featured"
      defaultValue={false}
      disabled={disableFilterUnderFlagged}
    />,
    <BooleanInput key={4} label="Claimed" source="claimed" defaultValue={false} />,
    <BooleanInput key={5} label="Owned" source="owned" defaultValue={false} />,
  ];

  const pagination = useMemo(() => <Pagination limit={<EmptyList searchQuery={searchQuery} />} />, [searchQuery]);

  return (
    <ScrollToTop>
      <CustomListActions
        title="Businesses"
        btnPrimary="All Businesses"
        btnSecondary="Flagged businesses"
        filter={LISTACTIONSFILTER}
        listContext={filterContext}
        btnCreate={createBusiness}
      />
      <List
        {...props}
        actions={<Actions />}
        bulkActionButtons={false}
        filters={listFilters}
        style={empty}
        pagination={pagination}
        perPage={100}
        sort={defaultSort}
      >
        <>
          <CustomListActionsTrigger setfilterContext={setfilterContext} />
          <Datagrid rowClick="edit">
            <BusinessListImage source="Image" label="Background Image" sortable={false} />
            <BusinessListName source="name" label="Business Name" />
            <BusinessListClaims source="Claims" sortable={false} />
            <BusinessListOwner source="Owner" sortable={false} />
            <BusinessListStatus source="status" sortable={false} />
            <BusinessListReviews source="reviews" sortable={false} />
            <BusinessListReport source="reports" sortable={false} />
            <ListCreatedAt source="createdAt" label="Created" />
          </Datagrid>
        </>
      </List>
    </ScrollToTop>
  );
};

export default BusinessList;
