import { CircularProgress } from '@mui/material';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export const Device = ({ children, loaded, deleted, deleteTypeText, overlay = [], type, ...props }) => {
  return (
    <div className="Device" {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'center',
          opacity: deleted || !loaded ? '1' : '0',
          transition: '10ms',
        }}
      >
        {!deleted && <CircularProgress />}
      </div>
      {overlay.length > 0 && (
        <div className="Device-overlay">
          <ErrorOutlineIcon color="error" style={{ marginRight: '0.25em' }} />
          {type} {overlay[0]}
        </div>
      )}
      <div style={{ opacity: !loaded ? '0.2' : deleted ? '0' : '1', transition: '700ms' }}>{children}</div>
    </div>
  );
};
