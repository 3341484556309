import { maxLength, required } from 'react-admin';
import { PhoneNumberValidation } from '../../common/utils/FormatPhoneNumber';
import { uniquePhoneNumber, uniqueEmail } from './protectedData';

export const characterLimit = {
  bio: 300,
};

export const validateUserInformation = {
  firstName: [required()],
  lastName: [required()],
  bio: [maxLength(characterLimit.bio)],
  phoneNumber: [required(), PhoneNumberValidation, uniquePhoneNumber('Phone number already in use')],
  email: [required(), uniqueEmail('Email already in use')],
};
