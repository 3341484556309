export const reporterFields = `
  id
  fullName
  banned
  avatarImage {
    url
    publicId
    resourceType
    type
  }
  protectedData {
    email
    phoneNumber
  }
`;
