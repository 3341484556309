import { useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

import { list } from '../styles';

const useStyles = makeStyles({ ...list.status });

export const UserListStatus = () => {
  const record = useRecordContext();
  const classes = useStyles();
  const receivedReports = record?.userReportsReceivedAggregate?.count;
  const status = record?.banned ? 'Banned' : 'Active';

  /**
   * @TODO update once User's have an active predicate
   * @ticket https://gohypergiant.atlassian.net/browse/JUPTR-1413
   */
  return record ? (
    <Typography className={`${classes.status} ${classes[status]}`}>
      {status}
      {receivedReports && receivedReports > 0 ? (
        <>
          <br />
          {receivedReports} violation{receivedReports === 1 ? '' : 's'}.
        </>
      ) : (
        ''
      )}
    </Typography>
  ) : null;
};
