import { FormatPhoneNumberRaw } from './FormatPhoneNumber';

export const PhoneNumberMask = (value) => {
  if (!value?.length) {
    return value;
  }

  const newValue = value.replace(/[()-]/g, '').replace(' ', '');
  if (newValue.length >= 9 && !new RegExp(/[A-za-z]/, 'g').test(newValue)) {
    const phone = `${value}`;
    const sanitized = phone.trim().replace('+', '').replace(/\D/g, '');
    return `+${sanitized}`;
  }

  return value;
};

export const BusinessPhoneNumberMask = (value) => {
  if (!value?.length) {
    return value;
  }

  const newValue = value.replace(/[()-]/g, '').replace(' ', '');
  if (newValue.length === 10 && !new RegExp(/[A-za-z]/, 'g').test(newValue)) {
    const phone = `${value}`;
    const sanitized = phone.trim().replace('+1', '').replace(/\D/g, '');
    return `+1${sanitized}`;
  }

  return value;
};
