// import './App.css';
import { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import JupiterAdmin from './common/views/_JupiterAdmin';
import Landing from './common/views/_Landing';
// import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0Wrapper } from './auth/hooks/auth0';
import { verifyAdmin } from './auth/utils/admin';
import { AuthTokenContext } from './auth/providers/auth-token-provider';

const renderAdminOrLanding = (isAuthenticated, token) => {
  if (isAuthenticated && token) {
    return <JupiterAdmin token={token} />;
  } else {
    return (
      <Router>
        <Landing />
      </Router>
    );
  }
};

const App = () => {
  const { token, setToken } = useContext(AuthTokenContext);

  const { isAuthenticated, getIdTokenClaims, user, logout } = useAuth0Wrapper();
  const [isAdmin, setAdminClaim] = useState(undefined);

  useEffect(() => {
    async function getToken() {
      const result = await getIdTokenClaims();
      setToken(result?.__raw);
    }
    if (isAuthenticated) {
      getToken();
    }
  }, [getIdTokenClaims, isAuthenticated, setToken]);

  useEffect(() => {
    async function getAdminStatus() {
      const hasAdminClaim = await verifyAdmin({ user });
      setAdminClaim(hasAdminClaim);
    }
    if (token) {
      getAdminStatus();
    }
  }, [user, token, setAdminClaim]);

  /**
   * Throw out users who've logged in, but do not exist as an Admin user.
   */
  if (isAuthenticated && isAdmin === false) {
    const params = {
      error: encodeURI('error=Unauthorized'),
      error_desc: encodeURI('error_description=User is not registered as Admin.'),
    };
    const returnTo = `${window.location.origin}?${params.error}&${params.error_desc}`;
    logout({ returnTo });
    return null;
  } else {
    return renderAdminOrLanding(isAuthenticated, token);
  }
};

export default App;
