/**
 * Wrapper computer that assists in conditoinally wrapping children nodes
 * under condition = true.
 *
 * @param   {any}        condition    The Conditional check on whether to wrap, or not.
 * @param   {Function}   IfWrap       If condition = true, then wrap.
 * @param   {any}        children     Children of components
 *
 * @return  {any}
 */
export const ConditionalWrap = ({ condition, IfWrap, children }) => (condition ? IfWrap(children) : children);
