import { reporterFields } from './reporter';
import { businessUserReportedContentListFields, businessUserReportedContentDetailFields } from './business';
import { reviewUserReportedContentListFields, reviewUserReportedContentDetailFields } from './review';
import { userUserReportedContentListFields, userUserReportedContentDetailFields } from './user';

const list = `
  id
  createdAt
  updatedAt
  closedAt
  reportedItem {
    __typename
    ... on ActivityFeedReportedPost {
      id
      author {
        id
        fullName
        protectedData {
          email
        }
      }
      postId
    }
    ... on ActivityFeedReportedComment {
      id
      author {
        id
        fullName
        protectedData {
          email
        }
      }
      postId
      commentId
    }
    ... on Review { ${reviewUserReportedContentDetailFields} }
    ... on User { ${userUserReportedContentDetailFields} }
    ... on Business { ${businessUserReportedContentDetailFields} }
  }
  reportedItemSummary {
    id
    reportedContentAuthor {
      id
      fullName
      authId
    }
    type
    contextName
    reportedContentImage {
      id
      url
      publicId
      type
      resourceType
    }
  }
  userReportedContentReports {
    id
    priority
    reporterComment
    reporter {
      ${userUserReportedContentListFields}
    }
    reason {
      label
    }
  }
  reportReason {
    label
  }
  reportPriority
  reportReason {
    label
  }
  moderatorComments {
    id
    createdAt
    comment
    admin {
      id
      authId
      name
      email
    }
  }
  reportCount: userReportedContentReportsAggregate {
	  count
  }
`;

const detail = list;

export const userReportedContentGroup = { detail, list };
